import React from "react";

type Props = {
  src: string;
  children?: React.ReactNode;
  className?: string;
};

function BackgroungImage({ src, children, className }: Props) {
  return (
    <div className={`w-full h-full relative ${className ? className : ""}`}>
      <img src={src} className="w-full h-full absolute top-0 left-0 z-0" />
      <div
        id="content"
        className="w-full h-full scrollbar-hide absolute overflow-y-auto top-0 left-0 z-10"
      >
        {children}
      </div>
    </div>
  );
}

export default BackgroungImage;
