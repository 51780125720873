import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLocation, useRoutes } from "react-router-dom";
import routesConfig from "routes";
import moment from "moment-timezone";
import MaintenancePage from "pages/Maintainance";
moment.tz.setDefault("Etc/GMT-5");
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      experimental_prefetchInRender: true,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  const location = useLocation();  
  const element = useRoutes(routesConfig, location); // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    <QueryClientProvider client={queryClient}>
      <MaintenancePage />
      {/* {element} */}
      {/* <DraggableFloatingButton /> */}
    </QueryClientProvider>
  );
}
export default App;
