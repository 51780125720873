import { message, Spin } from "antd";
import { useMemberDetail } from "apis/auth/member-detail";
import { useDepositGameBalance } from "apis/games/DepositGameBalance";
import { useLoginGameInterface } from "apis/games/LoginGameInterface";
import { usePlayerStopGame } from "apis/games/PlayerStopGame";
import IconDeposit from "assets/images/icon-withdrawal.png";
import HeaderTab from "components/HeaderTab";
import { useModalConfirm } from "components/ModalConfirm";
import { PATH } from "constants/path";
import { ProvidersGame } from "constants/providers-game";
import { createAmountSelect } from "helper/createAmountSelect";
import { useLoginGame } from "helper/handleOpenGame";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModalTransfer from "./modal-transfer";
import TimeLine from "./time-line";

enum STATUS_GAME {
  playing = "playing",
  close = "close",
}
function PlayingGameControlsMainPoint() {
  const { t } = useTranslation("game"); // dịch
  const { contextHolder, modalConfig } = useModalConfirm(); // modal conform
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { data: profile } = useMemberDetail(); // lấy chips point
  const { data: gameInterface } = useLoginGameInterface();
  const { onLoginGame, windowTab } = useLoginGame(); // xử lý mở game và quản lý tab
  const { mutateAsync, isPending } = usePlayerStopGame(); // api stop game khi kết thúc
  const [playing, setPlaying] = useState(STATUS_GAME.close); // trạng thái hoạt động
  const isPlaying = playing === STATUS_GAME.playing; // trạng thái playing
  const paramsObject = Object.fromEntries(params.entries()); // obj params game lấy từ link
  const [amount, setAmount] = useState(""); // state amount muốn chuyển khi vào game
  const [openTransfer, setOpenTransfer] = useState(false); // mở modal transfer
  const [gameURL, setGameURL] = useState("");
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight);

  const { mutateAsync: mutateAsyncDepositGame, isPending: isLoading } =
    useDepositGameBalance({
      onSuccess: () => {
        if (profile?.points) {
          setAmount(profile.points.toString());
        }
      },
    });
  const monitorTabRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const provider = ProvidersGame.find(
    // lấy item provider của game
    (i) =>
      i.provider_code === params.get("provider_code") &&
      i.type === params.get("type")
  );

  useEffect(() => {
    if (!profile?.points) return;
    if (amount) return;
    setAmount(profile?.points.toString());
  }, [profile?.points]);

  useEffect(() => {
    // xử lý sự kiện thoát tab khi đang login game và đóng tab
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      mutateAsync();
      setPlaying(STATUS_GAME.close);
      setGameURL("");
      if (windowTab.current) {
        windowTab.current.close();
      }
      event.preventDefault();
      event.returnValue = t("messageAlert");
    };
    if (playing === STATUS_GAME.playing) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [playing]);

  useEffect(() => {
    // bắt trạng thái đóng tab game, refresh 1s/lần
    if (isLoading) return;
    if (!isPlaying) {
      clearInterval(monitorTabRef.current);
      monitorTabRef.current = undefined;
    } else {
      monitorTabRef.current = setInterval(async () => {
        if (windowTab.current && windowTab.current.closed) {
          clearInterval(monitorTabRef.current);
          monitorTabRef.current = undefined;
          await mutateAsync();
          setPlaying(STATUS_GAME.close);
        }
      }, 100);
    }
    return () => {
      clearInterval(monitorTabRef.current);
    };
  }, [isPlaying, isLoading]);

  const onPlayingGame = async () => {
    // xử lý lúc start game
    const iframeGame = ["BG", "SL", "FH"];
    // const iframeGame = [''];

    if (!isPlaying) {
      if (paramsObject) {
        try {
          // khiểm tra obj game có tồn tại
          if (amount) {
            if (isLoading) return;

            // nếu có amount thì chạy api chuyển đổi wallet từ main -> game
            await mutateAsyncDepositGame({
              provider: provider?.provider_code + "",
              amount: amount,
              game_type: provider?.type + ""
            });
          }
          await onLoginGame(
            paramsObject,
            iframeGame.includes(paramsObject.type) ? setGameURL : undefined
          );
          localStorage.setItem("data-on-game", JSON.stringify(paramsObject)); // lưu lại data khi login
          setPlaying(isPlaying ? STATUS_GAME.close : STATUS_GAME.playing); // thay đổi trạng thái
        } catch (error) {
          message.destroy();
          message.error(error + "");
        }
      }
    } else {
      await mutateAsync(); // khi stop thì chạy api close game và đóng tab nếu tab đang bật
      setPlaying(STATUS_GAME.close);
      if (windowTab.current) {
        windowTab.current.close();
      }
      setPlaying(isPlaying ? STATUS_GAME.close : STATUS_GAME.playing); // thay đổi trạng thái
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      setIframeHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div className="flex flex-col relative bg-linearBlack">
      {isPending ? (
        <div className="flex items-center justify-center fixed top-0 left-0 bg-black bg-opacity-50 h-screen w-screen">
          <Spin />
        </div>
      ) : null}
      <HeaderTab
        onLeft={() => {
          if (!isPlaying) {
            return navigate(-1);
          }
          modalConfig({
            title: params.get("name") + "",
            content: t("messageWarning"),
            onSuccess: async () => {
              await onPlayingGame();
              navigate(-1);
            },
          });
        }}
        title={params.get("name") || ""}
      />
      {contextHolder}
      {/* <div className="flex items-center bg-slate-900 px-4 py-2">
        <span>Game rewards:</span>
        <span className="flex items-center ml-4">
          <span className="text-red-600 font-semibold">
            {gameInterface?.game_reward_new || "0.0"}%
          </span>
        </span>
      </div> */}
      <div className="flex items-center border-b border-slate-500 mx-4 py-3">
        <div className="flex-1 pr-4">
          <div className="flex items-center justify-between text-sm">
            <span>
              Balance<span className="text-gray-400">(MVR):</span>
            </span>
            <span className="font-bold">{profile?.points || "0.00"}</span>
          </div>
          <div className="flex items-center justify-between mt-3 text-sm">
            <span>
              Amount<span className="text-gray-400">(MVR):</span>
            </span>
            <span className="font-bold">{profile?.points || "0.00"}</span>
          </div>
        </div>
        <div
          onClick={() => {
            if (!isPlaying) return navigate(PATH.topup);
            message.warning(t("messageWarning"));
          }}
          className="w-1/6 flex items-center justify-center border-l border-slate-500"
        >
          <img src={IconDeposit} className="w-12" />
        </div>
      </div>
      <div className="flex flex-col mx-4 mt-3">
        <input
          placeholder="0.00"
          className="h-12 bg-slate-900 text-center rounded-xl outline-none"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <div className="flex items-center flex-wrap justify-around mt-4">
          {createAmountSelect(100).map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => setAmount(item.toString())}
                className="flex items-center justify-center px-3 py-2 text-sm bg-slate-800 rounded-xl"
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div
        onClick={onPlayingGame}
        className={`${!isPlaying ? "bg-linearGold" : "bg-red-500"
          } mt-4 w-auto mx-4 text-center py-2 px-6 rounded uppercase`}
      >
        {isLoading ? (
          <Spin size="small" />
        ) : isPlaying ? (
          isPlaying && !gameURL ? (
            <Spin size="small" />
          ) : (
            <TimeLine title={t("Stopgame")} />
          )
        ) : (
          <span className={`text-white font-bold`}>{t("Startgame")}</span>
        )}
      </div>
      <div className="flex flex-row mx-4 mt-2 text-gray-300">
        <div className="flex-1 text-sm">
          <span>Turnover: {gameInterface?.turnover || "0.00"}</span>
        </div>
        <div className="flex-1 text-sm">
          <span>Last Turnover: {gameInterface?.last_turnover || "0.00"}</span>
        </div>
      </div>
      <ModalTransfer
        open={openTransfer}
        onClose={() => setOpenTransfer(false)}
      />
      {gameURL ? (
        <div className="fixed w-full h-full container-max-width z-[999999] bg-black">
          <button
            className="w-[40px] h-[40px] text-[#E3B85D] text-lg font-medium flex items-center justify-center fixed top-0 left-0 z-[9999999999]"
            onClick={() => {
              setGameURL("");
              mutateAsync();
              setPlaying(STATUS_GAME.close);
            }}
          >
            <svg
              className="w-6 fill-red-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
          <iframe
            src={gameURL}
            title="Embedded Page"
            className="w-full border-0"
            style={{ height: iframeHeight }}
          ></iframe>
        </div>
      ) : null}
    </div>
  );
}

export default PlayingGameControlsMainPoint;
