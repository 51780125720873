import { Spin } from "antd";
import { useStuckPoint } from "apis/games/StuckPoint";
import { useStuckPointQuit } from "apis/games/StuckPointQuit";
import Marquee from "react-fast-marquee";

function StuckPoint() {
  const { data } = useStuckPoint();
  const { mutate, isPending } = useStuckPointQuit();
  if (!data) return null;
  if (Array.isArray(data) && !data.length) return null;
  return (
    <div className="bg-slate-900 px-2 py-1 rounded-2xl mb-2 flex items-center justify-between">
      <div className="flex items-center">
        <svg
          className="w-4 fill-[#E3B85D] mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z" />
        </svg>
        <Marquee>
          <span className="text-sm ml-2 text-white">
            [{data?.provider_name}]<span className="ml-1">Playing</span>
          </span>
        </Marquee>
      </div>
      <span
        onClick={() =>
          mutate({
            username: localStorage.getItem("username") + "",
            provider: data?.provider + "",
          })
        }
        className="border ml-4 border-[#E3B85D] text-[11px] px-3 py-0.5 rounded-3xl text-white whitespace-pre"
      >
        {isPending ? <Spin size="small" /> : "Quit game"}
      </span>
    </div>
  );
}

export default StuckPoint;
