import { Empty } from 'antd';
import { useRewardRecords } from 'apis/transactions';
import HeaderTab from 'components/HeaderTab';
import LoadingCustom from 'components/LoadingCustom';
import PaginationsCustom from 'components/PaginationsCustom';
import RangePickerCustom from 'components/RangePickerCustom';
import dayjs, { Dayjs } from 'dayjs';
import { handleColorStatusWithString } from 'helper/showStatusText';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type dateType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const RewardRecords = () => {
  const { t } = useTranslation("transactions")
  const [dateTime, setDateTime] = useState<dateType>({
    startDate: dayjs().subtract(7, "day"),
    endDate: dayjs()
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;

  const { data: dataRewardRecords, refetch, isFetching, isLoading } = useRewardRecords({
    size: pageSize,
    page: currentPage,
    start: dateTime.startDate?.format("YYYY/MM/DD").toString() ?? "",
    end: dateTime.endDate?.format("YYYY/MM/DD").toString() ?? ""
  });

  useEffect(() => {
    refetch();
  }, [dateTime.startDate, dateTime.endDate, refetch, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateTime.startDate, dateTime.endDate])

  return (
    <div className='bg-linearBlack w-full h-full overflow-hidden'>
      <HeaderTab title={"Reward Records"} />
      <div className='pt-2 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3'>
        <RangePickerCustom
          startDate={dateTime.startDate}
          endDate={dateTime.endDate}
          className='mt-2'
          handleChangeDate={setDateTime}
          handleApplyDate={() => refetch()}
        />
        <div className='mt-5'>
          {dataRewardRecords?.data &&
            dataRewardRecords.data.length > 0 &&
            !isFetching && dataRewardRecords.data.map((item, index) => (
              <div
                key={index}
                className="p-3 mb-5 bg-[#171200] rounded-md border border-[#E3B85D]"
              >
                <div className='flex items-center justify-between gap-1'>
                  <div className='relative'>
                    <span
                      style={{ color: handleColorStatusWithString(item.status) }}
                      className="font-semibold mb-2 block"
                    >
                      {t(item.status)}
                    </span>
                    <p className='font-semibold text-[16px] text-white'>{item.promotion_name}</p>
                  </div>
                  <div className='flex flex-col items-end justify-center'>
                    <p className='flex gap-1 items-center'>
                      <span className='text-[12px] text-[#c8a45c]'>MVR</span>
                      <span className='text-[18px] font-bold text-[#ffe6b4]'>{item.reward_amount}</span>
                    </p>
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
                <div className='flex justify-between items-center mb-2'>
                  <span className='text-[12px] font-semibold text-[#c8a45c]'>{t("Reward Date")}:</span>
                  <p className='text-[12px] text-[#c8a45c]'>
                    {item.reward_date ? moment(item.reward_date).format("YYYY-MM-DD HH:mm:ss") : t("DateNotFounds")}
                  </p>
                </div>
                <div className='flex items-center gap-3 justify-between'>
                  <span className='text-[12px] font-semibold text-[#c8a45c]'>{t("Create Date")}:</span>
                  <span className='text-[12px] text-[#c8a45c]'>{item.created_date}</span>
                </div>
              </div>
            ))}
        </div>
        {dataRewardRecords?.data.length === 0 && !isFetching && (
          <div className='w-full h-[400px] flex items-center justify-center'>
            <Empty description={"Empty Data"} />
          </div>
        )}
        {(isLoading || isFetching) && (
          <LoadingCustom height={400} center />
        )}
        {!isFetching && (
          <PaginationsCustom
            lengthData={dataRewardRecords?.data.length || 0}
            currentPage={currentPage}
            pageSize={pageSize || 5}
            total={dataRewardRecords?.totalCount || 0}
            onChangePage={(page: number) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  )
}

export default RewardRecords