import { PATH } from "constants/path";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppStore } from "stores/app";

function LayoutNotAuth() {
  const isAuth = useAppStore((state) => state.isAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) return;
    navigate(PATH.home, { replace: true });
  }, [isAuth]);
  return <Outlet />;
}

export default LayoutNotAuth;
