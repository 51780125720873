// import { yupResolver } from '@hookform/resolvers/yup';
import { useDepositForm, useDepositOfflineSubmission } from "apis/deposit";
import clsx from "clsx";
import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import _map from "lodash/map";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  DepositOfflineSubmissionForm,
  OfflineBankingChannelType,
} from "types/deposit";
import SelectCustom from "components/SelectCustom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalNotification, {
  ModalNotificationProps,
} from "components/ModalNotification";
import { useTranslation } from "react-i18next";
import { message } from "antd";

const initialBankAccount = {
  bank_account_id: "",
  bank_account_name: "",
  bank_account_no: "",
  bank_name: "",
  bank_id: "",
  picture: "",
  max_deposit: "",
  min_deposit: "",
};

const DepositOffline = () => {
  const { t } = useTranslation("deposit");
  const [openNotification, setOpenNotification] =
    useState<ModalNotificationProps>({
      open: false,
      msg: "",
      desc: "",
    });

  const { data: dataDeposit } = useDepositForm();
  const [selectBankAccount, setSelectBankAccount] =
    useState<OfflineBankingChannelType>(initialBankAccount);
  const promotion = dataDeposit?.promotion;
  const currencies = dataDeposit?.currencies;

  const listSuggestedAmount = [500, 1000, 2000, 5000];
  listSuggestedAmount.unshift(Number(dataDeposit?.setting.min_deposit) || 100);
  listSuggestedAmount.push(Number(dataDeposit?.setting.max_deposit));
  const [file, setFile] = useState<File | null>(null);

  const { language } = i18next;

  const NONE_PROMOTION = [
    {
      label: t("None"),
      value: "",
    },
  ];

  const promotionOptions = useMemo(() => {
    const res = _map(promotion, (item) => ({
      label: item.title,
      value: item.id,
    }));

    if (promotion && promotion.length <= 0) {
      return res;
    }

    return [...NONE_PROMOTION, ...res];
  }, [promotion, language]);

  const schema = yup.object({
    amount: yup
      .number()
      .max(
        Number(dataDeposit?.setting.max_deposit),
        t("Max", { max: dataDeposit?.setting.max_deposit })
      )
      .min(
        Number(dataDeposit?.setting.min_deposit),
        t("Min", { min: dataDeposit?.setting.min_deposit })
      )
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(t("requiredFields")),
    username: yup.string().required(t("requiredFields")),
    currency_id: yup.number().required(t("requiredFields")),
    bank_account_id: yup.number().required(t("requiredFields")),
    receipt: yup.string().required(t("requiredFields")),
  });

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<DepositOfflineSubmissionForm>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      type: 1,
      username: localStorage.getItem("username") ?? "",
      bank_account_id: 0,
      transaction_id: "",
      receipt: "",
    },
  });

  useEffect(() => {
    if (!file) return;
    handleConver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleConver = async () => {
    if (!file) return;
    const fileBase64 = (await convertBase64(file)) as string;
    const receipt = fileBase64.split(",")[1];
    setValue("receipt", receipt);
  };

  const convertBase64 = (data: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  useEffect(() => {
    if (!dataDeposit) return;

    if (dataDeposit.offline_banking_channel.length === 0) return;

    setSelectBankAccount(dataDeposit.offline_banking_channel[0]);
    setValue(
      "bank_account_id",
      Number(dataDeposit.offline_banking_channel[0].bank_account_id)
    );
  }, [dataDeposit?.offline_banking_channel]);

  useEffect(() => {
    if (!currencies) return;
    if (currencies.length === 0) return;

    setValue("currency_id", Number(currencies[0].currency_id));
  }, [currencies]);

  const { mutateAsync, isPending } = useDepositOfflineSubmission({
    onSuccess: () => {
      reset();
      setTimeout(() => {
        if (!dataDeposit) return;
        setValue("currency_id", Number(dataDeposit.currencies[0].currency_id));
        setSelectBankAccount(dataDeposit.offline_banking_channel[0]);
        setValue(
          "bank_account_id",
          Number(dataDeposit.offline_banking_channel[0].bank_account_id)
        );
      }, 100);
    },
  });

  const onSubmit: SubmitHandler<DepositOfflineSubmissionForm> = async (
    data
  ) => {
    try {
      const res = await mutateAsync(data);
      if (res.status === 0) {
        setOpenNotification({
          open: true,
          msg: res.msg,
          desc: t("Code") + res.data,
        });
      } else {
        setOpenNotification({ open: true, msg: res.msg });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Lấy file đầu tiên từ input
    if (file) {
      setFile(file); // Cập nhật state với file đã chọn
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4 flex flex-col w-full">
        <label
          className='relative text-[#edcd90] px-2.5 font-medium mb-2 
          before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
          before:top-[50%] before:-translate-y-[50%]
          '
        >
          <span className="pl-3">{t("Bank Choose")}</span>
        </label>
        <div className="px-2.5 grid gap-2 grid-cols-[repeat(auto-fill,calc(33%_-_8px))]">
          {dataDeposit &&
            dataDeposit.offline_banking_channel.length > 0 &&
            dataDeposit.offline_banking_channel.map((item) => (
              <div
                key={item.bank_account_id}
                className={clsx(
                  "shadow-sm text-center overflow-hidden flex items-center justify-center flex-col gap-2",
                  "text-[14px] relative p-2 border border-[#4e442e] rounded-md bg-[#E3B85D80] text-black",
                  selectBankAccount.bank_account_id === item.bank_account_id &&
                    "text-black !border-[#E3B85D] bg-linearGold"
                )}
                onClick={() => {
                  setSelectBankAccount(item);
                  setValue("bank_account_id", Number(item.bank_account_id));
                }}
              >
                <div className="max-w-[70px]">
                  <img src={item.picture} alt="..." />
                </div>
                <p className="text-[14px] leading-[1] font-semibold">
                  {item.bank_name}
                </p>
              </div>
            ))}
        </div>
        <div className="w-full mt-3 flex flex-col">
          <span className="px-5 text-[#edcd90] text-[14px] font-semibold">
            {t("Bank Account")}
          </span>

          <div className="row flex justify-between items-center border-b px-5 border-[#E3B85D] ">
            <input
              type="text"
              className="h-[40px] text-[14px] outline-none bg-transparent py-2.6 text-white leading-[1.2] placeholder:text-[#B89032]"
              value={selectBankAccount.bank_account_name}
              disabled
            />
          </div>
        </div>
        <div className="w-full mt-3 flex flex-col">
          <span className="px-5 text-[#edcd90] text-[14px] font-semibold">
            {t("Bank Account Number")}
          </span>
          <div className="row flex justify-between items-center border-b px-5 border-[#E3B85D] ">
            <input
              type="text"
              className="h-[40px] flex-1 text-[14px] outline-none bg-transparent py-2.6 text-white leading-[1.2] placeholder:text-[#B89032]"
              value={selectBankAccount.bank_account_no}
              disabled
            />
            <span
              onClick={() => {
                message.success("Copy to clipboard successfully!");
                navigator.clipboard.writeText(
                  selectBankAccount.bank_account_no
                );
              }}
              className="w-[40px] h-[40px] flex items-center justify-center"
            >
              <svg
                className="w-4 fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z" />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4 flex flex-col w-full">
        <label
          className='relative text-[#edcd90] px-2.5 font-medium mb-2 
          before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
          before:top-[50%] before:-translate-y-[50%]
          '
        >
          <span className="pl-3">{t("Promotion")}</span>
        </label>
        <div className="px-5 border-b border-[#E3B85D] ">
          <SelectCustom
            control={control}
            name="promotion_id"
            options={promotionOptions}
            placeholder={t("Choose a promotion")}
            className="mb-0 h-[40px] text-[#E3B85D]"
            dropdownClassName="select-dropdown-custom !bg-[#42300c] border border-[#E3B85D] !text-[#ffffff] shadow-[0_0_15px_rgba(0,0,0,0.5)]"
          />
        </div>
      </div>
      <div className="mb-4 flex flex-col w-full">
        <label
          className='relative text-[#edcd90] px-2.5 font-medium mb-2 
          before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
          before:top-[50%] before:-translate-y-[50%]
          '
        >
          <span className="pl-3">{t("Transaction ID")}</span>
        </label>
        <input
          {...register("transaction_id")}
          type="text"
          className="h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.5 px-5 text-[#E3B85D] 
            leading-[1.2] placeholder:text-[#E3B85D80]"
          placeholder={t("enterTransactionId")}
        />
      </div>
      <div className="mb-4 flex flex-col w-full">
        <label
          className='relative text-[#edcd90] px-2.5 font-medium mb-2 
          before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
          before:top-[50%] before:-translate-y-[50%]
          '
        >
          <span className="pl-3">{t("Amount")}</span>
        </label>
        <input
          type="number"
          {...register("amount")}
          className={clsx(
            "h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.5 px-5 text-[#E3B85D]",
            "leading-[1.2] placeholder:text-[#E3B85D80]",
            errors.amount && "border-red-500 !bg-[#ff000017]"
          )}
          autoComplete="off"
          placeholder={t("MinMaxAmount", {
            min: dataDeposit?.setting.min_deposit,
            max: dataDeposit?.setting.max_deposit,
          })}
        />
        {errors.amount && (
          <span className="text-[14px] px-5 text-red-500">
            {errors.amount.message}
          </span>
        )}
      </div>
      <div className="mb-4 grid grid-cols-3 gap-2 px-2.5">
        {listSuggestedAmount.map((item: number) => {
          return (
            <div
              onClick={() => {
                setValue("amount", Number(item));
                clearErrors("amount");
              }}
              key={item}
              className={clsx(
                "flex items-center justify-center bg-[#6e5d3a80] p-1 rounded-md",
                watch("amount") === item &&
                  "border border-[#E3B85D] bg-[#836d40]"
              )}
            >
              <span>{item}</span>
            </div>
          );
        })}
      </div>
      <div className="w-full mt-3 flex flex-col">
        <span className="px-5 text-[#edcd90] text-[14px] font-semibold">
          {t("Upload Receipt")}
        </span>
        <input
          type="file"
          accept=".png,.jpg,.jpeg"
          multiple={false}
          className={clsx(
            "h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.5 px-5 text-[#E3B85D]",
            "leading-[1.2] placeholder:text-[#E3B85D80]",
            errors.amount && "border-red-500 !bg-[#ff000017]"
          )}
          onChange={handleFileChange}
        />
        {errors.receipt && (
          <span className="text-[14px] px-5 text-red-500">
            {errors.receipt.message}
          </span>
        )}
      </div>
      <div className="pt-3 text-center">
        <button
          className="max-w-[370px] w-full h-[46px] mx-auto flex items-center justify-center bg-linearGold rounded-md text-black font-semibold
            disabled:bg-gradientDisabled disabled:cursor-no-drop disabled:text-[#F5D598]"
          type="submit"
          disabled={isPending}
        >
          {t("Submit")}
        </button>
      </div>
      <ModalNotification
        open={openNotification.open}
        onClose={() =>
          setOpenNotification((prev) => ({ ...prev, open: false }))
        }
        mesage={openNotification.msg}
        description={openNotification.desc}
      />
    </form>
  );
};

export default DepositOffline;
