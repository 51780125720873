import BottomTabs from "components/BottomTabs";
import { Outlet } from "react-router-dom";

function BottomTabLayout() {
  return (
    <>
      <Outlet />
      <BottomTabs />
    </>
  );
}

export default BottomTabLayout;
