import { useReferralReward } from "apis/auth/referral";
import HeaderTab from "components/HeaderTab";
import LoadingCustom from "components/LoadingCustom";
import moment from "moment";
import { useTranslation } from "react-i18next";

function CollectionRecord() {
  const { t } = useTranslation(["referral"]);
  const { data: referral, isLoading } = useReferralReward();

  const collection = referral?.collection_record;

  return (
    <div className="flex flex-col">
      <HeaderTab title="Collection record" />
      <div className="flex flex-col mx-2 bg-slate-900 min-h-96">
        <table className="mt-4">
          <thead className="bg-linearGold">
            <tr>
              <th className="py-2 text-black">{t("Date")}</th>
              <th className="py-2 text-black">{t("Amount")}</th>
              <th className="py-2 text-black">{t("Status")}</th>
            </tr>
          </thead>
          <tbody>
            {collection && collection.map((item, index) => {
              return (
                <tr key={index} className="border-b odd:bg-slate-950">
                  <td className="py-2 text-center">
                    {moment(item.date).format("YYYY-MM-DD")}
                  </td>
                  <td className="py-2 text-center">{item.amount}</td>
                  <td className="py-2 text-center">
                    {item.status ? "active" : "inactive"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoading && <LoadingCustom height={400} center />}
      </div>
    </div>
  );
}

export default CollectionRecord;
