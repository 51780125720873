import { CaretDownOutlined } from '@ant-design/icons';
import { Empty, Popover } from 'antd';
import { useTransaction } from 'apis/transactions';
import clsx from 'clsx';
import HeaderTab from 'components/HeaderTab';
import LoadingCustom from 'components/LoadingCustom';
import PaginationsCustom from 'components/PaginationsCustom';
import RangePickerCustom from 'components/RangePickerCustom';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type dateType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

type statusType = {
  id: string
  name: string
}

const optionStatus: statusType[] = [
  { id: "", name: "All" },
  { id: "0", name: "Pending" },
  { id: "1", name: "Approve" },
  { id: "2", name: "Reject" },
  { id: "3", name: "Processing" },
]

const RebateRecords = () => {
  const { t } = useTranslation("transactions");
  const btnRef = useRef<HTMLButtonElement>(null)

  const [dateTime, setDateTime] = useState<dateType>({
    startDate: dayjs().subtract(7, "day"),
    endDate: dayjs()
  })
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;
  const [selectedStatus, setSelectedStatus] = useState<statusType>(optionStatus[0])
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const { data: dataMemberOrder, refetch, isFetching, isLoading } = useTransaction({
    type: 3,
    size: pageSize,
    page: currentPage,
    status: selectedStatus.id,
    start: dateTime.startDate?.format("YYYY/MM/DD").toString() ?? "",
    end: dateTime.endDate?.format("YYYY/MM/DD").toString() ?? ""
  });

  useEffect(() => {
    refetch();
  }, [dateTime.startDate, dateTime.endDate, refetch, selectedStatus, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateTime.startDate, dateTime.endDate, selectedStatus])

  return (
    <div className='bg-linearBlack w-full h-full overflow-hidden'>
      <HeaderTab title={t("RebateRecords")} />
      <div className='pt-4 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3'>
        <div className='flex items-center justify-between gap-3'>
          <div className='text-[#E3B85D] text-[14px] font-semibold '>{t("Status")}: </div>
          <div className="flex items-center w-full max-w-[300px]">
            <Popover
              trigger="click"
              open={openPopover}
              onOpenChange={(open) => setOpenPopover(open)}
              content={
                <div className='flex flex-col items-start'>
                  {optionStatus.map(item => (
                    <button
                      key={item.name}
                      onClick={() => {
                        setSelectedStatus(item);
                        setOpenPopover(false);
                      }}
                      className={clsx(
                        "text-[#ffffff] py-2 px-4 text-[12px] rounded-md leading-[1] w-full text-left",
                        selectedStatus.id === item.id && "bg-[#543c00]"
                      )}
                    >
                      {t(item.name)}
                    </button>
                  ))}
                </div>
              }
              arrow={false}
              placement='bottom'
              overlayClassName={clsx(
                "popover-custom bg-[#302000] border border-[#b8871b] rounded-md p-2",
              )}
              overlayStyle={{ width: `${btnRef.current?.offsetWidth}px` }}
            >
              <button
                ref={btnRef}
                className="w-full flex items-center justify-between text-[14px] gap-1 bg-black border border-[#4e442e] text-[#E3B85D] py-2 px-3 rounded-sm h-[36px]"
              >
                <span className='truncate'>{t(selectedStatus.name)}</span>
                <CaretDownOutlined />
              </button>
            </Popover>
          </div>
        </div>
        <RangePickerCustom
          startDate={dateTime.startDate}
          endDate={dateTime.endDate}
          className='mb-3 mt-5'
          handleChangeDate={setDateTime}
          handleApplyDate={() => refetch()}
        />
        <div className='flex flex-col mt-5'>
          {
            dataMemberOrder?.data &&
            dataMemberOrder.data.length > 0 &&
            !isFetching &&
            dataMemberOrder.data.map((item, i) => (
              <div key={i} className='p-3 mb-5 bg-[#000000] rounded-md border border-[#E3B85D]'>
                <h2 className='font-semibold text-[20px] text-[#c8a45c]'>{item.Bonus_type}</h2>
                <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
                <div className='flex items-center justify-between gap-1'>
                  <p className='text-[14px]'>{item.Member}</p>
                  <div className='flex flex-col items-end'>
                    <p className='flex gap-1 items-center my-1'>
                      <span className='text-[12px] text-[#c8a45c]'>MVR</span>
                      <span className='text-[18px] font-bold text-[#ffe6b4]'>{item.Amount}</span>
                    </p>
                    <p className={clsx("text-xs", Number(item.Rate) < 0.00 ? "text-[#e44141]" : "text-[#2bd32b]")}>{item.Rate}%</p>
                  </div>
                </div>
                <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
                <div className='flex items-center justify-between gap-1'>
                  <p className='text-[12px] text-[#c8a45c]'>{moment(item.Date).format("YYYY-MM-DD HH:mm:ss")}</p>

                </div>
              </div>
            ))}
        </div>
        {dataMemberOrder?.data.length === 0 && !isFetching && (
          <div className='w-full h-[400px] flex items-center justify-center'>
            <Empty description={"Empty Data"} />
          </div>
        )}
        {(isLoading || isFetching) && (
          <LoadingCustom height={400} center />
        )}
        {!isFetching && <PaginationsCustom
          lengthData={dataMemberOrder?.data.length || 0}
          currentPage={currentPage}
          pageSize={pageSize || 5}
          total={dataMemberOrder?.totalCount || 0}
          onChangePage={(page: number) => setCurrentPage(page)}
        />}
      </div>
    </div>
  )
}

export default RebateRecords