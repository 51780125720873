import React from "react";
import "./BottomSheet.css";

type BottomSheetType = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  contentClass?: string;
};

const BottomSheet = ({
  isOpen,
  onClose,
  children,
  contentClass,
}: BottomSheetType) => {
  return (
    <div className={`bottom-sheet bg-slate-900 ${isOpen ? "open" : ""}`}>
      {isOpen && <div className="bottom-sheet-overlay" onClick={onClose}></div>}
      <div
        className={`bottom-sheet-content container-max-width ${
          contentClass ? contentClass : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default BottomSheet;
