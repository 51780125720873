import { CaretDownOutlined } from "@ant-design/icons";
import { Empty, Popover } from "antd";
import { TransactionItemProps, useTransaction } from "apis/transactions";
import clsx from "clsx";
import HeaderTab from "components/HeaderTab";
import LoadingCustom from "components/LoadingCustom";
import PaginationsCustom from "components/PaginationsCustom";
import RangePickerCustom from "components/RangePickerCustom";
import dayjs, { Dayjs } from "dayjs";
import { OrderStatus } from "enums/orders";
import { handleColorStatus, showStatusText } from "helper/showStatusText";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

type dateType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

const optionsTab = [
  {
    name: "Deposit",
    type: 1,
  },
  {
    name: "Withdrawal",
    type: 2,
  },
  {
    name: "Transfer",
    type: 5,
  },
];

type statusType = {
  id: string;
  name: string;
};

const optionStatus: statusType[] = [
  { id: "", name: "All" },
  { id: "0", name: "Pending" },
  { id: "1", name: "Approve" },
  { id: "2", name: "Reject" },
  { id: "3", name: "Processing" },
];

const TransactionHistory = () => {
  const { t } = useTranslation("transactions");
  const [params, setParams] = useSearchParams();
  const paramTab = params.get("tab");
  const [dateTime, setDateTime] = useState<dateType>({
    startDate: dayjs().subtract(7, "day"),
    endDate: dayjs(),
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;
  const [selectedStatus, setSelectedStatus] = useState<statusType>(
    optionStatus[0],
  );
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  useEffect(() => {
    if (paramTab) return;
    params.set("tab", optionsTab[0].type + "");
    setParams(params, { replace: true });
  }, [paramTab]);

  const {
    data: dataMemberOrder,
    refetch,
    isFetching,
    isLoading,
  } = useTransaction({
    type: Number(paramTab),
    size: pageSize,
    page: currentPage,
    status: selectedStatus.id,
    start: dateTime.startDate?.format("YYYY/MM/DD").toString() ?? "",
    end: dateTime.endDate?.format("YYYY/MM/DD").toString() ?? "",
  });

  useEffect(() => {
    refetch();
  }, [
    dateTime.startDate,
    dateTime.endDate,
    refetch,
    selectedStatus,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateTime.startDate, dateTime.endDate, selectedStatus]);

  return (
    <div className="bg-linearBlack w-full h-full overflow-hidden">
      <HeaderTab title={t("Transactions")} />
      <div className="pt-4 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start gap-2">
            {optionsTab.map((item) => (
              <button
                key={item.type}
                onClick={() => {
                  params.set("tab", item.type + "");
                  setParams(params, { replace: true });
                }}
                className={clsx(
                  "shadow-sm text-center overflow-hidden flex items-center justify-center flex-col gap-2 font-semibold outline-none",
                  "text-[12px] relative py-2 px-3 border border-[#4e442e] rounded-md bg-[#E3B85D80] text-black",
                  item.type === Number(paramTab) &&
                  "text-black !border-[#E3B85D] bg-linearGold",
                )}
              >
                {t(item.name)}
              </button>
            ))}
          </div>
          <span className="text-[#4e442e] lg:hidden">|</span>
          <div className="flex items-center gap-3">
            <Popover
              trigger="click"
              open={openPopover}
              onOpenChange={(open) => setOpenPopover(open)}
              content={
                <div className="flex flex-col">
                  {optionStatus.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => {
                        setSelectedStatus(item);
                        setOpenPopover(false);
                      }}
                      className={clsx(
                        "text-[#ffffff] py-2 px-4 text-[12px] rounded-md leading-[1] text-left",
                        selectedStatus.id === item.id && "bg-[#543c00]",
                      )}
                    >
                      {t(item.name)}
                    </button>
                  ))}
                </div>
              }
              arrow={false}
              placement="bottomRight"
              overlayClassName="popover-custom bg-[#302000] border border-[#b8871b] rounded-md p-2"
            >
              <button
                className={clsx(
                  "w-[85px] flex items-center justify-between whitespace-nowrap text-[12px] gap-1 bg-linearGold text-black py-2 px-3 rounded-md font-semibold h-[36px]",
                  Number(paramTab) === 3 &&
                  "pointer-events-none !bg-gradientDisabled",
                )}
              >
                <span className="truncate">{t(selectedStatus.name)}</span>
                <CaretDownOutlined />
              </button>
            </Popover>
          </div>
        </div>
        <RangePickerCustom
          startDate={dateTime.startDate}
          endDate={dateTime.endDate}
          className="mt-5 mb-3"
          handleChangeDate={setDateTime}
          handleApplyDate={() => refetch()}
        />
        <div className="flex flex-col mt-5">
          {dataMemberOrder?.data &&
            dataMemberOrder.data.length > 0 &&
            !isFetching &&
            dataMemberOrder.data.map((item, index) => {
              if (Number(paramTab) === 5) {
                return <MemberOrderTransfer item={item} key={index} />;
              }
              return <MemberOrderItem item={item} key={index} />;
            })}
        </div>
        {dataMemberOrder?.data.length === 0 && !isFetching && (
          <div className="w-full h-[400px] flex items-center justify-center">
            <Empty description={"Empty Data"} />
          </div>
        )}
        {(isLoading || isFetching) && <LoadingCustom height={400} center />}
        {!isFetching && (
          <PaginationsCustom
            lengthData={dataMemberOrder?.data.length || 0}
            currentPage={currentPage}
            pageSize={pageSize || 5}
            total={dataMemberOrder?.totalCount || 0}
            onChangePage={(page: number) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
};

const MemberOrderItem = ({ item }: { item: TransactionItemProps }) => {
  const { t } = useTranslation("transactions");
  return (
    <div
      key={item.transaction_code}
      className="p-3 mb-5 bg-[#000000] rounded-md border border-[#E3B85D] flex items-start justify-between gap-1"
    >
      <div className="flex flex-col">
        <p className="font-semibold text-[16px] text-white my-2">
          {item.transaction_code}
        </p>
        <p className="text-[12px] text-[#c8a45c]">
          {moment(item.create_date).format("YYYY-MM-DD HH:mm:ss")}
        </p>
      </div>
      <div className="flex flex-col items-end">
        <span
          style={{
            color: handleColorStatus(item.status as OrderStatus),
          }}
          className="font-semibold mb-2"
        >
          {t(showStatusText(item.status as OrderStatus))}
        </span>
        <p className="flex gap-1 items-center my-1">
          <span className="text-[12px] text-[#c8a45c]">MVR</span>
          <span className="text-[18px] font-bold text-[#ffe6b4]">
            {item.amount}
          </span>
        </p>
      </div>
    </div>
  );
};

const MemberOrderTransfer = ({ item }: { item: TransactionItemProps }) => {
  const { t } = useTranslation("transactions");
  return (
    <div
      key={item.transaction_code}
      className="p-3 mb-5 bg-[#000000] rounded-md border border-[#E3B85D] flex flex-col gap-1"
    >
      <div className="flex items-start justify-between">
        <div className="flex flex-col">
          <p className="font-semibold text-[16px] text-white my-2">
            {item.friend || "Unknown"}
          </p>
          <p className="text-[12px] text-[#c8a45c]">
            {moment(item.report_date).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <span
            style={{
              color: handleColorStatus(item.status as OrderStatus),
            }}
            className="font-semibold mb-2"
          >
            {t(showStatusText(item.status as OrderStatus))}
          </span>
          <p className="flex gap-1 items-center my-1">
            <span className="text-[12px] text-[#c8a45c]">MVR</span>
            <span
              className={`text-lg  font-semibold ${item.transfer === "send" ? "text-red-500" : "text-green-500"
                }`}
            >
              {item.amount}
            </span>
          </p>
        </div>
      </div>

      <div className="flex flex-row items-center justify-between">
        <span className="text-sm">
          After:
          <span className="font-semibold text-orange-300 ml-2">
            {item.balance_after || "0.00"}
          </span>
        </span>
        <span className="text-sm">
          Before:
          <span className="font-semibold text-orange-300 ml-2">
            {item.balance_before || "0.00"}
          </span>
        </span>
      </div>
    </div>
  );
};

export default TransactionHistory;
