import { useEffect, useState } from "react";

const useIsSamsungNote = () => {
  const [isSamsungNote, setIsSamsungNote] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const samsungNoteKeywords = [
      "sm-n", // Các model Note thường có mã SM-Nxxxx
      "galaxy note",
    ];

    const isNote = samsungNoteKeywords.some((keyword) =>
      userAgent.includes(keyword)
    );

    setIsSamsungNote(isNote);
  }, []);

  return isSamsungNote;
};

export default useIsSamsungNote;
