import { Modal } from 'antd';
import clsx from 'clsx';
import React from 'react'

export interface ModalNotificationProps {
  open: boolean,
  msg: string,
  desc?: string
}

interface Props {
  mesage?: string
  description?: string
  open: boolean;
  onClose: () => void;
  childrend?: React.ReactNode
  bodyClassName?: string
  titleClassName?: string
  className?: string
}


const ModalNotification = ({
  mesage,
  onClose,
  open,
  description,
  className = "",
  titleClassName = "",
  bodyClassName = "",
  childrend
}: Props) => {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={false}
      modalRender={node => (
        <div className='custom-modal !bg-transparent'>{node}</div>
      )}
      className={`top-[30%] ${className}`}
    >
      <div className='rounded-lg overflow-hidden bg-[#241800] text-white border border-[#E3B85D]'>
        <h3 className={clsx(
          "bg-linearGold text-center text-[20px] font-bold text-[#000000] py-2 px-4",
          titleClassName
        )}>
          Notification !
        </h3>
        <div className={clsx(
          "overflow-y-auto max-h-[70vh] p-5 text-center font-semibold text-[18px]",
          bodyClassName
        )}>
          {mesage}
          {description && <span className='mt-2 block text-[14px] text-[#e9b336]'>
            {description}
          </span>}
          {childrend && childrend}
        </div>
      </div>
    </Modal>
  )
}

export default ModalNotification