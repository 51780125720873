import HeaderTab from 'components/HeaderTab'
import CardAuthenVip from './components/RankCard'
import BannerMonthly from "assets/images/rankingPage/ranking-monthly.png"
import BannerWeekly from "assets/images/rankingPage/ranking-weekly.png"
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { getDateTimeRangeRanking } from 'constants/week-date-time';

const RankingPage = () => {
  const { t } = useTranslation("ranking");
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedClass = 'border-b border-[#E3B85D]';

  const { weekRange, monthlyRange } = getDateTimeRangeRanking();

  return (
    <div className='bg-linearBlack w-full h-full'>
      <HeaderTab title="Ranking Page" />
      <div className='overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide'>
        <div className='flex text-center text-xs text-[#E3B85D] leading-10'>
          <div onClick={() => setSelectedTab(0)} className={`${selectedTab === 0 ? selectedClass : "opacity-60"} flex-1 flex items-center justify-center`}>{t("weeklyRanking")}</div>
          <div className='border-r border-[#E3B85D] h-5 mt-3' />
          <div onClick={() => setSelectedTab(1)} className={`${selectedTab === 1 ? selectedClass : "opacity-60"} flex-1 flex items-center justify-center`}>{t("total")}</div>
        </div>
        <div className="w-full min-h-40 rounded-xl items-center justify-center flex bg-gray-700">
          <img src={selectedTab === 0 ? BannerWeekly : BannerMonthly} alt='banner' />
        </div>
        <div className='py-4 w-full px-3'>
          <div className='w-full h-full flex items-center justify-center'>
            <CardAuthenVip selectedTab={selectedTab} />
          </div>
          <div className='mt-10 text-[14px]'>
            {selectedTab === 0 && (
              <React.Fragment>
                <h3 className='px-3 text-center text-[22px] font-bold mb-5 text-[#EDCD90]'>{t("weeklyDesc.title")}</h3>
                <p className='mb-5 text-[#EDCD90]'>{t("weeklyDesc.desc")}</p>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_1")}:</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>IPHONE 16 PRO MAX 1TB</p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.Eventperiod")}:</p>
                  <p className='font-semibold text-[#EDCD90] text-[13px]'>{weekRange}</p>
                </div>
              </React.Fragment>
            )}
            {selectedTab === 1 && (
              <React.Fragment>
                <h3 className='px-3 text-center text-[22px] font-bold mb-5 text-[#EDCD90]'>{t("monthlyDesc.title")}</h3>
                <p className='mb-5 text-[#EDCD90]'>{t("monthlyDesc.desc")}</p>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_1")}:</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>
                    50,000.00
                    <span className='font-normal text-[14px] pl-1'>MVR</span>
                  </p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_2")}:</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>
                    30,000.00
                    <span className='font-normal text-[14px] pl-1'>MVR</span>
                  </p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_3")}:</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>
                    15,000.00
                    <span className='font-normal text-[14px] pl-1'>MVR</span>
                  </p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_4_10")} :</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>
                    5,000.00
                    <span className='font-normal text-[14px] pl-1'>MVR</span>
                  </p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_11_20")}:</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>
                    2,500.00
                    <span className='font-normal text-[14px] pl-1'>MVR</span>
                  </p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking.place_21_70")}:</p>
                  <p className='text-[18px] font-semibold text-[#b68800]'>
                    1,800.00
                    <span className='font-normal text-[14px] pl-1'>MVR</span>
                  </p>
                </div>
                <div className='w-full flex items-center justify-between mb-3'>
                  <p className='font-semibold'>{t("labelRanking:Eventperiod")}:</p>
                  <p className='font-semibold text-[#EDCD90] text-[13px]'>{monthlyRange}</p>
                </div>
              </React.Fragment>
            )}
            <p className='mb-5 text-[#EDCD90]'>{t("labelRanking.reserved")}</p>
            <p className='text-center mt-5 text-xs text-[#EDCD90] border-t border-[#4e442e] pt-3'>{t("labelRanking.copyright")}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RankingPage