import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";

export interface ProfileFormProps {
  fullname?: string;
  nickname?: string;
  email?: string;
  mobile?: string;
  birth?: string;
  otp: string;
}

export interface FormProps {
  onSuccess?: () => void;
}
export const useProfileForm = (props: FormProps) => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["UpdateMemberDetail"],
    mutationFn: async (payload: ProfileFormProps) => {
      const res = await fetchAPI(
        { ...payload, username },
        "UpdateMemberDetail",
        username,
      );
      if (!res.status) {
        return res;
      }
      return Promise.reject(res.msg);
    },
    onSuccess: (data) => {
      message.success(data.msg);
      props.onSuccess && props.onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
