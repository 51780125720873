import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useParams } from "react-router-dom";
import { useFriendDetail } from "./friend-detail";
import { useBalance } from "apis/auth/balance";
import { useMemberDetail } from "apis/auth/member-detail";

export interface FriendTransferForm {
  username: string;
  friend_username: string;
  amount: string;
}

interface SuccessProps {
  onSuccess: () => void;
}

export const useFriendTransfer = (props: SuccessProps) => {
  const { code } = useParams();
  const { refetch } = useFriendDetail(code + "");
  const { refetch: balanceRefesh } = useBalance();
  const { refetch: profileRefesh } = useMemberDetail();
  return useMutation({
    mutationKey: ["FriendTransfer"],
    mutationFn: async (payload: FriendTransferForm) => {
      const res = await fetchAPI(payload, "FriendTransfer", payload.username);
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: (data) => {
      message.success(data.msg);
      refetch();
      balanceRefesh();
      profileRefesh();
      props.onSuccess && props.onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
