import { message } from "antd";
import { useMemberDetail } from "apis/auth/member-detail";
import { useCloseGame } from "apis/games/close-game";
import { useLoginGameInterface } from "apis/games/LoginGameInterface";
import IconDeposit from "assets/images/icon-withdrawal.png";
import HeaderTab from "components/HeaderTab";
import { PATH } from "constants/path";
import { useLoginGame } from "helper/handleLoginGame";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import TimeLine from "./time-line";
import { createAmountSelect } from "helper/createAmountSelect";
enum STATUS_GAME {
  playing = "playing",
  close = "close",
}
function PlayingGameControlsChips() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const paramsObject = Object.fromEntries(params.entries()); // obj params game lấy từ link
  const { data: profile } = useMemberDetail();
  const { data: gameInterface } = useLoginGameInterface();
  const { t } = useTranslation("game");
  const [playing, setPlaying] = useState(STATUS_GAME.close);
  const [amount, setAmount] = useState("");
  const { mutate } = useCloseGame();
  const { onLoginGame, windowTab } = useLoginGame();
  const isPlaying = playing === STATUS_GAME.playing;

  useEffect(() => {
    if (!profile?.chip_points) return;
    if (!profile?.points) return;
    setAmount(
      (parseFloat(profile?.chip_points) + parseFloat(profile?.points)).toFixed(
        2
      )
    );
  }, [profile?.chip_points, profile?.points]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Gọi API khi người dùng rời trang
      // ****
      mutate();
      setPlaying(STATUS_GAME.close);
      if (windowTab.current) {
        windowTab.current.close();
      }
      event.preventDefault();
      event.returnValue = t("messageAlert");
    };
    if (playing === STATUS_GAME.playing) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [playing]);

  useEffect(() => {
    if (!isPlaying) return;
    const monitorTab = setInterval(() => {
      if (windowTab.current && windowTab.current.closed) {
        clearInterval(monitorTab);
        mutate();
        setPlaying(STATUS_GAME.close);
      }
    }, 1000);
    return () => {
      clearInterval(monitorTab);
    };
  }, [isPlaying]);

  const onPlayingGame = () => {
    setPlaying(isPlaying ? STATUS_GAME.close : STATUS_GAME.playing);
    if (!isPlaying) {
      if (paramsObject) {
        onLoginGame(paramsObject);
        localStorage.setItem("data-on-game", JSON.stringify(paramsObject));
      }
    } else {
      mutate();
      setPlaying(STATUS_GAME.close);
      if (windowTab.current) {
        windowTab.current.close();
      }
    }
  };
  return (
    <div className="flex flex-col">
      <HeaderTab
        onLeft={() => {
          if (!isPlaying) {
            return navigate(-1);
          }
          message.warning(t("messageWarning"));
        }}
        title={paramsObject?.name || ""}
      />
      {/* <div className="flex items-center bg-slate-900 px-4 py-2">
        <span>Game rewards:</span>
        <span className="flex items-center ml-4">
          <span className="text-red-600 font-semibold">
            {gameInterface?.game_reward_new}%
          </span>
        </span>
      </div> */}
      <div className="flex items-center border-b border-slate-500 mx-4 py-3">
        <div className="flex-1 pr-4">
          <div className="flex items-center justify-between text-sm">
            <span>
              Balance<span className="text-gray-400">(MVR):</span>
            </span>
            <span className="font-bold">{profile?.points || "0.00"}</span>
          </div>
          <div className="flex items-center justify-between mt-3 text-sm">
            <span>
              Chips<span className="text-gray-400">(MVR):</span>
            </span>
            <span className="font-bold">{profile?.chip_points || "0.00"}</span>
          </div>
          <div className="flex items-center justify-between mt-3 text-sm">
            <span>
              Amount<span className="text-gray-400">(MVR):</span>
            </span>
            <span className="font-bold">
              {profile?.chip_points && profile?.points
                ? (
                    parseFloat(profile?.chip_points) +
                    parseFloat(profile?.points)
                  ).toFixed(2)
                : "0.00"}
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            if (!isPlaying) return navigate(PATH.topup);
            message.warning(t("messageWarning"));
          }}
          className="w-1/6 flex items-center justify-center border-l border-slate-500"
        >
          <img src={IconDeposit} className="w-12" />
        </div>
      </div>
      <div className="flex flex-col">
        <p className="text-red-500 text-sm text-center mt-2">
          *This game cannot modify the amount
        </p>
      </div>
      <div className="flex flex-col mx-4 mt-3">
        <input
          placeholder="0.00"
          className="h-12 bg-slate-900 text-center rounded-xl outline-none"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          readOnly
        />
        <div className="flex items-center flex-wrap justify-around mt-4">
          {createAmountSelect(100).map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-center justify-center px-3 py-2 text-sm bg-slate-800 opacity-60 rounded-xl"
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div
        onClick={onPlayingGame}
        className={`${
          !isPlaying ? "bg-linearGold" : "bg-red-500"
        } mt-4 w-auto mx-4 text-center py-2 px-6 rounded uppercase`}
      >
        {isPlaying ? (
          <TimeLine title={t("Stopgame")} />
        ) : (
          <span className={`text-white font-bold`}>{t("Startgame")}</span>
        )}
      </div>
      <div className="flex flex-row mx-4 mt-2 text-gray-300">
        <div className="flex-1 text-sm">
          <span>Turnover: {gameInterface?.turnover || "0.00"}</span>
        </div>
        <div className="flex-1 text-sm">
          <span>Last Turnover: {gameInterface?.last_turnover || "0.00"}</span>
        </div>
      </div>
    </div>
  );
}

export default PlayingGameControlsChips;
