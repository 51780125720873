export function utilFindClosestItems<T>(
  items: T[],
  totalBet: number,
  getBetAmount: (item: T) => number
): T[] {
  const sortedItems = items
    .map((item) => ({
      ...item,
      betAmountNumber: getBetAmount(item),
    }))
    .sort((a, b) => a?.betAmountNumber - b?.betAmountNumber);

  const result = sortedItems.reduce<{ prev?: T; next?: T }>(
    (acc, item) => {
      const betAmount = getBetAmount(item);

      // Nếu betAmount bằng totalBet, đặt item làm next và prev là item trước đó
      if (betAmount === totalBet) {
        acc.next = item;
        return acc;
      }

      // Nếu betAmount nhỏ hơn hoặc bằng totalBet
      if (betAmount < totalBet) {
        acc.prev = item;
      } else if (!acc.next && betAmount > totalBet) {
        acc.next = item;
        return acc; // Thoát vòng lặp sớm
      }

      return acc;
    },
    { prev: undefined, next: undefined }
  );

  // Nếu totalBet trùng với một item, đảm bảo prev được xử lý đúng
  if (result.next && getBetAmount(result.next) === totalBet) {
    const index = sortedItems.findIndex(
      (item) => getBetAmount(item) === totalBet
    );
    result.prev = sortedItems[index - 1] ?? undefined;
  }

  // Trường hợp totalBet nhỏ hơn tất cả các giá trị trong mảng, trả về 2 item đầu tiên
  if (totalBet < sortedItems[0]?.betAmountNumber || totalBet === 0) {
    return [sortedItems[0], sortedItems[1]].filter(Boolean);
  }

  // Trường hợp totalBet lớn hơn tất cả các giá trị trong mảng, trả về 2 item cuối cùng
  if (!result.next) {
    const lastIndex = sortedItems.length - 1;
    return [sortedItems[lastIndex - 1], sortedItems[lastIndex]].filter(Boolean);
  }

  // Trả về các item gần nhất
  return [result.prev, result.next].filter(Boolean) as T[];
}
