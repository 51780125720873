import { usePerformanceQuery } from 'apis/referral'
import clsx from 'clsx'
import LoadingCustom from 'components/LoadingCustom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type tabType = {
  id: string
  title: string
}

const optionsTabs: tabType[] = [
  { id: "1", title: "Yesterday" },
  { id: "2", title: "DayBeforeYesterday" },
]

const PerformanceQuery = () => {
  const { t } = useTranslation("referral")
  const [activeTab, setActiveTab] = useState<tabType>(optionsTabs[0]);
  const { data, isLoading } = usePerformanceQuery();

  return (
    <div className='relative'>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3'>
        <h2 className='text-[#EDCD90] font-semibold text-center text-lg mb-4'>{t("performanceQuery.PerformanceQuery")}</h2>
        <div className='flex items-center justify-center p-2 border border-[#4e442e] rounded-lg bg-[#000000] text-black h-[70px]'>
          {optionsTabs.map(item => (
            <button
              key={item.id}
              className={clsx(
                "w-2/4 text-[#E3B85D] font-semibold flex items-center justify-center h-full rounded-md opacity-70",
                activeTab.id === item.id && "bg-linearGold text-black !opacity-100"
              )}
              onClick={() => setActiveTab(item)}
            >
              {t(`performanceQuery.${item.title}`)}
            </button>
          ))}
        </div>
        {data && <table className='mt-4 text-[12px] w-full'>
          <thead>
            <tr>
              <td className='font-semibold text-[#EDCD90] py-2.5 pl-2.5 w-1/4'>{t("performanceQuery.Gametype")}</td>
              <td className='text-center font-semibold text-[#EDCD90] py-2.5 w-1/4'>{t("performanceQuery.TotalPerfor")}</td>
              <td className='text-center font-semibold text-[#EDCD90] py-2.5 w-1/4'>{t("performanceQuery.DirectPerfor")}</td>
              <td className='text-center font-semibold text-[#EDCD90] py-2.5 w-1/4'>{t("performanceQuery.AgentPerfor")}</td>
            </tr>
          </thead>
          {activeTab.id === "1" &&
            data.yesterday.length > 0 && (
              <tbody className='table-row-group'>
                {data.yesterday.map((item, i) => (
                  <tr key={i} className='border-t border-[#fde08c] first-of-type:border-none'>
                    <td className='text-[#E3B85D] py-2.5 pl-2.5 w-1/4'>{item.game_type}</td>
                    <td className='text-center text-[#E3B85D] py-2.5 w-1/4'>{item.total_performance}</td>
                    <td className='text-center text-[#E3B85D] py-2.5 w-1/4'>{item.direct_performance}</td>
                    <td className='text-center text-[#E3B85D] py-2.5 w-1/4'>{item.agent_performance}</td>
                  </tr>
                ))}
                <tr className='bg-[#d5ab52] text-black mt-2 font-semibold'>
                  <td className='py-2.5 pl-2.5 w-1/4 rounded-tl-sm rounded-bl-sm'>{t("performanceQuery.Total")}</td>
                  <td className='text-center py-2.5 w-1/4'>{data.yesterday.reduce((total, item) => total + Number(item.total_performance), 0) + ".00"}</td>
                  <td className='text-center py-2.5 w-1/4'>{data.yesterday.reduce((total, item) => total + Number(item.direct_performance), 0) + ".00"}</td>
                  <td className='text-center py-2.5 w-1/4 rounded-tr-sm rounded-br-sm'>{data.yesterday.reduce((total, item) => total + Number(item.agent_performance), 0) + ".00"}</td>
                </tr>
              </tbody>
            )}
          {activeTab.id === "2" &&
            data.day_before_yesterday.length > 0 && (
              <tbody className='table-row-group'>
                {data.day_before_yesterday.map((item, i) => (
                  <tr key={i} className='border-t border-[#fde08c] first-of-type:border-none'>
                    <td className='text-[#E3B85D] py-2.5 pl-2.5 w-1/4'>{item.game_type}</td>
                    <td className='text-center text-[#E3B85D] py-2.5 w-1/4'>{item.total_performance}</td>
                    <td className='text-center text-[#E3B85D] py-2.5 w-1/4'>{item.direct_performance}</td>
                    <td className='text-center text-[#E3B85D] py-2.5 w-1/4'>{item.agent_performance}</td>
                  </tr>
                ))}
                <tr className='bg-[#d5ab52] text-black mt-2 font-semibold'>
                  <td className='py-2.5 pl-2.5 w-1/4 rounded-tl-sm rounded-bl-sm'>{t("performanceQuery.Total")}</td>
                  <td className='text-center py-2.5 w-1/4'>{data.day_before_yesterday.reduce((total, item) => total + Number(item.total_performance), 0) + ".00"}</td>
                  <td className='text-center py-2.5 w-1/4'>{data.day_before_yesterday.reduce((total, item) => total + Number(item.total_performance), 0) + ".00"}</td>
                  <td className='text-center py-2.5 w-1/4 rounded-tr-sm rounded-br-sm'>{data.day_before_yesterday.reduce((total, item) => total + Number(item.total_performance), 0) + ".00"}</td>
                </tr>
              </tbody>
            )}
        </table>}
        {isLoading && <LoadingCustom center height={400} />}
      </div>
    </div>
  )
}

export default PerformanceQuery