import { vipApi } from "apis/vip";
import { VipLevel } from "apis/vip/type";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LevelProgressType } from "types/vip";
import { utilFindClosestItems } from "./utilCloseItem";
import Benefits from "./Tier";
import LoadingCustom from "components/LoadingCustom";

interface Props {
  className?: string;
  clickOpenModal?: boolean;
}

interface StateType {
  isLoadingLevel: boolean;
  isLoadingLevelProgress: boolean;
  dataVipLevel: VipLevel;
  dataLevelProgress: LevelProgressType[];
}
type KeyState = keyof StateType;

const CardAuthenVip = ({ className = "", clickOpenModal = true }: Props) => {
  const { t } = useTranslation("ranking");
  const username = localStorage.getItem("username") || "";

  const [state, setState] = useState<StateType>({
    isLoadingLevel: true,
    isLoadingLevelProgress: true,
    dataVipLevel: [],
    dataLevelProgress: [],
  });

  const handleChangeState = (key: KeyState, value: VipLevel | boolean) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const fetchLevelVip = async () => {
    try {
      const res = await vipApi.showVipLevel();

      if (res?.data) {
        setState((prev) => ({
          ...prev,
          dataVipLevel: res.data,
        }));
      }
    } catch (err) {
      throw new Error((err as Error).message || "error get Data Blog");
    } finally {
      handleChangeState("isLoadingLevel", false);
    }
  };
  const fetchLevelProgress = async () => {
    try {
      const res = await vipApi.getLevelProgress({ username });

      if (res?.data) {
        setState((prev) => ({
          ...prev,
          dataLevelProgress: res.data,
        }));
      }
    } catch (err) {
      throw new Error((err as Error).message || "error get Data Blog");
    } finally {
      handleChangeState("isLoadingLevelProgress", false);
    }
  };

  useEffect(() => {
    fetchLevelVip();
    fetchLevelProgress();
  }, []);

  const itemClose = utilFindClosestItems(
    state.dataVipLevel,
    Number(state.dataLevelProgress?.[0]?.total_bet_amount_valid || "0"),
    (level) => parseFloat(level.bet_amount)
  );

  const rateVip =
    (Number(state.dataLevelProgress?.[0]?.total_bet_amount_valid) * 100) /
    Number(itemClose?.[1]?.bet_amount);

  return (
    <div className={clsx(className, "flex flex-col items-center w-full h-full max-w-[390px] mx-auto overflow-auto")}>
      {state.isLoadingLevel && state.isLoadingLevelProgress ? (
        <LoadingCustom height={400} center />
      ) : (
        <div className="w-full">
          <div className="flex flex-col items-center w-full max-w-[384px] p-2 bg-linearGold rounded-lg aspect-[16/9] mb-8">
            <div className="flex flex-col justify-between w-full p-6 border-2 border-[#655746] bg-linearGold rounded-md">
              <div className="flex justify-between mb-6">
                <span className="text-black text-lg font-medium">{username}</span>
              </div>
              <div className="relative">
                <div className="flex justify-between items-center gap-5 mb-2">
                  <button
                    className="flex items-center gap-2 text-black text-sm font-medium transition-all hover:translate-x-2"
                    disabled={!clickOpenModal}
                    style={{
                      pointerEvents: clickOpenModal ? "all" : "none",
                    }}
                  >
                    {t("yourVIP")}
                  </button>
                </div>
                <div className="relative w-full h-2 rounded-lg bg-[#2f4553] overflow-hidden">
                  <div
                    className="h-full bg-[#20ca20] rounded-lg transition-all"
                    style={{ width: `${rateVip || 0}%` }}
                  ></div>
                </div>
                <div className="flex justify-between items-center mt-2 gap-5">
                  <div className="flex items-center gap-1">
                    <span className="text-black text-sm font-medium">{itemClose?.[0]?.level_name}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="text-black text-sm font-medium">{itemClose?.[1]?.level_name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Benefits />
          </div>
        </div>
      )}
    </div>
  );
};
export default CardAuthenVip;
