import "./index.css";

import { Modal } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  content?: string;
  onClose?: () => void;
  onSuccess?: () => void;
  isRenderOnly?: boolean;
  textCancel?: string;
  textConfirm?: string;
};
export function useModalConfirm() {
  const [modal, contextHolder] = Modal.useModal();
  const [t] = useTranslation("translation");
  const modalConfig = (props: Props) => {
    const modalCustom = modal.confirm({ open: false });
    modalCustom.update({
      open: true,
      footer: null,
      closable: false,
      width: "280px",
      style: { padding: 0 },
      centered: true,
      icon: null,
      onCancel: () => {
        props.onClose && props.onClose();
        modalCustom.destroy();
      },
      className: "modal-confirm-hook",
      content: (
        <div className="bg-slate-800">
          <div className="flex items-center justify-center relative flex-col px-6 py-4 gap-4">
            <span className="font-bold text-xl text-orange-300">
              {props.title}
            </span>
            {props.content && (
              <span className="text-sm text-orange-300">{props.content}</span>
            )}
          </div>
          <div className="flex w-full">
            {!props.isRenderOnly && (
              <button
                value="cancel"
                className="flex-1 text-center border-r h-10 bg-linearGold text-red-500 font-semibold"
                onClick={() => {
                  props.onClose && props.onClose();
                  modalCustom.destroy();
                }}
              >
                {props.textCancel || t("cancel")}
              </button>
            )}
            <button
              value="oke"
              className="flex-1 text-center h-10 bg-linearGold text-black font-semibold"
              onClick={() => {
                props.onSuccess && props.onSuccess();
                modalCustom.destroy();
              }}
            >
              {props.textConfirm || t("Confirm")}
            </button>
          </div>
        </div>
      ),
    });
  };
  return { contextHolder, modalConfig };
}
