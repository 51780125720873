import { PATH } from "constants/path";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import QrScanner from "./QRScanner";

const Register = () => {
  const { t } = useTranslation("register");
  const { referralCode } = useParams<{ referralCode: string }>();
  const [step, setStep] = useState(1);
  const [referrer, setReferrer] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (referralCode) {
      setReferrer(referralCode);
      setStep(2)
    }
  }, [referralCode]);

  return (
    <div className="h-full w-full bg-gradient-to-b from-[#252525] to-[#000000] pt-2">
      {step === 1 && (
        <div>
          <div className="flex justify-between px-1 items-center mb-2">
            <div onClick={() => navigate(PATH.login)}>
              <img src={"/images/back.svg"} className="w-2" />
            </div>
            <div className="text-lg text-[#EDCD90]">{t("newAccount")}</div>
            <div className="w-6 h-6 text-xs text-black rounded-full bg-linearGold p-1">
              EN
            </div>
          </div>
          <div className="bg-linearGold text-black text-center text-sm py-2">
            {t("pleaseEnterInfo")}
          </div>
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-10">
            <div className="flex-shrink-0 text-lg mr-4 font-light text-[#EDAE50]">
              {t("referrer")}
            </div>
            <input
              type={"text"}
              placeholder={t("enterUID")}
              value={referrer}
              onChange={(e) => setReferrer(e.target.value)}
              className="bg-transparent outline-none flex-grow placeholder-yellow-400 text-[15px] font-light text-[#B89032]"
            />
            <div id='12345'><QrScanner setReferrer={setReferrer} closeText={t("close")} /></div>
          </div>
          <div className="px-6">
            <div
              onClick={() => setStep(2)}
              className="text-center py-2 bg-linearGold rounded cursor-pointer text-black mb-4"
            >
              {t("next")}
            </div>
          </div>
          <div className="text-[#E3B85D] text-base text-center absolute bottom-12 w-full">
            MCLUBMDV
          </div>
        </div>
      )}
      {step === 2 && <RegisterForm referrer={referrer} />}
    </div>
  );
};

export default Register;
