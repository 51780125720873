export function getDateTimeRangeRanking(): { weekRange: string; monthlyRange: string } {
  const now: Date = new Date();

  // === Xử lý tuần ===
  const dayOfWeek: number = now.getDay(); // 0 = Chủ Nhật, 1 = Thứ Hai, ..., 6 = Thứ Bảy
  const diffToMonday: number = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Chuyển về Thứ Hai
  const startOfWeek: Date = new Date(now);
  startOfWeek.setDate(now.getDate() + diffToMonday);
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek: Date = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  endOfWeek.setHours(23, 59, 59, 999);
  return {
    // weekRange: `${formatDateTime(startOfWeek)} - ${formatDateTime(endOfWeek)}`,
    // monthlyRange: `${formatDateTime(startOfMonth)} - ${formatDateTime(endOfMonth)}`,
    weekRange: `2025/xx/xx 00:00 - 2025/xx/xx 23:59`,
    monthlyRange: `2025/xx/xx 00:00 - 2025/xx/xx 23:59`,
  };
}