import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

// export interface ReferralRewardProps {
//   list: List[];
// }

// export interface List {
//   promotion_name: string;
//   reward_amount: string;
//   genre_code: string;
//   remark: string;
//   status_remark: string;
//   is_reward: string;
//   reward_date: string;
//   updated_date: string;
// }

interface CollectionRecordType {
  date: string
  amount: string
  status: string
}

export interface ReferralRewardProps {
  current_bonus: string
  direct_member: string
  new_member_today: string
  new_team_member_today: string
  total_member: string
  collection_record: CollectionRecordType[]
}

export const useReferralReward = () => {
  const isAuth = useAppStore((state) => state.isAuthenticated);
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["ReferralReward"],
    queryFn: async () => {
      const res = await fetchAPI<ReferralRewardProps>(
        { username },
        "ReferralReward",
        username,
      );
      if (!res.status) {
        return res.data;
      }
    },
    enabled: isAuth,
  });
};
