/* eslint-disable @typescript-eslint/no-explicit-any */

export const createSearchParams = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      // Chuyển đổi value thành string
      searchParams.append(key, value.toString());
    }
  });

  return searchParams.toString();
};
