import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchAPI } from "..";
import { message } from "antd";

export interface MissionItemProps {
  "id": string,
  "bonus": string,
  "wager": string,
  "web": string,
  "mobile": string,
  "content": string,
  "button": string,
  "category": string,
  "claim": string,
}

export const useMissionList = () => {
  return useQuery({
    queryKey: ["MissionList"],
    queryFn: async () => {
      const username = localStorage.getItem("username");
      const res = await fetchAPI<MissionItemProps[]>(
        { username },
        "MissionList",
        username,
      );
      if (res.status === 0) {
        return res.data;
      }
      message.error(res.msg);
      return Promise.reject(res.msg);
    },
  });
};

export const useGetMission = (props: { onSuccess: () => void }) => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["GetMissionForm"],
    mutationFn: async (payload: { promotion_id: string }) => {
      const res = await fetchAPI(
        { ...payload, username },
        "MissionClaim",
        username,
      );
      if (!res.status) {
        return res;
      }
    },
    onSuccess: () => {
      props.onSuccess && props.onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};