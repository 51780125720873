import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useMemberDetail } from "apis/auth/member-detail";
import { useLoginGameInterface } from "./LoginGameInterface";

export const usePlayerStopGame = () => {
  const { refetch: detail } = useMemberDetail(); // lấy chips point
  const { refetch: gameInterface } = useLoginGameInterface();
  return useMutation({
    mutationKey: ["PlayerStopGame"],
    mutationFn: async () => {
      const res = await fetchAPI(
        { username: localStorage.getItem("username") },
        "PlayerStopGame",
        localStorage.getItem("username")
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: () => {
      localStorage.removeItem("data-on-game");
      detail();
      gameInterface();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
