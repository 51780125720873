import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface FriendDetailProps {
  player_id: string;
  username: string;
  full_name: string;
  mobile: string;
  email: string;
  avatar: string;
  is_friend_added: number;
  friend_status: string;
}

export const useFriendDetail = (friend_username: string) => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["FriendDetail", friend_username],
    queryFn: async () => {
      const res = await fetchAPI<FriendDetailProps>(
        { username: username, friend_username: friend_username },
        "FriendDetail",
        username,
      );
      return res.data;
    },
    enabled: isAuth && !!friend_username,
    retryDelay: 1000,
  });
};
