import BackgroungImage from "components/BackgroungImage";
import Banner from "./banner";
import GameContainer from "./GameContainer";
import HeaderHome from "./header";
import HomeFunction from "./home-function";
import Balance from "./balance";
import HotGame from "./hot-game";
import { isIOS } from "constants/device";
import clsx from "clsx";
import useIsSamsungNote from "helper/useIsSamsungNote";
import StuckPoint from "./StuckPoint";
import SearchBar from "./SearchBar";

function HomePage() {
  const isSamsungNote = useIsSamsungNote();

  return (
    <BackgroungImage src="/images/home/bg-page.png">
      <div
        className={clsx(
          "w-full h-auto px-2",
          isIOS ? "pb-[170px]" : isSamsungNote ? "pb-48" : "pb-32"
        )}
      >
        <HeaderHome />
        <Banner />
        <Balance />
        <HomeFunction />
        <StuckPoint />
        <SearchBar />
        <HotGame />
        <GameContainer />
      </div>
    </BackgroungImage>
  );
}

export default HomePage;
