import { Spin } from "antd";
import { useSubGameList } from "apis/games/get-sub-game";
import { SubGameItemProps } from "apis/games/type";
import HeaderTab from "components/HeaderTab";
import ItemGameProvider from "components/ItemGameProvider";
import { ProvidersGame } from "constants/providers-game";
import { useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";

function SubGamePage() {
  const { category, provider } = useParams();
  const [search, setSearch] = useState("");
  const keywordRef = useRef<HTMLInputElement>(null);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { data, isFetching, fetchNextPage } = useSubGameList({
    provider: provider + "",
    game_type: category + "",
    search: search,
  });

  useEffect(() => {
    if (!inView) return;
    fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const arrayGame = useMemo(() => {
    let array: SubGameItemProps[] = [];
    data?.pages.forEach((item) => {
      array = [...array, ...item.data];
    });
    return array;
  }, [data]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!keywordRef.current) return;
      setSearch(keywordRef.current?.value);
    }
  };

  const currentItemProvider = ProvidersGame.find(
    (i) => i.type === category && i.provider_code === provider,
  );
  return (
    <div className="w-full flex flex-col">
      <HeaderTab title={currentItemProvider?.name || "Games"} />
      <div className="w-full px-4">
        <div className="flex flex-1 flex-row items-center bg-slate-900 px-4 rounded-lg mt-2">
          <input
            ref={keywordRef}
            onKeyDown={handleKeyDown}
            placeholder="Search"
            className="w-full bg-inherit h-10 placeholder:text-orange-300 text-orange-300 outline-none"
          />
          <span
            onClick={() => {
              if (!keywordRef.current) return;
              setSearch(keywordRef.current?.value);
            }}
          >
            <svg
              className="fill-orange-300 w-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>
          </span>
        </div>
        <div className="grid gap-6 mt-4 grid-cols-3 xl:grid-cols-4">
          {arrayGame.map((item, index) => {
            return (
              <ItemGameProvider item={item} key={index}></ItemGameProvider>
            );
          })}
        </div>
        {!isFetching &&
        arrayGame.length > 0 &&
        data?.pages &&
        data?.pages[0].currentPage < data?.pages[0].totalPages ? (
          <div ref={ref} className="h-16"></div>
        ) : null}
        <div className="flex items-center justify-center">
          {isFetching ? <Spin /> : null}
        </div>
      </div>
    </div>
  );
}

export default SubGamePage;
