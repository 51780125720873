import { Spin } from "antd";
import { useLoginForm } from "apis/auth/login";
import BackgroungImage from "components/BackgroungImage";
import BottomSheet from "components/BottomSheet";
import { PATH } from "constants/path";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import faq from "./faq.svg";
import helpdesk from "./helpdesk.svg";
import lang from "./lang.svg";
import lock from "./lock.svg";
import logo from "./logo.svg";
import user from "./user.svg";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface LanguageOption {
  code: string;
  name: string;
  label: string;
}
type Error = { username?: string; password?: string };

const Login = () => {
  const { t } = useTranslation("login");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<Error>();
  const { mutate, isPending } = useLoginForm();
  const [langCode, setLangCode] = useState("EN");
  const [type, setType] = useState("password");

  const languages: LanguageOption[] = [
    { code: "EN", name: "English", label: "EN" },
    { code: "ZH", name: "中文", label: "中" },
    { code: "MY", name: "Malay", label: "MY" },
    { code: "TH", name: "ไทย", label: "TH" },
    { code: "ID", name: "Indonesia", label: "ID" },
    { code: "VN", name: "Tiếng Việt", label: "VN" },
  ];

  const validate = () => {
    const err: Error = {};
    if (username === "") {
      err.username = "Username is required";
    }
    if (password === "") {
      err.password = "Password is required";
    }
    return err;
  };

  const handleLogin = () => {
    if (isPending) return;
    const errors = validate();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    if (username && password) {
      mutate({ username, password });
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <BackgroungImage src="/images/home/bg-login.jpeg" className="min-h-[800px]">
      <div className="px-4 pt-[20%]">
        <div className="flex justify-center mb-8">
          <Link to="/" className="block">
            <img src={logo} alt="Logo" className="w-[168px]" />
          </Link>
        </div>

        <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#E3B85D] w-full mb-4">
          <div className="flex-shrink-0">
            <img src={user} alt="Logo" className="w-6" />
          </div>
          <span className="mx-2 text-yellow-400">|</span>
          <input
            type={"text"}
            placeholder={"Username"}
            className="bg-transparent outline-none flex-grow text-[#E3B85D] placeholder:text-[#eab54380]"
            value={username}
            onChange={(e) => {
              setErrors({ ...errors, username: "" });
              setUsername(e.target.value);
            }}
          />
          <div className="flex gap-1">
            <div className="text-xs">{langCode}</div>
            <img
              src={lang}
              alt="Logo"
              className="w-[12px]"
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
        {errors?.username && (
          <div className="text-red-500 text-xs -mt-[12px]">
            {errors.username}
          </div>
        )}

        <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
          <div className="flex-shrink-0">
            <img src={lock} alt="Logo" className="w-6" />
          </div>
          <span className="mx-2 text-yellow-400">|</span>
          <input
            type={type}
            placeholder="Password"
            className="bg-transparent outline-none flex-grow text-[#E3B85D] placeholder:text-[#eab54380]"
            value={password}
            onChange={(e) => {
              setErrors({ ...errors, password: "" });
              setPassword(e.target.value);
            }}
          />
          <span
            className="flex justify-around items-center"
            onClick={handleToggle}
          >
            {type === "password" ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </span>
        </div>
        {errors?.password && (
          <div className="text-red-500 text-xs -mt-[4px]">
            {errors.password}
          </div>
        )}

        <div
          className="text-right text-[#E3B85D] text-[13px] mb-6"
          onClick={() => navigate(PATH.forgotPassword)}
        >
          {t("forgotPassword")}
        </div>
        <div
          onClick={handleLogin}
          className="text-center py-3 bg-linearGold rounded cursor-pointer text-black mb-4 font-semibold"
        >
          {isPending ? <Spin size="small" /> : t("signin")}
        </div>
        <div
          onClick={() => navigate(PATH.register)}
          className="text-center py-3 border border-solid border-[#EEB154] rounded cursor-pointer text-[#E3B85D] mb-6"
        >
          {t("register")}
          {`>`}
        </div>
        <div className="flex justify-center mb-4">
          <div className="flex gap-1 py-1 px-2 border border-solid border-[#FFE794] bg-[#755D32] rounded-full">
            <img src={helpdesk} alt="Logo" className="w-[20px]" />
            <div
              className="text-[10px] text-[#FFE794]"
              onClick={() => navigate(PATH.helpDesk)}
            >
              {t("helpdesk")} 7x24h
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex gap-1 py-1 px-3 bg-[#755D32] rounded">
            <img src={faq} alt="Logo" className="w-[20px]" />
            <div className="text-[10px] text-[#FFE794] leading-5">FAQ</div>
          </div>
        </div>
        <div className="text-[#E3B85D] text-base text-center absolute bottom-16 -ml-4 w-full">
          MCLUBMDV
        </div>
        <BottomSheet isOpen={open} onClose={() => setOpen(false)}>
          <div className="text-center py-3 bg-gradient-to-r from-[#FFE794] to-[#EEB154] text-black">
            {t("language")}
          </div>
          {languages.map((lang, index) => {
            return (
              <div
                key={index}
                className="relative py-3 border-b border-solid border-[#EEB154] text-[#FFE794] text-center bg-black"
                onClick={() => {
                  setOpen(false);
                  setLangCode(lang.code);
                }}
              >
                <div className="w-6 h-6 absolute left-[20%] text-xs text-black rounded-full bg-gradient-to-r from-[#FFE794] to-[#EEB154] p-1">
                  {lang.code}
                </div>
                {lang.name}
              </div>
            );
          })}
        </BottomSheet>
      </div>
    </BackgroungImage>
  );
};

export default Login;
