import {
  useWithdrawForm,
  useWithdrawFormSetting,
  WithdrawFormProps,
} from "apis/withdrawal";
import HeaderTab from "components/HeaderTab";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "constants/path";
import clsx from "clsx";
import LoadingCustom from "components/LoadingCustom";
import ModalNotification, {
  ModalNotificationProps,
} from "components/ModalNotification";
import { PlayerBankType } from "types/bank";
import { useRequestOTP } from "apis/auth/forgot-password";
import { useMemberDetail } from "apis/auth/member-detail";

function WithdrawalPage() {
  const [openNotification, setOpenNotification] =
    useState<ModalNotificationProps>({
      open: false,
      msg: "",
      desc: "",
    });

  const navigate = useNavigate();

  const { data: dataForm, isLoading } = useWithdrawFormSetting();
  const playerBanks = dataForm && dataForm.player_bank;
  const [dataBankPlayer, setDataBankPlayer] = useState<PlayerBankType>();
  const [otpTime, setOTPTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (otpTime) {
      interval.current = setInterval(() => {
        setOTPTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [otpTime]);
  const { t } = useTranslation("withdrawal");

  const listSuggestedAmount = [500, 1000, 2000, 5000];
  listSuggestedAmount.unshift(Number(dataForm?.setting.min_withdrawal) || 100);
  listSuggestedAmount.push(Number(dataForm?.setting.max_withdrawal));

  const schema = yup
    .object({
      currency_id: yup.string().trim().required(t("CurrencyEmpty")),
      player_bank_id: yup.string().trim().required(t("playerBankEmpty")),
      amount: yup
        .number()
        .max(
          Number(dataForm?.setting.max_withdrawal),
          t("Max", { max: dataForm?.setting.max_withdrawal }),
        )
        .min(
          Number(dataForm?.setting.min_withdrawal),
          t("Min", { min: dataForm?.setting.min_withdrawal }),
        )
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required(t("amountEmpty")),
      otp: yup.string().required(t("otpEmpty")),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      currency_id: dataForm?.currencies[0].currency_id,
      player_bank_id: dataBankPlayer?.player_bank_id,
      otp: "",
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync, isPending } = useWithdrawForm({
    onSuccess: () => {
      reset();
      setTimeout(() => {
        if (!dataForm) return;
        setValue("currency_id", dataForm.currencies[0].currency_id);
      }, 100);
    },
  });

  useEffect(() => {
    if (playerBanks && !playerBanks?.length) {
      navigate(PATH.addBank);
    }
  }, [playerBanks]);

  useEffect(() => {
    if (!dataForm) return;
    setValue("currency_id", dataForm.currencies[0].currency_id);
    setDataBankPlayer(dataForm.player_bank[0]);
    setValue("player_bank_id", dataForm.player_bank[0]?.player_bank_id);
  }, [dataForm]);

  const onSubmit: SubmitHandler<WithdrawFormProps> = async (formData) => {
    try {
      const res = await mutateAsync({ ...formData, mobile: profile?.mobile });
      if (res.status === 0) {
        setOpenNotification({
          open: true,
          msg: res.msg,
          desc: `Code: ${res.data}`,
        });
        setDataBankPlayer(dataForm?.player_bank[0]);
      } else {
        setOpenNotification({ open: true, msg: res.msg });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { data: profile } = useMemberDetail();
  const { mutate: requestOTPAPI } = useRequestOTP();

  const sendOTP = async () => {
    if (!profile) return;
    const { mobile, username } = profile;
    setOTPTime(60);
    requestOTPAPI({
      username: username,
      mobile: `${mobile}`,
      type: 7,
    });
  };

  return (
    <div className="w-full h-full flex flex-col bg-linearBlack overflow-hidden">
      <HeaderTab title={t("Withdrawal")} />
      {isLoading ? (
        <LoadingCustom center height={400} />
      ) : (
        <div className="w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide pb-10">
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <label
                className='relative text-[#edcd90] font-medium mb-2 
              before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
              before:top-[50%] before:-translate-y-[50%] px-2.5'
              >
                <span className="pl-3">{t("Amount")}</span>
              </label>
              <input
                className={clsx(
                  "h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.5 px-5 text-[#E3B85D]",
                  "leading-[1.2] placeholder:text-[#E3B85D80]",
                  errors.amount && "border-red-500 !bg-[#ff000017]",
                )}
                type="number"
                autoComplete="off"
                placeholder={`Min amount ${dataForm?.setting.min_withdrawal} - Max amount ${dataForm?.setting.max_withdrawal}`}
                {...register("amount")}
              />
              {errors.amount && (
                <span className="text-[14px] text-red-500 px-2.5 pt-1">
                  {errors.amount.message}
                </span>
              )}
              <div className="mt-2 mb-4 grid grid-cols-3 gap-2 px-2.5">
                {listSuggestedAmount.map((item: number) => {
                  return (
                    <div
                      onClick={() => {
                        setValue("amount", item);
                        clearErrors("amount");
                      }}
                      key={item}
                      className={clsx(
                        "flex items-center justify-center bg-[#6e5d3a80] p-1 rounded-md",
                        watch("amount") === item &&
                        "border border-[#E3B85D] bg-[#836d40]",
                      )}
                    >
                      <span>{item}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label
                className='relative text-[#edcd90] font-medium mb-2 
              before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
              before:top-[50%] before:-translate-y-[50%] px-2.5'
              >
                <span className="pl-3">{t("playerBank")}</span>
              </label>
              <div className="px-2.5 grid gap-2 grid-cols-[repeat(auto-fill,calc(33%_-_8px))]">
                {playerBanks?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setDataBankPlayer(item);
                      setValue("player_bank_id", item.player_bank_id);
                    }}
                    className={clsx(
                      "shadow-sm text-center overflow-hidden flex items-center justify-center flex-col gap-2 font-semibold",
                      "text-[14px] relative px-2 py-4 border border-[#4e442e] rounded-md bg-[#E3B85D80] text-black leading-[1.2]",
                      dataBankPlayer?.player_bank_id === item.player_bank_id &&
                      "text-black !border-[#E3B85D] bg-linearGold",
                    )}
                  >
                    <span>{item.bank_name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label
                className='relative text-[#edcd90] font-medium mb-2 
              before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
              before:top-[50%] before:-translate-y-[50%] px-2.5'
              >
                <span className="pl-3">{t("AccountHolderName")}</span>
              </label>
              <input
                placeholder={t("AccountHolderName")}
                className="h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.6 px-5 text-[#E3B85D] 
            leading-[1.2] placeholder:text-[#B89032] disabled:text-[#E3B85D80]"
                disabled
                value={dataBankPlayer?.bank_account_name}
              />
            </div>
            <div className="flex flex-col mt-4">
              <label
                className='relative text-[#edcd90] font-medium mb-2 
              before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
              before:top-[50%] before:-translate-y-[50%] px-2.5'
              >
                <span className="pl-3">{t("AccountNumber")}</span>
              </label>
              <input
                placeholder={t("AccountNumber")}
                className="h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.6 px-5 text-[#E3B85D] 
            leading-[1.2] placeholder:text-[#B89032] disabled:text-[#E3B85D80]"
                disabled
                value={dataBankPlayer?.bank_account_no}
              />
            </div>
            <div className="flex flex-col mt-8">
              <label className="label-form ml-2">
                <span>{t("otp")}</span>
              </label>
              <div className="flex items-center py-2 bg-black text-yellow-400 w-full mb-2 relative">
                <input
                  className={clsx(
                    "w-full h-[40px] text-[14px] outline-none border-b border-[#E3B85D] bg-transparent py-2.5 px-5 text-[#E3B85D]",
                    "leading-[1.2] placeholder:text-[#E3B85D80]",
                    errors.otp && "border-red-500 !bg-[#ff000017]",
                  )}
                  type="text"
                  placeholder={t("otp")}
                  {...register("otp")}
                />
                <button
                  className={clsx(
                    "flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer absolute right-2 z-10",
                    (!watch("amount") || errors.amount) &&
                    `pointer-events-none bg-gradientDisabled  w-28 text-center ${otpTime ? 'opacity-70 pointer-events-none' : ''}`,
                  )}
                  onClick={sendOTP}
                  disabled={!!errors.amount}
                  type="button"
                >
                  {otpTime ? otpTime : t("requestOTP")}
                </button>
              </div>
              {errors?.otp && (
                <div className="text-sm text-red-600 -mt-1 mb-2 ml-2">
                  {errors?.otp?.message}
                </div>
              )}
            </div>
            <div className="flex mt-6 items-center justify-center">
              <button
                type="submit"
                className="max-w-[370px] w-full h-[46px] mx-auto flex items-center justify-center bg-linearGold rounded-md text-black font-semibold
              disabled:bg-gradientDisabled disabled:cursor-no-drop disabled:text-[#F5D598]"
                disabled={isPending}
              >
                {t("Submit")}
              </button>
            </div>
          </form>
        </div>
      )}
      <ModalNotification
        open={openNotification.open}
        onClose={() =>
          setOpenNotification((prev) => ({ ...prev, open: false }))
        }
        mesage={openNotification.msg}
        description={openNotification.desc}
      />
    </div>
  );
}

export default WithdrawalPage;
