import React from 'react';
import ImgMaintainance from "assets/images/maintainance.png";

const MaintenancePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center">
      <div className="bg-white shadow-lg rounded-lg p-8 pt-0">
        <div className='flex justify-center'><img src={ImgMaintainance} alt="" width={250} /></div>
        <h1 className="text-2xl font-bold mb-2">Website under maintenance</h1>
        <p className="text-gray-700 mb-4">
          You have an outstanding balance. If you are the person in charge, please contact us for more details.
        </p>
        <h1 className="text-2xl font-bold mb-2">网站正在维护</h1>
        <p className="text-gray-700">
          您有未结余额。如果您是负责人，请联系有关人员获取更多信息。
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
