import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { PATH } from "constants/path";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "stores/app";
import { SubGameItemProps } from "./type";

export interface SubGameListParams {
  search: string;
  game_type: string;
  provider: string;
}

export const useFavourireGames = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["GetFavouriteList", username],
    enabled: isAuth,
    queryFn: async () => {
      const res = await fetchAPI<SubGameItemProps[]>(
        { page: 1, size: 1000, username: username },
        "GetFavouriteList",
        null
      );
      return res.data;
    },
  });
};

export interface UpdateFavouriteProps {
  item: SubGameItemProps;
  favourite: 1 | 0;
}
export const useUpdateFavourite = () => {
  const username = localStorage.getItem("username");
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useMutation({
    mutationKey: ["UpdateFavourite"],
    mutationFn: async (payload: UpdateFavouriteProps) => {
      if (!isAuth) {
        return navigate(PATH.login);
      }
      const res = await fetchAPI(
        {
          username,
          favourite: payload.favourite,
          sub_game_id: payload.item.sub_game_id,
        },
        "UpdateFavourite",
        null
      );
      if (res.status === 0) {
        if (payload.favourite === 1) {
          queryClient.setQueryData(
            ["GetFavouriteList", username],
            (oldData: SubGameItemProps[]) => {
              if (!oldData) return oldData;

              return [...oldData, payload.item];
            }
          );
        } else {
          queryClient.setQueryData(
            ["GetFavouriteList", username],
            (oldData: SubGameItemProps[]) => {
              if (!oldData) return oldData;

              return oldData.filter(
                (i) => i.game_code !== payload.item.game_code
              );
            }
          );
        }
      }
      return res.data;
    },
  });
};
