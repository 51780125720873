import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchAPI, fetchAPIGetMessage } from "..";
import { message } from "antd";
import { ReceiveWithdrawType, WithdrawalSettingProps } from "./type";
import { useAppStore } from "stores/app";
import { ApiMessageWithdrawralResponseType, ApiResponseGlobal } from "types/api";

export const useWithdrawFormSetting = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["WithdrawForm"],
    queryFn: async () => {
      const res = await fetchAPI<WithdrawalSettingProps>(
        { username },
        "WithdrawForm",
        username,
      );
      return res.data;
    },
    enabled: isAuth,
  });
};

export interface WithdrawFormProps {
  currency_id: string;
  player_bank_id: string;
  amount: number;
  otp: string;
  mobile?: string;
}
export interface PoprsForm {
  onSuccess: () => void;
}
export const useWithdrawForm = (props: PoprsForm) => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["Withdrawal"],
    mutationFn: async (payload: WithdrawFormProps) => {
      const res = await fetchAPI(
        { ...payload, username },
        "Withdrawal",
        username,
      );
      if (!res.status) {
        return res;
      }
      return Promise.reject(res.msg);
    },
    onSuccess: () => {
      props.onSuccess && props.onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};

export const useGetMessageWithdrawal = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["checkPopupOnOffWRL", username],
    queryFn: async () => {
      const res = await fetchAPIGetMessage<ApiMessageWithdrawralResponseType>(
        { username },
        "checkPopupOnOffWRL",
        username,
      );
      return res;
    },
    staleTime: 0,
    refetchInterval: 1000 * 30,
    enabled: isAuth
  });
};

export const useGetReceiveDataWRL = () => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["receiveDataWRL"],
    mutationFn: async (payload: ReceiveWithdrawType) => {
      const res = await fetchAPIGetMessage<ApiResponseGlobal>(
        {
          ...payload,
          username
        },
        "receiveDataWRL",
        username);
      return res;
    }
  })
};
