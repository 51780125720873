import { Empty } from "antd";
import { useAnnouncement } from "apis/announcement";
import { useTranslation } from "react-i18next";

function InfoView() {
  const { t } = useTranslation("translation");
  const { data } = useAnnouncement();
  if (!data?.length) {
    return (
      <div className="w-full flex items-center justify-center">
        <Empty description={t("noRecords")} className="!text-white" />
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="flex flex-col bg-slate-100 p-2 rounded-md"
          >
            <h3 className="text-lg font-semibold">{item.title}</h3>
            <p className="text-sm">{item.content}</p>
          </div>
        );
      })}
    </div>
  );
}

export default InfoView;
