import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { message, Spin } from "antd";
import {
  ChangePasswordFormProps,
  useChangePassword,
} from "apis/auth/change-password";
import { useRequestOTP } from "apis/auth/forgot-password";
import BottomSheet from "components/BottomSheet";
import HeaderTab from "components/HeaderTab";
import { MOBILE_PREFIX } from "constants/mobile";
import { useEffect, useRef, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const defaultValuesForm: ChangePasswordFormProps = {
  oldpass: "",
  password: "",
  passconf: "",
  mobile: "",
  otp: "",
};
function ChangePassword() {
  const { t } = useTranslation("change-password");
  const [selectedPrefix, setSelectedPrefix] = useState(MOBILE_PREFIX[0]);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    oldpass: false,
    newpass: false,
    conpass: false,
  });
  const [otpTime, setOTPTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (otpTime) {
      interval.current = setInterval(() => {
        setOTPTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [otpTime]);
  const schema = yup
    .object({
      oldpass: yup.string().trim().required(t("oldPassEmpty")),
      password: yup.string().trim().required(t("newPassEmpty")),
      passconf: yup.string().trim().required(t("passconfEmpty")),
      mobile: yup
        .string()
        .trim()
        .lowercase()
        .required(t("mobileEmpty"))
        .matches(selectedPrefix.regex, t("mobileMatches")),
      otp: yup.string().trim().lowercase().required(t("otpEmpty")),
    })
    .required();

  const { register, handleSubmit, reset, watch, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValuesForm,
  });
  const { mutate, isPending } = useChangePassword({
    onSuccess: () => {
      reset();
    },
  });
  const { mutate: requestOTPAPI } = useRequestOTP();
  const mobile = watch("mobile");

  const onSubmit: SubmitHandler<ChangePasswordFormProps> = (data) => {
    if (data.password !== data.passconf) {
      return message.error(t("passAndNewPassNot"));
    }
    mutate({
      ...data,
      mobile: `${selectedPrefix.code.replace("+", "")}${mobile}`,
    });
  };

  const onError: SubmitErrorHandler<ChangePasswordFormProps> = (errors) => {
    if (errors.oldpass?.message) {
      return message.error(errors.oldpass?.message);
    }
    if (errors.password?.message) {
      return message.error(errors.password?.message);
    }
    if (errors.passconf?.message) {
      return message.error(errors.passconf?.message);
    }
    if (errors.mobile?.message) {
      return message.error(errors.mobile?.message);
    }
    if (errors.otp?.message) {
      return message.error(errors.otp?.message);
    }
    if (errors.root?.message) {
      return message.error(errors.root?.message);
    }
  };
  const sendOTP = async () => {
    if (!mobile) {
      setError("mobile", {
        type: "validate",
        message: t("mobileEmpty"), // Thông báo lỗi
      });
      return;
    }
    setOTPTime(60);
    requestOTPAPI({
      username: localStorage.getItem("username") || "",
      mobile: `${selectedPrefix.code.replace("+", "")}${mobile}`,
      type: 2,
    });
  };

  // toggle show pass 
  const togglePasswordVisibility = (
    field: "newpass" | "oldpass" | "conpass"
  ) => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div className="flex flex-col w-full">
      <HeaderTab title={t("changePassword")} />
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col p-4"
      >
        <div>
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
            <div className="flex-shrink-0">
              <img src={"/images/password.png"} className="w-4" />
            </div>
            <span className="mx-2 text-[#e3b85d]">|</span>
            <input
              placeholder={t("oldPassword")}
              type={showPassword.oldpass ? "text" : "password"}
              {...register("oldpass")}
              className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
            />
            <span
              className="flex justify-around items-center"
              onClick={() => togglePasswordVisibility("oldpass")}
            >
              {showPassword.oldpass ? (
                <EyeInvisibleOutlined />
              ) : (
                <EyeOutlined />
              )}
            </span>
          </div>
        </div>

        <div className="mt-2">
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
            <div className="flex-shrink-0">
              <img src={"/images/password.png"} className="w-4" />
            </div>
            <span className="mx-2 text-[#e3b85d]">|</span>
            <input
              placeholder={t("newPassword")}
              type={showPassword.newpass ? "text" : "password"}
              {...register("password")}
              className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
            />
            <span
              className="flex justify-around items-center"
              onClick={() => togglePasswordVisibility("newpass")}
            >
              {showPassword.newpass ? (
                <EyeInvisibleOutlined />
              ) : (
                <EyeOutlined />
              )}
            </span>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
            <div className="flex-shrink-0">
              <img src={"/images/password.png"} className="w-4" />
            </div>
            <span className="mx-2 text-[#e3b85d]">|</span>
            <input
              placeholder={t("passconf")}
              type={showPassword.conpass ? "text" : "password"}
              {...register("passconf")}
              className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
            />
            <span
              className="flex justify-around items-center"
              onClick={() => togglePasswordVisibility("conpass")}
            >
              {showPassword.conpass ? (
                <EyeInvisibleOutlined />
              ) : (
                <EyeOutlined />
              )}
            </span>
          </div>
        </div>

        <div>
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
            <div className="flex-shrink-0">
              <div className="flex" onClick={() => setOpen(true)}>
                <div>{selectedPrefix.code}</div>
              </div>
            </div>
            <span className="mx-2 text-yellow-400">|</span>
            <input
              placeholder={t("mobile")}
              type={"text"}
              {...register("mobile")}
              className="bg-transparent outline-none flex-grow placeholder-yellow-400"
            />
          </div>
        </div>

        <div>
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
            <input
              {...register("otp")}
              placeholder={t("otp")}
              type={"text"}
              className="bg-transparent outline-none flex-grow placeholder-yellow-400"
            />
            <div
              className={`w-32 text-center flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer ${
                otpTime ? "opacity-70 pointer-events-none" : ""
              }`}
              onClick={sendOTP}
            >
              {otpTime ? otpTime : t("requestOTP")}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-4">
          <button
            type="submit"
            className="bg-linearGold px-4 py-2 rounded-xl text-black"
          >
            {isPending ? <Spin size="small" /> : t("Update")}
          </button>
        </div>
      </form>
      <BottomSheet isOpen={open} onClose={() => setOpen(false)}>
        <div className="min-h-40">
          <div className="text-center py-3 bg-gradient-to-r from-[#FFE794] to-[#EEB154] text-orange-300 font-semibold">
            {t("Country")}
          </div>
          {MOBILE_PREFIX.map((prefix, index) => {
            return (
              <div
                key={index}
                className="relative py-3 border-b border-solid border-[#EEB154] text-[#FFE794] text-center bg-black"
                onClick={() => {
                  setOpen(false);
                  setSelectedPrefix(prefix);
                }}
              >
                {prefix.code}
              </div>
            );
          })}
        </div>
      </BottomSheet>
    </div>
  );
}

export default ChangePassword;
