import React, { useEffect, useRef, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
import IconScan from "assets/icons/icon-scan.svg";
import { ReactSVG } from "react-svg";

const QrScanner = ({ setReferrer, closeText }: { setReferrer: (v: string) => void, closeText: string }) => {
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState<string | null>(null);
  const scannerRef = useRef<Html5Qrcode | null>(null);
  const qrCodeRegionId = "qr-reader";
  const prefixURL = 'https://mclubbet.com/ref/';
  useEffect(() => {
    if (isScanning) {
      if (!scannerRef.current) {
        scannerRef.current = new Html5Qrcode(qrCodeRegionId);
      }

      scannerRef.current
        .start(
          { facingMode: "environment" },
          {
            fps: 10,
            qrbox: { width: window.innerWidth * 0.8, height: window.innerHeight * 0.8 },
          },
          (decodedText) => {
            // setScanResult(decodedText);
            setReferrer(decodedText.replace(prefixURL, ''));
            stopScanner(); // Đóng camera đúng cách
            setIsScanning(false);
          },
          (error) => {
            console.warn("Lỗi quét QR:", error);
          }
        )
        .catch((err) => {
          console.error("Không thể khởi động scanner:", err);
          setIsScanning(false);
        });
    }

    return () => {
      stopScanner();
    };
  }, [isScanning]);

  const stopScanner = () => {
    if (scannerRef.current?.getState() === 2) {
      // Chỉ dừng scanner nếu nó đang chạy
      scannerRef.current
        .stop()
        .then(() => {
          console.log("Scanner đã dừng");
          scannerRef.current = null;
        })
        .catch((err) => console.warn("Lỗi khi dừng scanner:", err));
    }
  };

  return (
    <div>
      {scanResult ? (
        <div className="text-center">
          <p className="text-lg font-semibold">Kết quả: {scanResult}</p>
          <button
            className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg"
            onClick={() => {
              setScanResult(null);
              setIsScanning(true); // Quét lại
            }}
          >
            Quét lại
          </button>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <ReactSVG
            src={IconScan}
            className="cursor-pointer"
            onClick={() => setIsScanning(true)}
          />
        </div>
      )}

      {/* Camera mở toàn màn hình khi quét */}
      {isScanning && (
        <div className="fixed top-0 left-0 w-full h-full bg-black z-50 flex justify-center items-center">
          <div id={qrCodeRegionId} className="w-full h-full"></div>
          <button
            className="absolute top-4 right-4 px-2 py-1 bg-linearGold rounded cursor-pointer text-black"
            onClick={() => setIsScanning(false)}
          >
            {closeText}
          </button>
        </div>
      )}
    </div>
  );
};

export default QrScanner;
