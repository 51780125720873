export const MOBILE_PREFIX = [
  {
    code: "+960",
    regex: /^\+?\d{7,14}$/,
  },
  // {
  //   code: "+60",
  //   regex: MOBILE_REGEX,
  // },
  // {
  //   code: "+84",
  //   regex: MOBILE_REGEX,
  // },
  // {
  //   code: "+65",
  //   regex: MOBILE_REGEX,
  // },
];
