import { QRCode } from "antd";
import HeaderTab from "components/HeaderTab";
const AndroidURL = "/app/mclub2u.apk";
const IosURL = "/app/mclub2u.mobileconfig";
function DownloadApp() {
  return (
    <div className="flex flex-col">
      <HeaderTab title="Download App" />
      <div className="flex flex-col mt-2">
        <div className=" relative bg-slate-900 p-4">
          <h3 className="font-bold text-xl text-orange-300">Download App</h3>
          <img
            src="/images/common/coin.png"
            className="h-12 absolute bottom-0 right-0"
          />
        </div>
        <div className="flex flex-col gap-4 mt-6 mx-4">
          <div className="flex flex-col items-center justify-center py-4 bg-slate-900 rounded-xl">
            <h3 className="font-semibold text-lg">Android</h3>
            <QRCode value={AndroidURL} color="white" />
            <span className="mt-2">Or</span>
            <a
              href={AndroidURL}
              download={true}
              className="bg-linearGold px-4 py-2 mt-2 rounded-xl text-black font-semibold"
            >
              Download Android
            </a>
          </div>
          <div className="flex flex-col items-center justify-center py-4 bg-slate-900 rounded-xl">
            <h3 className="font-semibold text-lg">IOS</h3>
            <QRCode value={IosURL} color="white" />
            <span className="mt-2">Or</span>
            <a
              href={IosURL}
              download={true}
              className="bg-linearGold px-4 py-2 mt-2 rounded-xl text-black font-semibold"
            >
              Download IOS
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
