import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, message, Spin } from "antd";
import "antd/dist/reset.css";
import { ProfileFormProps, useProfileForm } from "apis/auth/edit-profile";
import { useRequestOTP } from "apis/auth/forgot-password";
import { useMemberDetail } from "apis/auth/member-detail";
import HeaderTab from "components/HeaderTab";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
const defaultValuesForm: ProfileFormProps = {
  fullname: "",
  nickname: "",
  email: "",
  mobile: "",
  birth: "",
  otp: ""
};
function ProfilePage() {
  const { t } = useTranslation("edit-profile");
  const { data, refetch } = useMemberDetail();
  const [otpTime, setOTPTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (otpTime) {
      interval.current = setInterval(() => {
        setOTPTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [otpTime]);
  const schema = yup
    .object({
      fullname: yup.string(),
      nickname: yup.string(),
      email: yup.string(),
      mobile: yup.string(),
      birth: yup.string(),
      otp: yup.string().required(t("usernameEmpty")),
    })
    .required();

  const { register, handleSubmit, setValue, watch, setError, formState: { errors }, } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValuesForm,
  });

  const { mutate, isPending } = useProfileForm({
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (!data) return;
    setValue("birth", data.birth || "");
    setValue("email", data.email || "");
    setValue("fullname", data.full_name || "");
    setValue("mobile", data.mobile || "");
    setValue("nickname", data.nickname || "");
  }, [data, setValue]);

  const onSubmit: SubmitHandler<ProfileFormProps> = async (data) => {
    mutate(data);
  };

  const onError: SubmitErrorHandler<ProfileFormProps> = (errors) => {
    if (errors.root?.message) {
      return message.error(errors.root?.message);
    }
  };
  const { mutate: requestOTPAPI } = useRequestOTP();

  const sendOTP = async () => {
    if (!data) return;
    const { mobile, username } = data;
    if (!mobile || !username) {
      if (!mobile)
        setError("mobile", {
          type: "validate",
          message: t("mobileEmpty"), // Thông báo lỗi
        });
      return;
    }
    setOTPTime(60);
    requestOTPAPI({
      username: username,
      mobile: `${mobile}`,
      type: 2,
    });
  };
  return (
    <div className="flex flex-col">
      <HeaderTab title={t("Profile")} />
      <form className="p-4" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="flex flex-col">
          <label className="label-form">
            <span>{t("fullname")}</span>
          </label>
          <input
            placeholder={t("fullname")}
            className="h-10 bg-black mt-0 px-2 text-orange-300 outline-none border-b hover:bg-black border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
            {...register("fullname")}
            readOnly={!!data?.full_name}
          />
        </div>
        <div className="flex flex-col mt-8">
          <label className="label-form">
            <span>{t("nickname")}</span>
          </label>
          <input
            placeholder={t("nickname")}
            className="h-10 bg-black mt-0 px-2 text-orange-300 outline-none border-b border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
            {...register("nickname")}
          />
        </div>
        <div className="flex flex-col mt-8">
          <label className="label-form">
            <span>{t("mobile")}</span>
          </label>
          <input
            placeholder={t("mobile")}
            className="h-10 bg-black mt-0 px-2 text-orange-300 outline-none border-b border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
            {...register("mobile")}
          />
        </div>
        <div className="flex flex-col mt-8">
          <label className="label-form">
            <span>{t("email")}</span>
          </label>
          <input
            placeholder={t("email")}
            className="h-10 bg-black mt-0 px-2 text-orange-300 outline-none border-b border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
            {...register("email")}
          />
        </div>
        <div className="flex flex-col mt-8">
          <label className="label-form">
            <span>{t("birth")}</span>
          </label>
          <DatePicker
            value={watch("birth") ? dayjs(watch("birth"), "YYYY-MM-DD") : null} // Gắn giá trị ngày
            onChange={(e) => {
              setValue("birth", e?.format("YYYY-MM-DD"));
            }}
            format="YYYY-MM-DD"
            className="h-10 bg-black mt-0 px-2 text-orange-300 outline-none border-t-0 border-l-0 border-r-0 rounded-none border-b border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
          />
        </div>
        <div className="flex flex-col mt-8">
          <label className="label-form">
            <span>{t("otp")}</span>
          </label>
          <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
            <input
              {...register("otp")}
              placeholder={t("otp")}
              type={"text"}
              className="bg-transparent outline-none flex-grow placeholder-yellow-400"
            />
            <div
              className={`flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer w-32 text-center ${otpTime ? 'opacity-70 pointer-events-none' : ''}`}
              onClick={sendOTP}
            >
              {otpTime ? otpTime : t("requestOTP")}
            </div>
          </div>
          {errors?.otp && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {t("otpEmpty")}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          <button
            type="submit"
            className="bg-linearGold px-4 py-2 rounded-xl text-black"
          >
            {isPending ? <Spin size="small" /> : t("Update")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProfilePage;
