export interface LanguageItemProps {
  name: string;
  code: string;
  picture: string;
  id: number;
}

export const listLanguage: LanguageItemProps[] = [
  {
    name: "EN",
    code: "en",
    picture: "/images/language/flag-uk.webp",
    id: 1,
  },
];
