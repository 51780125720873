import { Empty } from "antd";
import { useFriendList } from "apis/friends/list-friend";
import { useFriendUpdate } from "apis/friends/update-friend";
import { PATH } from "constants/path";
import { useNavigate } from "react-router-dom";

function MyFriendRequest() {
  const { data: dataFriend } = useFriendList();
  const navigate = useNavigate();
  const { mutate } = useFriendUpdate();
  return (
    <div className="flex flex-col">
      {!dataFriend?.list.received.length ? (
        <div className="flex items-center justify-center mt-4">
          <Empty />
        </div>
      ) : (
        <div className="flex flex-col px-4 py-2">
          {dataFriend?.list.received.map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-center py-4 border-b border-gray-800"
              >
                <div
                  onClick={() => {
                    navigate(PATH.contact + "/detail/" + item.friend_username);
                  }}
                  className="flex items-center flex-1"
                >
                  <img
                    src={item.avatar}
                    className="w-10 h-10 rounded-full bg-slate-600"
                  />
                  <span className="text-sm ml-4 flex-1">
                    {item.friend_username}
                  </span>
                </div>
                <span
                  onClick={() => {
                    mutate({
                      username: localStorage.getItem("username") + "",
                      update_type: "accept",
                      friend_username: item.friend_username,
                      remark: `${localStorage.getItem(
                        "username",
                      )} accept your friend request`,
                    });
                  }}
                  className="text-sm text-green-500"
                >
                  Accept
                </span>
                <span
                  onClick={() => {
                    mutate({
                      username: localStorage.getItem("username") + "",
                      update_type: "reject",
                      friend_username: item.friend_username,
                      remark: `${localStorage.getItem(
                        "username",
                      )} refuse to be friends`,
                    });
                  }}
                  className="text-sm text-red-500 ml-4"
                >
                  Reject
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MyFriendRequest;
