import { Spin } from "antd";
import { useFriendSearch } from "apis/friends/search-user";
import { useFriendUpdate } from "apis/friends/update-friend";
import HeaderTab from "components/HeaderTab";
import { PATH } from "constants/path";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import FriendRequest from "./friend-request";
import MyFriendRequest from "./my-friend-request";
import { useFriendList } from "apis/friends/list-friend";

const listTabs = [
  {
    code: "friendRequest",
    id: "1",
  },
  {
    code: "myRequest",
    id: "2",
  },
];

function NewFriends() {
  const navigate = useNavigate();
  const { t } = useTranslation("contact");
  const [params, setParams] = useSearchParams();
  const code = params.get("code") || listTabs[0].id;
  const keyword = params.get("keyword") || "";
  const [searchText, setSearchText] = useState(keyword);
  const { data } = useFriendSearch(keyword);
  const timeRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { mutate, isPending } = useFriendUpdate();
  const { data: dataFriend } = useFriendList();
  return (
    <div>
      <HeaderTab title={t("newFriends")} />
      <div className="px-4">
        <div className="flex px-2 items-center bg-slate-900 mt-4 rounded">
          <svg
            className="w-4 mx-2 fill-orange-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
          </svg>
          <input
            placeholder={t("searchFriend")}
            className="flex-1 h-10 bg-inherit pl-2 outline-none placeholder:text-orange-300"
            value={searchText}
            onChange={(e) => {
              const value = e.target.value;
              setSearchText(value);
              if (timeRef.current) {
                clearTimeout(timeRef.current);
              }
              if (value) {
                timeRef.current = setTimeout(() => {
                  params.set("keyword", value);
                  setParams(params, { replace: true });
                }, 1000);
              } else {
                params.set("keyword", value);
                setParams(params, { replace: true });
              }
            }}
          />
          <div
            onClick={() => {
              const value = "";
              setSearchText(value);
              params.set("keyword", value);
              setParams(params, { replace: true });
              if (timeRef.current) {
                clearTimeout(timeRef.current);
              }
            }}
            className={`w-16 ${
              searchText ? "flex" : "hidden"
            } items-center justify-end`}
          >
            <svg
              className="w-4 fill-orange-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </div>
        </div>
      </div>
      {keyword ? (
        <div className="flex flex-col px-4 mt-4">
          {data?.list.map((item, index) => {
            const isFriend =
              dataFriend?.list.friend.find(
                (i) => i.friend_username === item.username,
              ) ||
              dataFriend?.list.sent.find(
                (i) => i.friend_username === item.username,
              );
            return (
              <div
                key={index}
                className="flex items-center py-2 border-b border-gray-800"
              >
                <div
                  onClick={() => {
                    navigate(PATH.contact + "/detail/" + item.username);
                  }}
                  className="flex items-center flex-1"
                >
                  <img
                    src={item.avatar}
                    className="w-10 h-10 rounded-full bg-slate-600"
                  />
                  <div className="flex-1 flex ml-4 mr-2 flex-col">
                    <span className="text-sm font-semibold">
                      {item.full_name || item.username}
                    </span>
                    <span className="text-xs">{item.mobile}</span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    mutate({
                      username: localStorage.getItem("username") + "",
                      update_type: "add",
                      friend_username: item.username,
                      remark: `${localStorage.getItem(
                        "username",
                      )} sent a friend request`,
                    });
                  }}
                  className="w-16 flex items-center justify-center"
                >
                  {isFriend ? (
                    <span className="text-xs text-orange-300">Requested</span>
                  ) : isPending ? (
                    <Spin />
                  ) : (
                    <svg
                      className="w-6 fill-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM504 312l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                    </svg>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="bg-gray-900 px-4 mt-4 py-2">
            <span className="text-sm font-semibold">
              {t("tabs.friendRequest")}
            </span>
          </div>
          <div className="flex items-center">
            {listTabs.map((item, index) => {
              const active = code === item.id;
              return (
                <div
                  key={index}
                  className={`flex-1 flex items-center transition-all justify-center border-b py-4 ${
                    active && "border-orange-300 border-b-2"
                  }`}
                  onClick={() => {
                    params.set("code", item.id);
                    setParams(params, { replace: true });
                  }}
                >
                  <span
                    className={`text-sm font-semibold ${
                      active && "text-orange-300"
                    }`}
                  >
                    {t(`tabs.${item.code}`)}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col">
            {code === listTabs[0].id ? <FriendRequest /> : null}
            {code === listTabs[1].id ? <MyFriendRequest /> : null}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewFriends;
