import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "..";
import { message } from "antd";

export interface PromotionItemProps {
  id: string;
  bonus: string;
  wager: string;
  web: string;
  mobile: string;
  content: string;
  button: string;
  category: string;
}

export const usePromotionList = () => {
  return useQuery({
    queryKey: ["PromotionList"],
    queryFn: async () => {
      const res = await fetchAPI<PromotionItemProps[]>(
        null,
        "PromotionList",
        null,
      );
      if (res.status === 0) {
        return res.data;
      }
      message.error(res.msg);
      return Promise.reject(res.msg);
    },
  });
};