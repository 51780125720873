function HelpDesk() {
  return (
    <iframe
      className="w-full h-screen"
      src="/help-desk.html"
    ></iframe>
  );
}

export default HelpDesk;
