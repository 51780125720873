import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { AgentReportResponse, PerformanceQueryResponse, ReferralInfoResponse, TeamManagementResponse } from "./type";

export const useGetAgentReport = () => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["GetAgentReport"],
    queryFn: async () => {
      const res = await fetchAPI<AgentReportResponse>(
        { username },
        "GetAgentReport",
        username,
      );
      return res.data;

    }
  })
}

export const usePerformanceQuery = () => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["PerformanceQuery"],
    queryFn: async () => {
      const res = await fetchAPI<PerformanceQueryResponse>(
        { username },
        "PerformanceQuery",
        username,
      );
      return res.data;

    }
  })
}

export const useTeamManagement = () => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["TeamMemberList"],
    queryFn: async () => {
      const res = await fetchAPI<TeamManagementResponse[]>(
        { username },
        "TeamMemberList",
        username,
      );
      return res.data;

    }
  })
}

export const useGetReferralInfo = (payload: string) => {
  return useQuery({
    queryKey: ["GetReferralInfo"],
    queryFn: async () => {
      if (!payload) {
        return Promise.resolve(null); // Không gọi API nếu payload không hợp lệ
      }
      const res = await fetchAPI<ReferralInfoResponse>(
        { referral_code: payload },
        "GetReferralInfo",
        null
      );
      return res.data;
    },
  })
}