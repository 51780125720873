import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchPaginationAPI } from "apis";
import { SubGameItemProps } from "./type";

export interface SubGameListParams {
  search: string;
  game_type?: string;
  provider?: string;
}

export const useSubGameList = (payload: SubGameListParams) => {
  const username = localStorage.getItem("username");
  return useInfiniteQuery({
    queryKey: [
      "GetSubGameList",
      payload.game_type,
      payload.provider,
      payload.search,
    ],
    enabled: !!payload.provider && !!payload.game_type,
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      const res = await fetchPaginationAPI<SubGameItemProps[]>(
        { ...payload, page: pageParam, size: 21, username: username },
        "GetSubGameList",
        null,
      );
      return res;
    },
    getNextPageParam: (lastPage) => lastPage.currentPage + 1,
    getPreviousPageParam: (firstPage) => firstPage.currentPage - 1,
  });
};


export const useSearchSubGameList = (payload: SubGameListParams) => {
  const username = localStorage.getItem("username");
  return useInfiniteQuery({
    queryKey: [
      "GetSubGameList",
      payload.search,
    ],
    enabled: !!payload.search && payload.search.length >= 3,
    initialPageParam: 1,
    queryFn: async ({ pageParam }) => {
      const res = await fetchPaginationAPI<SubGameItemProps[]>(
        { ...payload, page: pageParam, size: 21, username: username },
        "GetSubGameList",
        null,
      );
      return res;
    },
    getNextPageParam: (lastPage) => lastPage.currentPage + 1,
    getPreviousPageParam: (firstPage) => firstPage.currentPage - 1,
  });
};

