import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const Benefits: React.FC = () => {
  const { t } = useTranslation("ranking");
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const tiers = [
    {
      title: t("tiers.bronze.title"),
      benefits: [
        t("tiers.bronze.benefits.0"),
        t("tiers.bronze.benefits.1"),
        t("tiers.bronze.benefits.2"),
        t("tiers.bronze.benefits.3"),
        t("tiers.bronze.benefits.4"),
      ],
    },
    {
      title: t("tiers.silver.title"),
      benefits: [
        t("tiers.silver.benefits.0"),
        t("tiers.silver.benefits.1"),
      ],
    },
    {
      title: t("tiers.gold.title"),
      benefits: [
        t("tiers.gold.benefits.0"),
        t("tiers.gold.benefits.1"),
        t("tiers.gold.benefits.2"),
      ],
    },
    {
      title: t("tiers.platinum.title"),
      benefits: [
        t("tiers.platinum.benefits.0"),
        t("tiers.platinum.benefits.1"),
        t("tiers.platinum.benefits.2"),
      ],
    },
    {
      title: t("tiers.diamond.title"),
      benefits: [
        t("tiers.diamond.benefits.0"),
        t("tiers.diamond.benefits.1"),
        t("tiers.diamond.benefits.2"),
        t("tiers.diamond.benefits.3"),
      ],
    },
  ];
  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="space-y-2">
      {tiers.map((tier, index) => (
        <div
          key={index}
          className="bg-[#655746] text-white rounded-md overflow-hidden"
        >
          <div
            className="flex justify-between items-center px-4 py-3 cursor-pointer border-b border-[#2f4553]"
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex items-center gap-2">
              <span>{tier.title}</span>
            </div>
            <span
              className={`transform text-[10px] transition-transform ${openIndex === index ? "rotate-180" : ""
                }`}
            >
              ▼
            </span>
          </div>
          <div
            className={`transition-[max-height] duration-300 ease-in-out overflow-hidden ${openIndex === index ? "max-h-screen" : "max-h-0"
              }`}
          >
            <ul className="px-6 py-4 space-y-2">
              {tier.benefits.map((benefit, idx) => (
                <li key={idx} className="flex items-center gap-2">
                  <div className="w-2 h-2 bg-[#B1BAD3] rounded-full"></div>
                  <span className="text-sm text-[#B1BAD3]">{benefit}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Benefits;
