import { MissionItemProps, useGetMission, useMissionList } from "apis/mission";
import HeaderTab from "components/HeaderTab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MissionDetail from "./MissionDetail";

const MissionPage = () => {
  const { t } = useTranslation("ranking");
  const { data: missionData, refetch } = useMissionList();
  const [dataItem, setDataItem] = useState<MissionItemProps>();

  const { mutateAsync } = useGetMission({
    onSuccess: () => {
      refetch();
    },
  });
  const onGetMission = (promotion_id: string) => {
    mutateAsync({ promotion_id });
  };
  return (
    <div className="bg-linearBlack w-full h-full overflow-hidden">
      <HeaderTab title="Mission Page" />
      <div className="pt-4 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3">
        <div className="w-full flex items-center justify-center">
          {missionData?.map((mission, index) => {
            return (
              <div
                key={index}
                className="border border-solid border-[#E3B85D] p-2 bg-black rounded w-full"
              >
                <img src={mission.mobile} />
                <div className="text-[#E3B85D] text-sm text-center my-2">
                  {mission.bonus}
                </div>
                <div className="flex gap-2">
                  <div
                    onClick={() => setDataItem(mission)}
                    className="cursor-pointer rounded bg-[#E3B85D] text-xs text-black text-center py-1.5 flex-1"
                  >
                    {t("moreInfo")}
                  </div>
                  <div
                    onClick={() => onGetMission(mission.id)}
                    className={`${
                      !mission.claim
                        ? "opacity-60 pointer-events-none"
                        : "cursor-pointer"
                    } rounded
                 bg-[#655746] text-xs text-white text-center py-1.5 flex-1`}
                  >
                    {t("claim")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {dataItem && (
        <MissionDetail
          dataItem={dataItem}
          onClose={() => setDataItem(undefined)}
          open={!!dataItem}
        />
      )}
    </div>
  );
};

export default MissionPage;
