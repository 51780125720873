import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
// import IconHome from "assets/icons/icon-home.svg";
import Logomain from "assets/images/logo-main.png";
import IconContact from "assets/icons/icon-phone.svg";
import IconProfile from "assets/icons/icon-user.svg";
import IconHelpDesk from "assets/icons/icon-help-desk.svg";
import IconMission from "assets/icons/icon-transaction.svg";
import clsx from "clsx";
import { PATH } from "constants/path";

function BottomTabs() {
  const dataMenu = [
    {
      id: "mission",
      icon: IconMission,
      name: "Mission",
      url: "/mission",
    },
    {
      id: "contact",
      icon: IconContact,
      name: "Contact",
      url: "/contact",
    },
    {
      id: "home",
      icon: Logomain,
      name: "",
      url: "/",
    },
    {
      id: "help",
      icon: IconHelpDesk,
      name: "Help desk",
      url: PATH.helpDesk,
    },
    {
      id: "profile",
      icon: IconProfile,
      name: "Me",
      url: "/me",
    },
  ];

  return (
    <div className="fixed bottom-0 left-0 w-screen z-50">
      <div
        style={{
          backgroundImage: `url("/images/gold.png")`,
        }}
        className="relative flex items-center w-full xl:!bg-bottomBackground shadow-sm bg-[top_center] container-max-width bg-no-repeat bg-cover"
      >
        {dataMenu.map((item) => (
          <Link
            key={item.id}
            to={item.url}
            className="relative flex-1 flex flex-col items-center justify-center h-16"
          >
            {item.id === "home" ? (
              <div
                className={clsx(
                  "absolute -top-[35px] lg:-top-[55px] rounded-full flex items-center justify-center max-w-[85px] lg:max-w-[120px] w-full"
                )}
              >
                <img src={item.icon} alt="logo" />
              </div>
            ) : (
              <ReactSVG src={item.icon} />
            )}
            <span className="text-black text-sm font-semibold">
              {item.name}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BottomTabs;
