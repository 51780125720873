import { message, Spin } from "antd";
import { GameWallet, useGetPlayerWallets } from "apis/games/GetPlayerWallets";
import { useWithdrawGameBalance } from "apis/games/WithdrawGameBalance";
import BottomSheet from "components/BottomSheet";
import { createAmountSelect } from "helper/createAmountSelect";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

function ModalTransfer({ open, onClose }: Props) {
  const { data: playerWallet } = useGetPlayerWallets();
  const [gameWallet, setGameWallet] = useState<GameWallet | null>(null);
  const [amount, setAmount] = useState("0");
  const { mutateAsync, isPending } = useWithdrawGameBalance({
    onSuccess: () => {
      setGameWallet(null);
      setAmount("0");
      onClose();
    },
  });

  const onSubmit = async () => {
    if (!gameWallet) return message.error("Game wallet is empty!");
    if (!amount) return message.error("Amount is empty!");
    await mutateAsync({
      provider: gameWallet?.game_provider_code + "",
      amount: amount,
    });
  };
  return (
    <BottomSheet isOpen={open} onClose={onClose}>
      <div className="flex flex-col p-4">
        <div className="flex flex-row items-center justify-between py-2 border-b">
          <h3 className="text-orange-300 font-semibold">Main wallets</h3>
          <span>{playerWallet?.main_points || "0.00"} MVR</span>
        </div>
        <div className="grid grid-cols-3 xl:grid-cols-4 gap-3 py-2 max-h-[50vh] overflow-auto border-b">
          {playerWallet?.game_wallets.map((item, index) => {
            const isActive =
              gameWallet?.game_provider_code === item.game_provider_code;
            return (
              <div
                className={`flex items-center flex-col justify-center p-2 rounded-xl ${
                  isActive ? "bg-orange-300 text-white" : "bg-slate-800"
                }`}
                key={index}
                onClick={() => {
                  setGameWallet(item);
                  setAmount(item.balance.toString() || "");
                }}
              >
                <span
                  className={`font-semibold ${
                    isActive ? "text-white" : "text-orange-300"
                  }`}
                >
                  {item.game_provider_code}
                </span>
                <span className="text-sm">{item.balance || "0.00"} MVR</span>
              </div>
            );
          })}
        </div>

        <div className={`flex items-center justify-between py-2 border-b`}>
          <div className="flex flex-row items-center justify-center bg-slate-800 rounded-xl p-2">
            <span className="text-orange-300 font-semibold">
              {gameWallet?.game_provider_code}
            </span>
            <span className="px-4"> - </span>
            <span className="text-sm">{gameWallet?.balance || "0.00"} MVR</span>
          </div>
          <span>to</span>
          <div className="flex flex-row items-center justify-center bg-slate-800 rounded-xl p-2">
            <span className="text-orange-300 font-semibold">Main</span>
            <span className="px-4"> - </span>
            <span className="text-sm">
              {playerWallet?.main_points || "0.00"} MVR
            </span>
          </div>
        </div>
        <div className="flex flex-col py-2">
          <div className="w-full relative">
            <input
              className="text-center h-12 w-full rounded-xl bg-slate-800 px-10 outline-none"
              placeholder="0.0"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <span className="text-sm absolute right-2 top-[14px] font-semibold">
              (MVR)
            </span>
          </div>
          <div className="flex items-center flex-wrap justify-around mt-4">
            {createAmountSelect(100).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setAmount(item.toString())}
                  className="flex items-center justify-center px-3 py-2 text-sm bg-slate-800 rounded-xl"
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center justify-center mt-4">
          <button
            onClick={onSubmit}
            className="bg-linearGold w-full py-2 rounded-xl text-black font-bold"
          >
            {isPending ? <Spin size="small" /> : "Submit"}
          </button>
        </div>
      </div>
    </BottomSheet>
  );
}

export default ModalTransfer;
