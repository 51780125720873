import BottomTabs from "components/BottomTabs";
import HeaderTab from "components/HeaderTab";
import React from "react";
import { Link } from "react-router-dom";
import ImgBetting from "assets/images/betting-records.png";
import IconChevronRight from "assets/icons/icon-chevron-right.svg";
import { ReactSVG } from "react-svg";
import ImgTransactions from "assets/images/transaction-records.png";
import { useTranslation } from "react-i18next";

const PlayerTransactionsPage = () => {
  const { t } = useTranslation("transactions");

  const dataPlayer = [
    {
      id: "betting",
      name: t("Betting"),
      url: "/betting-records",
      image: ImgBetting,
    },
    {
      id: "transactions",
      name: t("Transactions"),
      url: "/transactions-records",
      image: ImgTransactions,
    },
    {
      id: "point",
      name: t("Point"),
      url: "/point-in-out-records",
      image: ImgBetting,
    },
    {
      id: "reward",
      name: t("Reward"),
      url: "/reward-records",
      image: ImgTransactions,
    },
    {
      id: "rebate",
      name: t("RebateRecords"),
      url: "/rebate-records",
      image: ImgBetting,
    },
  ];

  return (
    <div className="bg-linearBlack w-full h-full overflow-hidden">
      <HeaderTab title={t("TransactionsPage")} />
      <div className="pt-2 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3">
        <div className="mt-5">
          {dataPlayer.map((item) => (
            <Link
              key={item.id}
              to={item.url}
              className="relative flex items-center bg-[#4e442e] border border-[#E3B85D] rounded-md py-2 mb-4"
            >
              <div className="w-[50px]">
                <img src={item.image} alt="" />
              </div>
              <p className="text-[#fff5de] font-medium pl-2">{item.name}</p>
              <ReactSVG
                src={IconChevronRight}
                className="absolute rotate-180 right-6 top-[50%] -translate-y-[50%]"
              />
              <ReactSVG
                src={IconChevronRight}
                className="absolute rotate-180 right-4 top-[50%] -translate-y-[50%]"
              />
            </Link>
          ))}
        </div>
      </div>
      <BottomTabs />
    </div>
  );
};

export default PlayerTransactionsPage;
