import { QRCode } from "antd";
import { useMemberDetail } from "apis/auth/member-detail";
import HeaderTab from "components/HeaderTab";
import { useTranslation } from "react-i18next";

function ShowQRCode() {
  const { t } = useTranslation(["referral"]);
  const { data } = useMemberDetail();
  const link = window.location.origin + "/ref/" + data?.referral_code;

  const downloadQRCode = () => {
    const canvas = document.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = url;
      a.download = "qrcode.png";
      a.click();
    }
  };
  return (
    <div className="flex flex-col">
      <HeaderTab title="QR Code" />
      <div className="flex flex-col items-center justify-center mx-2 my-4">
        <p className="text-orange-300">{t("ReferralCode")}</p>
        <p className="text-orange-300 font-semibold">{data?.referral_code}</p>
        <QRCode value={link} color="white" size={300} />
        <div
          onClick={downloadQRCode}
          className="flex items-center bg-linearGold w-2/3 px-4 justify-center py-2 rounded-md mt-3"
        >
          <img src="/icons/common/image-download.png" className="w-6" />
          <span className="ml-2">{t("save")}</span>
        </div>
      </div>
    </div>
  );
}

export default ShowQRCode;
