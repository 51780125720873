import { Pagination } from "antd";
import React from "react";
import "./style.css";

interface Props {
  lengthData: number;
  currentPage: number;
  pageSize: number;
  total: number;
  onChangePage: (pagePagination: number) => void;
}

const PaginationsCustom = ({
  lengthData,
  currentPage,
  pageSize,
  total,
  onChangePage,
}: Props) => {

  return (
    (lengthData || 0) > 0 && (
      <div className="flex w-full justify-center">
        <Pagination
          className="pagination-custom"
          current={currentPage}
          pageSize={pageSize}
          total={total}
          onChange={(page: number) => onChangePage(page)}
          showSizeChanger={false}
        />
      </div>
    )
  );
};

export default PaginationsCustom;
