/* eslint-disable @typescript-eslint/no-explicit-any */
import { Empty, Form, Select, SelectProps } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";

import "./style.scss";
import clsx from "clsx";

interface SelectCustomProps extends SelectProps {
  dropdownBackground?: string;
  name?: string;
  control?: Control<FieldValues | any>;
  defaultValue?: any;
  error?: string;
  validate_status?: ValidateStatus;
  defaultValueSelect?: any;
  label?: string;
}

const SelectCustom = (props: SelectCustomProps) => {
  const {
    dropdownBackground,
    name = "",
    control,
    defaultValue,
    error,
    label,
    validate_status,
    defaultValueSelect,
    ...rest
  } = props;

  const dropdownStyle = {
    padding: "10px",
    backgroundColor: dropdownBackground ? dropdownBackground : "white",
  };

  return control ? (
    <Form.Item
      label={
        label ? (
          <p style={{ fontSize: "16px", color: "white" }}>{label}</p>
        ) : null
      }
      labelCol={{ span: 24 }}
      help={error}
      validateStatus={validate_status}
      className={clsx(props.className, "select-custom")}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange: _onChange, value } }) => (
          <Select
            {...rest}
            dropdownStyle={dropdownStyle}
            onChange={_onChange}
            defaultValue={defaultValueSelect}
            value={value}
            notFoundContent={<Empty description={"Empty"} />}
          ></Select>
        )}
      />
    </Form.Item>
  ) : (
    <Select
      {...rest}
      dropdownStyle={dropdownStyle}
      defaultValue={defaultValue}
      className={clsx(props.className, "select-custom")}
    />
  );
};

export default SelectCustom;
