export enum OrderStatus {
    Pending = "0",
    Approve = "1",
    Reject = "2",
    Processing = "3",
  }
  
  export enum OrderType {
    DEPOSIT = 1,
    WITHDRAWAL = 2,
    REBATE = 3,
    NONE = 0,
  }
  
  export enum DepositType {
    OFFLINE = 1,
    ONLINE = 2,
  }