import { useSearchParams } from "react-router-dom";
import Favourites from "./favourites";
import GridGameContent from "./grid-game";
import InfoView from "./info-view";
import { useListMenu } from "./menu";
import "./style.css";
function GameContainer() {
  const [params, setParams] = useSearchParams();
  const listMenu = useListMenu();

  const codeActive = params.get("code") || listMenu[2].code;

  return (
    <div className="w-full">
      <div className="w-full overflow-x-auto scrollbar-hide flex items-center justify-around py-3 gap-x-4">
        {listMenu.map((item, index) => {
          const isActive = codeActive === item.code;
          return (
            <div
              onClick={() => {
                params.set("code", item.code);
                setParams(params, { replace: true });
              }}
              key={index}
              className={`flex items-center item-category cursor-pointer ${isActive ? "border-b-2 border-white item-active" : ""
                }`}
            >
              {item.icon}
              <span className="ml-1 text-orange-300 text-sm whitespace-nowrap">{item.name}</span>
            </div>
          );
        })}
      </div>
      <div className="w-full">
        {codeActive === listMenu[2].code ? <GridGameContent /> : null}
        {codeActive === listMenu[7].code ? (
          <GridGameContent gameType="BG" />
        ) : null}
        {codeActive === listMenu[5].code ? (
          <GridGameContent gameType="FH" />
        ) : null}
        {codeActive === listMenu[4].code ? (
          <GridGameContent gameType="SL" />
        ) : null}
        {codeActive === listMenu[3].code ? (
          <GridGameContent gameType="LC" />
        ) : null}
        {codeActive === listMenu[6].code ? (
          <GridGameContent gameType="SB" />
        ) : null}
        {/* {codeActive === listMenu[7].code ? (
          <GridGameContent gameType="ES" />
        ) : null} */}
        {codeActive === listMenu[8].code ? (
          <GridGameContent gameType="ARCADE" />
        ) : null}
        {codeActive === listMenu[1].code ? <Favourites /> : null}
        {codeActive === listMenu[0].code ? <InfoView /> : null}
      </div>
    </div>
  );
}

export default GameContainer;
