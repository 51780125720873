import { LevelProgressType, RankingType, UserTurnOver } from "types/vip";
import axiosRequest from "..";
import { PayloadLevelProgress, VipLevel } from "./type";
import { payloadHelper } from "helper/payloadHelper";

export const vipApi = {
  async showVipLevel() {
    const response = await axiosRequest.post<VipLevel>(
      "/",
      payloadHelper("showVipLevel", null, null)
    );
    return response;
  },
  async getLevelProgress(payload: PayloadLevelProgress) {
    const response = await axiosRequest.post<LevelProgressType[]>(
      "/",
      payloadHelper("levelProgress", payload.username, payload)
    );
    return response;
  },
  async getRankingWeekly(payload: PayloadLevelProgress) {
    const response = await axiosRequest.post<{ data: RankingType[] }>(
      "/",
      payloadHelper("showRankingWeekly", payload.username, payload)
    );
    return response;
  },
  async getRankingMonthly(payload: PayloadLevelProgress) {
    const response = await axiosRequest.post<{ data: RankingType[] }>(
      "/",
      payloadHelper("showRankingMonthly", payload.username, payload)
    );
    return response;
  },
  async getUserTurnOver(payload: PayloadLevelProgress) {
    const response = await axiosRequest.post<{ data: UserTurnOver }>(
      "/",
      payloadHelper("showRankingTotalByUser", payload.username, payload)
    );
    return response;
  },
};
