import { PATH } from "constants/path";
import { Link } from "react-router-dom";
import { useAppStore } from "stores/app";

function HeaderHome() {
  const { isAuthenticated } = useAppStore();
  return (
    <div className="w-full flex justify-between py-2">
      <div />
      <div className="flex items-center justify-end gap-2">
        {isAuthenticated ? (
          <div className="border border-orange-400 py-1 px-2 flex items-center justify-center rounded-full">
            <div className="rounded-full bg-orange-400 p-1 w-6">
              <img src="/icons/home/coins.svg" className="w-full" />
            </div>
            <span className="ml-1">MVR</span>
            <img src="/icons/home/caret-down.svg" className="w-4 ml-1" />
          </div>
        ) : (
          <div className="flex flex-row">
            <Link
              to={PATH.login}
              className="px-4 py-1 rounded-md text bg-linearGold text-black text-sm cursor-pointer font-semibold"
            >
              Login
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeaderHome;
