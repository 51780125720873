import HeaderTab from 'components/HeaderTab'
import React from 'react'
import CardAuthenVip from './components/RankCard'

const RankingPage = () => {
  return (
    <div className='bg-linearBlack w-full h-full overflow-hidden'>
      <HeaderTab title="Ranking Page" />
      <div className='py-4 w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3'>
        <div className='w-full h-full flex items-center justify-center'>
          <CardAuthenVip />
        </div>
      </div>
    </div>
  )
}

export default RankingPage