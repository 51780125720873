import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useTranslation } from "react-i18next";

export interface ChangePasswordFormProps {
  oldpass: string;
  password: string;
  passconf: string;
  mobile: string,
  otp: string
}

export interface FormProps {
  onSuccess?: () => void;
}

export const useChangePassword = ({ onSuccess }: FormProps) => {
  const { t } = useTranslation("change-password");
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["ChangePassword"],
    mutationFn: async (payload: ChangePasswordFormProps) => {
      const res = await fetchAPI(
        { ...payload, username: username },
        "ChangePassword",
        username,
      );
      if (res.status === 0) {
        return res;
      }
      return Promise.reject(res.msg);
    },
    onSuccess: () => {
      message.success(t("successMsg"));
      onSuccess && onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
