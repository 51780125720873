import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import {
  useForgotPasswordForm,
  useRequestOTP,
} from "apis/auth/forgot-password";
import BottomSheet from "components/BottomSheet";
import { MOBILE_PREFIX } from "constants/mobile";
import { PATH } from "constants/path";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

type FormRegisterProps = {
  username: string;
  fullname: string;
  mobile: string;
  otp: string;
};

const ForgotPasswordForm = () => {
  const { t } = useTranslation("register");
  const navigate = useNavigate();
  const [selectedPrefix, setSelectedPrefix] = useState(MOBILE_PREFIX[0]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [otpTime, setOTPTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (otpTime) {
      interval.current = setInterval(() => {
        setOTPTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [otpTime]);
  const schema = yup
    .object({
      username: yup.string().trim().lowercase().required(t("usernameEmpty")),
      fullname: yup.string().required(t("fullnameEmpty")),
      mobile: yup
        .string()
        .trim()
        .lowercase()
        .required(t("mobileEmpty"))
        .matches(selectedPrefix.regex, t("mobileMatches")),
      otp: yup.string().trim().lowercase().required(t("otpEmpty")),
    })
    .required();

  const defaultValuesForm: FormRegisterProps = {
    username: "",
    fullname: "",
    mobile: "",
    otp: "",
  };

  const backToLogin = () => {
    // message.success(t("forgotPasswordSuccess"));
    // navigate(PATH.login);
    setIsSuccess(true);
  };
  const { mutate } = useForgotPasswordForm(backToLogin);
  const { mutate: requestOTPAPI } = useRequestOTP();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValuesForm,
  });
  const mobile = watch("mobile");
  const username = watch("username");
  const onSubmit: SubmitHandler<FormRegisterProps> = (data) => {
    mutate({
      ...data,
      mobile: `${selectedPrefix.code.replace("+", "")}${mobile}`,
    });
  };

  const sendOTP = async () => {
    if (!mobile || !username) {
      if (!username)
        setError("username", {
          type: "validate",
          message: t("usernameEmpty"), // Thông báo lỗi
        });
      if (!mobile)
        setError("mobile", {
          type: "validate",
          message: t("mobileEmpty"), // Thông báo lỗi
        });
      return;
    }
    setOTPTime(60);
    requestOTPAPI({
      username: username,
      mobile: `${selectedPrefix.code.replace("+", "")}${mobile}`,
      type: 4,
    });
  };

  const copyPassword = () => {
    message.success(t("copied"));
    const password =
      (document.getElementById("new-password") as HTMLInputElement)?.value ||
      "";
    navigator.clipboard.writeText(password);
  };

  return (
    <div className="px-2 mt-8">
      <div className="mb-2 text-yellow-300">{t("userInfor")}</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-sm">
          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/user.png"} className="w-4" />
              </div>
              <span className="mx-2 text-yellow-400">|</span>
              <input
                placeholder={t("username")}
                {...register("username")}
                type={"text"}
                className="bg-transparent outline-none flex-grow placeholder-yellow-400"
              />
            </div>
          </div>
          {errors?.username && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.username?.message}
            </div>
          )}

          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/user.png"} className="w-4" />
              </div>
              <span className="mx-2 text-yellow-400">|</span>
              <input
                placeholder={t("fullname")}
                type={"text"}
                {...register("fullname")}
                className="bg-transparent outline-none flex-grow placeholder-yellow-400"
              />
            </div>
          </div>
          {errors?.fullname && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.fullname?.message}
            </div>
          )}

          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
              <div className="flex-shrink-0">
                <div className="flex" onClick={() => setOpen(true)}>
                  <div>{selectedPrefix.code}</div>
                </div>
              </div>
              <span className="mx-2 text-yellow-400">|</span>
              <input
                placeholder={t("mobile")}
                type={"text"}
                {...register("mobile")}
                className="bg-transparent outline-none flex-grow placeholder-yellow-400"
              />
            </div>
          </div>
          {errors?.mobile && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.mobile?.message}
            </div>
          )}

          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
              <input
                {...register("otp")}
                placeholder={t("otp")}
                type={"text"}
                className="bg-transparent outline-none flex-grow placeholder-yellow-400"
              />
              <div
                className={`flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer w-28 text-center ${otpTime ? 'opacity-70 pointer-events-none' : ''}`}
                onClick={sendOTP}
              >
                {otpTime ? otpTime : t("requestOTP")}
              </div>
            </div>
          </div>
          {errors?.otp && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.otp?.message}
            </div>
          )}

          {isSuccess && (
            <div>
              <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
                <input
                  type={"text"}
                  className="bg-transparent outline-none flex-grow placeholder-yellow-400"
                  id="new-password"
                />
                <div
                  className="flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer"
                  onClick={copyPassword}
                >
                  {t("copy")}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="px-2 mt-8">
          <button
            type="submit"
            className="w-full text-center py-2 bg-linearGold rounded cursor-pointer text-black mb-4"
          >
            {t("forgotSubmit")}
          </button>
        </div>
      </form>
      <div className="px-2">
        <div
          onClick={() => navigate(PATH.login)}
          className="text-center py-2 border border-solid border-[#EEB154] rounded cursor-pointer text-[#FFE794] mb-6"
        >
          {t("memberlogin")}
        </div>
      </div>
      <BottomSheet isOpen={open} onClose={() => setOpen(false)}>
        <div className="min-h-40">
          <div className="text-center py-3 bg-gradient-to-r from-[#FFE794] to-[#EEB154] text-orange-300 font-semibold">
            {t("Country")}
          </div>
          {MOBILE_PREFIX.map((prefix, index) => {
            return (
              <div
                key={index}
                className="relative py-3 border-b border-solid border-[#EEB154] text-[#FFE794] text-center bg-black"
                onClick={() => {
                  setOpen(false);
                  setSelectedPrefix(prefix);
                  setFocus("mobile");
                }}
              >
                {prefix.code}
              </div>
            );
          })}
        </div>
      </BottomSheet>
    </div>
  );
};

export default ForgotPasswordForm;
