import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ActionApp, AppState } from "./type";

export const useAppStore = create<AppState & ActionApp>()(
  immer((set) => ({
    isAuthenticated: !!localStorage.getItem("username"),
    setAuthentication: (val: boolean) =>
      set((state) => {
        state.isAuthenticated = val;
      }),
  })),
);
