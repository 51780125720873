import { Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import clsx from 'clsx';

export interface LoadingProps {
  height?: number;
  center?: boolean;
  fontSize?: number;
  width?: number | string;
  className?: string
}

const LoadingCustom = (props: LoadingProps) => {
  const { fontSize = 40, center, height, className = "" } = props;

  return (
    <div
      className={clsx(
        "relative",
        center && "grid items-center",
        height && `h-[${height}px]`,
        className
      )}
    >
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: `${fontSize}px` }} spin />
        }
        className='text-[#E0BB48]'
      />
    </div>
  )
}

export default LoadingCustom