import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import referralImage from "./referal.png";
import { useMemberDetail } from "apis/auth/member-detail";
import { useState } from "react";
import { PATH } from "constants/path";
import { useAppStore } from "stores/app";
import { useBalance } from "apis/auth/balance";
import { message } from "antd";
import IconDeposit from "assets/images/icon-withdrawal.png";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { isIOS } from "constants/device";
import { useModalConfirm } from "components/ModalConfirm";

type MenuItem = {
  title: string;
  icon: string;
  url?: string;
  class?: string;
};

const Setting = () => {
  const { contextHolder, modalConfig } = useModalConfirm();
  const { t } = useTranslation("setting");
  const { data: profile } = useMemberDetail();
  const [isDisplayPoint, setIsDisplayPoint] = useState(false);
  const navigate = useNavigate();
  const setAuthentication = useAppStore((state) => state.setAuthentication);
  const { data: balanceData } = useBalance();
  const queryClient = useQueryClient();

  const commonFeatures: MenuItem[] = [
    {
      title: t("topup"),
      icon: IconDeposit,
      url: PATH.topup,
    },
    {
      title: t("withdraw"),
      icon: "/images/Withdrawal.png",
      url: PATH.withdrawal,
    },
    {
      title: t("referral"),
      icon: "/icons/settings/referral.png",
      url: PATH.referral,
    },
    {
      title: t("event"),
      icon: "/images/Event.png",
      url: PATH.promotion,
    },
    {
      title: t("addBank"),
      icon: "/icons/settings/wallet.png",
      url: "/add-bank",
    },
    // {
    //   title: t("transactionRecord"),
    //   icon: "/images/Transaction Record.png",
    //   url: PATH.transactions,
    // },
    // { title: t("winloseReport"), icon: "/images/Win Lose Report.png" },
    {
      title: "Transactions",
      icon: "/images/Betting Record.png",
      url: PATH.playerTransaction,
    },
    // { title: t("redEnvelop"), icon: "/images/Red Envelope.png" },
  ];

  const otherFeature: MenuItem[] = [
    // { title: t("guide"), icon: "/images/Tutorial.png" },
    // { title: t("vpn"), icon: "/images/vpn.png" },
    // { title: t("customerService"), icon: "/images/Customer Service.png" },
    {
      title: t("information"),
      icon: "/images/Infomation.png",
      url: PATH.me.information,
    },
    {
      title: t("profile"),
      icon: "/icons/settings/user-regular.svg",
      url: PATH.me.edit,
      class: "p-[6px]",
    },
    {
      title: t("changePassword"),
      icon: "/images/Setting.png",
      url: PATH.changePassword,
    },
    {
      title: t("downloadApp"),
      icon: "/images/Setting.png",
      url: PATH.dowloadApp,
    },
  ];

  const handleLogout = () => {
    modalConfig({
      title: t("Logout"),
      content: t("LogoutMsg"),
      onSuccess: () => {
        navigate(PATH.login, { replace: true });
        setTimeout(() => {
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          setAuthentication(false);
          queryClient.clear();
          Object.keys(localStorage).forEach((key) => {
            if (key.includes("twk")) {
              localStorage.removeItem(key);
            }
          });
          Object.keys(sessionStorage).forEach((key) => {
            if (key.includes("twk")) {
              localStorage.removeItem(key);
            }
          });
          document.cookie.split(";").forEach((c) => {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
              );
          });
        }, 100);
      },
    });
  };
  return (
    <div className="px-4 h-screen overflow-auto">
      {contextHolder}
      <div
        className={clsx(
          "bg-gradient-to-b from-[#252525] to-[#000000]",
          isIOS ? "pb-[170px]" : "pb-32"
        )}
      >
        <div className="text-center text-black bg-linearGold py-2 -mx-4 mb-2">
          {t("me")}
        </div>
        <div>
          <div className="mb-2 text-yellow-300 ">{t("userInfo")}</div>
          <div className="flex bg-[#111111] mb-4">
            <div className="px-2">
              <img src={referralImage} alt="Logo" className="w-[64px]" />{" "}
            </div>
            <div className="flex-1 text-center">
              <div className="text-sm">
                {t("Account")}: {profile?.username}
              </div>
              {profile?.nickname && (
                <div className="text-sm">
                  {t("Nickname")}: {profile?.nickname}
                </div>
              )}
              {profile?.full_name && (
                <div className="text-sm">
                  {t("Fullname")}: {profile?.full_name}
                </div>
              )}
              {profile?.mobile && (
                <div className="text-sm">{profile?.mobile}</div>
              )}
              {profile?.referrer && (
                <div className="text-sm">
                  {t("AgentAccount")}: {profile?.referrer}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center text-sm">
            <div className="w-full max-w-[500px]">
              <div className="flex justify-between mb-2">
                <div>{t("Wallet")}</div>
                <div>
                  <img
                    src={
                      isDisplayPoint
                        ? "/images/eye-block.png"
                        : "/images/eye.png"
                    }
                    className="w-4"
                    onClick={() => setIsDisplayPoint(!isDisplayPoint)}
                  />
                </div>
              </div>
              <div className="border border-[#FFE794] p-1 rounded flex mb-4">
                <div className="flex-1 border-r border-white text-center">
                  <div className="text-[#FFE794]">{t("cash")}</div>
                  <div className="">
                    {isDisplayPoint ? balanceData?.total : "****"}
                  </div>
                </div>
                <div className="flex-1  text-center">
                  <div className="text-[#FFE794]">{t("chip")}</div>
                  <div className="">
                    {isDisplayPoint ? profile?.chip_points : "****"}
                  </div>
                </div>
              </div>
              <div className="mb-2">{t("commonFeatures")}</div>
              <div className="bg-[#655846] rounded p-4 grid grid-cols-5 gap-x-2 gap-y-4 justify-around items-center mb-4">
                {commonFeatures.map((feature, index) => {
                  return (
                    <div
                      key={index}
                      className="w-full h-full flex flex-col items-center justify-start"
                      onClick={() =>
                        feature.url
                          ? navigate(feature.url)
                          : message.warning(t("commingsoon"))
                      }
                    >
                      <div className="flex justify-center">
                        <img
                          src={feature.icon}
                          className={`w-8 ${feature.class}`}
                        />
                      </div>
                      <div className="text-center text-xs">{feature.title}</div>
                    </div>
                  );
                })}
              </div>
              <div className="mb-2">{t("otherFeatures")}</div>
              <div className="bg-[#655846] rounded p-4 grid grid-cols-5 gap-x-2 gap-y-4 justify-around items-center mb-4">
                {otherFeature.map((feature, index) => {
                  return (
                    <div
                      className="w-full h-full"
                      key={index}
                      onClick={() =>
                        feature.url
                          ? navigate(feature.url)
                          : message.warning(t("commingsoon"))
                      }
                    >
                      <div className="flex justify-center">
                        <img
                          src={feature.icon}
                          className={`w-8 ${feature.class}`}
                        />
                      </div>
                      <div className="text-center text-xs">{feature.title}</div>
                    </div>
                  );
                })}
              </div>
              <div
                onClick={handleLogout}
                className="text-center py-3 bg-linearGold rounded cursor-pointer text-black mb-8 font-semibold"
              >
                {t("Logout")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
