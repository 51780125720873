import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface GetPlayerWalletsProps {
  game_wallets: GameWallet[];
  main_points: number;
}

export interface GameWallet {
  player_game_account_id: string;
  game_provider_code: string;
  username: string;
  game_id: string;
  balance: number;
}

export const useGetPlayerWallets = () => {
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["GetPlayerWallets"],
    queryFn: async () => {
      const res = await fetchAPI<GetPlayerWalletsProps>(
        { username: localStorage.getItem("username") },
        "GetPlayerWallets",
        localStorage.getItem("username"),
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res.data;
    },
    enabled: isAuth,
    refetchInterval: 1000 * 30,
  });
};
