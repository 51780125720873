import { PromotionItemProps, usePromotionList } from 'apis/promotions';
import HeaderTab from 'components/HeaderTab'
import LoadingCustom from 'components/LoadingCustom';
import { PATH } from 'constants/path';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PromotionDetail from './PromotionDetail';

const PromotionPage = () => {
  const { data: promotionData, isLoading } = usePromotionList();
  const naviagte = useNavigate();
  const [dataItem, setDataItem] = useState<PromotionItemProps | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className='bg-linearBlack w-full h-full overflow-hidden'>
      <HeaderTab title="Promotions" />
      <div className='pt-4 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3'>
        <div className='w-full flex flex-col'>
          {promotionData && !isLoading && promotionData.map((item, index) => (
            <div key={index} className="w-full mb-5 p-2 rounded-md bg-[#000000] border border-[#E0BB48]">
              <div className="w-full">
                <img src={item.web} alt="..." className='object-cover w-full' />
              </div>
              <div className="w-full mt-3 flex flex-col">
                <p className='text-center font-medium text-[18px] mb-3 text-[#ffc039]'>{item.bonus}</p>
                <div className="flex items-center justify-center w-full gap-3 py-">
                  <button
                    onClick={() => {
                      setDataItem(item);
                      setOpenModal(true);
                    }}
                    className='w-full bg-linearGold p-2 rounded-md text-[#000000]'
                  >
                    <span>More Info</span>
                  </button>
                  <button
                    onClick={() => naviagte(PATH.topup)}
                    className='w-full bg-[#E3B85D80] p-2 rounded-md text-[#ffffff]'
                  >
                    <span>Claim Offer!</span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isLoading && <LoadingCustom height={400} center />}
      </div>
      {dataItem && <PromotionDetail
        dataItem={dataItem}
        onClose={() => setOpenModal(false)}
        open={openModal}
      />}
    </div>
  )
}

export default PromotionPage