import { useSearchSubGameList } from "apis/games/get-sub-game";
import IconSearch from "assets/icons/icon-search.svg";
import ItemGameProvider from "components/ItemGameProvider";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactSVG } from "react-svg";

const SearchBar = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const searchRef = useRef<HTMLDivElement>(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>(searchParams.get("s") || '');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);


  const { data, isLoading } = useSearchSubGameList({
    search: searchParams.get("s") + ""
  });

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      searchParams.set("s", event.target.value)
      setSearchParams(searchParams, { replace: true })
      if(searchRef.current){
        searchRef.current.scrollIntoView({behavior:"smooth",block:"start"})
      }
    }, 500);
  }, []);


  const arrayGame = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((item) => item.data);
  }, [data]);


  return <div ref={searchRef} className="relative my-2">
    <div className="relative w-full">
      <input
        ref={inputRef}
        type="text"
        className="w-full h-full outline-none border border-[#E3B85D] rounded-full bg-transparent pl-4 pr-10 py-2 text-[16px] placeholder:text-[14px] text-[#E3B85D]
        placeholder:text-[#E3B85D]"
        placeholder="Search for game ..."
        value={searchQuery}
        onChange={handleInputChange}
      />
      <div className="absolute text-[#E3B85D] right-3 top-[50%] -translate-y-[50%]">
        <ReactSVG
          src={IconSearch}
          className="w-[22px] h-[22px]"
          wrapper="svg"
        />
      </div>
    </div>
    {arrayGame && arrayGame.length > 0 && (
      <div className="flex items-center justify-start flex-wrap my-[15px] gap-[10px]">
        {isLoading && <div className="w-full flex items-center justify-center text-[14px] text-[#E3B85D] my-5">Search results loading ...</div>}
        {arrayGame.map((item, index) => (
          <div key={index} className="w-[calc(25%_-_8px)] lg:w-[calc(16%_-_3px)]">
            <ItemGameProvider hideFavourite item={item}></ItemGameProvider>
          </div>
        ))}
      </div>
    )}
  </div>
}
export default memo(SearchBar)