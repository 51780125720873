import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPILogin } from "apis";
import { PATH } from "constants/path";
import { createSearchParams } from "helper/createSearchParams";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppStore } from "stores/app";

export interface FormLoginProps {
  username: string;
  password: string;
}

export const useLoginForm = () => {
  const navigate = useNavigate();
  const setAuthentication = useAppStore((state) => state.setAuthentication);
  const [params] = useSearchParams();
  const paramsObject = Object.fromEntries(params.entries());
  const path = params.get("path");
  return useMutation({
    mutationKey: ["MemberLogin"],
    mutationFn: async (payload: FormLoginProps) => {
      const res = await fetchAPILogin(payload, "MemberLogin", payload.username);
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: (data) => {
      // clear data trước khi zô
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/");
      });
      Object.keys(localStorage).forEach((key) => {
        if (key.includes("twk")) {
          localStorage.removeItem(key);
        }
      });
      Object.keys(sessionStorage).forEach((key) => {
        if (key.includes("twk")) {
          localStorage.removeItem(key);
        }
      });

      //
      localStorage.setItem("username", data.username);
      localStorage.setItem("token", data.token);
      message.success(data.msg);
      navigate(
        path ? path + "?" + createSearchParams(paramsObject) : PATH.home,
        { replace: true }
      );
      setAuthentication(true);
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
