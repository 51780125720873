import { Empty } from "antd";
import { useFavourireGames } from "apis/games/list-favourite";
import ItemGameProvider from "components/ItemGameProvider";
import { useTranslation } from "react-i18next";

function Favourites() {
  const { t } = useTranslation("translation");
  const { data } = useFavourireGames();
  return (
    <div className="w-full flex flex-col">
      {!data?.length ? (
        <div className="w-full flex items-center justify-center">
          <Empty description={t("noRecords")} className="!text-white" />
        </div>
      ) : (
        <div className="grid gap-4 grid-cols-3 xl:grid-cols-4">
          {data.map((item, index) => {
            return (
              <ItemGameProvider item={item} key={index}></ItemGameProvider>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Favourites;
