import { Spin } from "antd";
import { useGetGameList } from "apis/games";
import { useStuckPoint } from "apis/games/StuckPoint";
import { useStuckPointQuit } from "apis/games/StuckPointQuit";
import { SubGameItemProps } from "apis/games/type";
import IconMaintenance from "assets/icons/icon-maintenance.svg";
import clsx from "clsx";
import LoadingCustom from "components/LoadingCustom";
import ModalNotification from "components/ModalNotification";
import { PATH } from "constants/path";
import { ProvidersGame } from "constants/providers-game";
import { createSearchParams } from "helper/createSearchParams";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useAppStore } from "stores/app";

interface Props {
  gameType?: string;
}
function GridGameContent({ gameType }: Props) {
  const { data } = useStuckPoint();
  const { mutate, isPending: pendingQuitGame } = useStuckPointQuit();
  const naviagte = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [msgNotification, setMsgNotification] = useState<string>("");
  const { isAuthenticated } = useAppStore();
  const { data: dataGameList, isPending } = useGetGameList("");
  // Mapping "SL" to "ARCADE"
  const typeMapping: Record<string, string> = {
    ARCADE: "SL",
  };

  const handleOnClickGame = (
    item: SubGameItemProps,
    isCommingSoon: boolean,
    isMaintenance?: boolean,
    isQuitGame?: boolean
  ) => {
    if (isQuitGame) {
      return mutate({
        username: localStorage.getItem("username") + "",
        provider: item.provider_code + "",
      });
    }
    if (data && !Array.isArray(data)) {
      setOpenModal(true);
      setMsgNotification(
        "You are currently in a game, please close the game before starting a new game!"
      );
      return;
    }
    if (isCommingSoon || isMaintenance) {
      setOpenModal(true);
      setMsgNotification(
        isCommingSoon ? "Coming soon" : isMaintenance ? "Game Maintenance" : ""
      );
      return;
    }
    if (!isAuthenticated) return naviagte(PATH.login);
    if (item.isClose) {
      return naviagte(PATH.playingChips + "?" + createSearchParams(item));
    }
    if (item.type === "SL" || item.type === "FH" || item.type === "BG") {
      return naviagte(`/games/${item.type}/${item.provider_code}`);
    }
    return naviagte(PATH.playingMainPoint + "?" + createSearchParams(item));
  };

  return (
    <>
      <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3">
        {!isPending &&
          ProvidersGame.sort((a, b) => {
            const getPriority = (
              isQuitGame: boolean,
              isMaintenance?: boolean,
              isGamePlaying?: boolean
            ) => {
              if (isQuitGame) return 1;
              if (isGamePlaying && !isMaintenance) return 2;
              if (isMaintenance && !isGamePlaying) return 3;
              if (isMaintenance && isGamePlaying) return 4;
              return 5;
            };
            const getGamePlaying = (item: SubGameItemProps) => {
              const normalizedType = item.type
                ? typeMapping[item.type] || item.type
                : "";
              const isGamePlaying = dataGameList?.some(
                (f) =>
                  f.provider_code === item.provider_code &&
                  f.game_type_code.includes(normalizedType + "")
              );
              return isGamePlaying || false;
            };
            return (
              getPriority(
                a.provider_code === data?.provider && (data?.game_type_code ? a.type === data?.game_type_code : true),
                a.isMaintenance || false,
                getGamePlaying(a)
              ) -
              getPriority(
                b.provider_code === data?.provider,
                b.isMaintenance || false,
                getGamePlaying(b)
              )
            );
          }).map((item, index) => {
            if (gameType && item.type !== gameType) return null;

            // Nếu không có trong mapping thì giữ nguyên
            const normalizedType = item.type
              ? typeMapping[item.type] || item.type
              : "";

            const isGamePlaying = dataGameList?.some(
              (f) =>
                f.provider_code === item.provider_code &&
                f.game_type_code.includes(normalizedType + "")
            );
            const isQuitGame = data && item.provider_code === data.provider && (data.game_type_code ? data.game_type_code === item.type : true);

            if (item.isMaintenance) return; // hidden maintanance item game

            return (
              <div
                onClick={() =>
                  handleOnClickGame(
                    item,
                    !isGamePlaying,
                    item.isMaintenance,
                    isQuitGame
                  )
                }
                className="w-full shadow-md relative"
                key={index}
              >
                {!isQuitGame && item.isMaintenance && (
                  <div className="absolute z-[1] left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center flex-col">
                    <ReactSVG
                      src={IconMaintenance}
                      className="text-white text-center w-[30px] h-[30px]"
                    />
                    <span className="text-[12px] font-semibold text-white px-2 py-1">
                      Maintain
                    </span>
                  </div>
                )}
                {isQuitGame && !item.isMaintenance && (
                  <div className="absolute z-[1] w-full left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center flex-col">
                    {pendingQuitGame ? (
                      <Spin size="small" />
                    ) : (
                      <img src="/images/playing-game.png" className="w-8 h-8" />
                    )}
                    <span className="text-[12px] font-semibold text-white px-2 py-1 w-full text-center">
                      Quit Game
                    </span>
                  </div>
                )}
                {item.picture ? (
                  <img
                    src={item.picture}
                    className={clsx(
                      !isGamePlaying && "grayscale",
                      (item.isMaintenance || isQuitGame) && "brightness-[0.4]"
                    )}
                  />
                ) : (
                  <div
                    className={clsx(
                      "bg-[#353535] rounded-md flex flex-col items-center justify-center w-[117px] h-[99px] text-xs"
                    )}
                  >
                    <span>Image Found</span>
                    <span>{item.provider_code}</span>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {isPending && <LoadingCustom center height={400} />}
      <ModalNotification
        open={openModal}
        onClose={() => setOpenModal(false)}
        childrend={
          <div className="text-[20px] font-bold uppercase text-[#e9b336]">
            {msgNotification}
          </div>
        }
        bodyClassName="h-[150px] flex items-center justify-center"
      />
    </>
  );
}

export default GridGameContent;
