import {
  FormMemberBankProps,
  useAddMemberBank,
  useBankList,
  useMemberBank,
} from "apis/bank";
import HeaderTab from "components/HeaderTab";
import { useTranslation } from "react-i18next";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { useMemberDetail } from "apis/auth/member-detail";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useEffect, useState } from "react";
import { message, Spin } from "antd";
import { useRequestOTP } from "apis/auth/forgot-password";

const defaultValuesForm: FormMemberBankProps = {
  id: "",
  account_no: "",
  account_name: "",
  otp: "",
};

function AddMemberBank() {
  const { t } = useTranslation("add-member-bank");
  const { data: listBank } = useBankList();
  const { data: memberBank, refetch } = useMemberBank();
  const { data: profile } = useMemberDetail();
  const [otpTime, setOTPTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (otpTime) {
      interval.current = setInterval(() => {
        setOTPTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [otpTime]);
  const schema = yup
    .object({
      id: yup.string().required(t("bamkEmpty")),
      account_no: yup.string().required(t("accountNumberEmpty")),
      account_name: yup.string().required(t("accountNameEmpty")),
      otp: yup.string().required(t("otpEmpty")),
    })
    .required();

  const { register, handleSubmit, reset, setValue, } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValuesForm,
  });

  const { mutate: requestOTPAPI } = useRequestOTP();

  const sendOTP = async () => {
    if (!profile) return;
    const { mobile, username } = profile;
    setOTPTime(60);
    requestOTPAPI({
      username: username,
      mobile: `${mobile}`,
      type: 6,
    });
  };

  useEffect(() => {
    if (profile) {
      setValue("account_name", profile.full_name || "");
    }
  }, [profile]);

  const { mutate, isPending } = useAddMemberBank({
    onSuccess: () => {
      reset();
      refetch();
    },
  });

  const onSubmit: SubmitHandler<FormMemberBankProps> = (data) => {
    mutate({ ...data, mobile: profile?.mobile });
  };

  const onError: SubmitErrorHandler<FormMemberBankProps> = (errors) => {
    if (errors.id?.message) {
      return message.error(errors.id?.message);
    }
    if (errors.account_no?.message) {
      return message.error(errors.account_no?.message);
    }
    if (errors.account_name?.message) {
      return message.error(errors.account_name?.message);
    }
    if (errors.otp?.message) {
      return message.error(errors.otp?.message);
    }
    if (errors.root?.message) {
      return message.error(errors.root?.message);
    }

  };

  return (
    <div className="flex flex-col w-full">
      <HeaderTab title={t("addMemberBank")} backUrl="home" />
      <div className="flex flex-col p-4">
        <div className="grid grid-cols-2 gap-4 flex-row flex-wrap">
          {memberBank?.data.map((item, index) => {
            return (
              <div
                key={index}
                className="flex p-4 bg-slate-900 flex-col shadow-md rounded-lg"
              >
                <h3 className="text-lg font-semibold text-orange-300">
                  {item.bank_name}
                </h3>
                <p className="text-sm text-orange-200">
                  {item.bank_account_no}
                </p>
              </div>
            );
          })}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className="flex flex-col w-full mt-8"
        >
          <div className="flex flex-col">
            <label className="label-form">
              <span>{t("selectBank")}</span>
            </label>
            <select
              className="bg-black text-orange-300 outline-none h-10 mt-2 px-2 border-b border-orange-300"
              {...register("id")}
            >
              <option value={""} disabled>
                -- {t("pleaseSelect")} --
              </option>
              {listBank?.data.map((item, index) => {
                return (
                  <option value={item.bank_id} key={index}>
                    {item.bank_name}
                  </option>
                );
              })}
            </select>
            <div className="flex flex-col mt-4">
              <label className="label-form">
                <span>{t("AccountHolderName")}</span>
              </label>
              <input
                className="h-10 bg-black mt-2 px-2 text-orange-300 outline-none border-b border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
                readOnly={!!profile?.full_name}
                placeholder={t("AccountHolderName")}
                {...register("account_name")}
              />
            </div>

            <div className="flex flex-col mt-4">
              <label className="label-form">
                <span>{t("AccountNumber")}</span>
              </label>
              <input
                placeholder={t("AccountNumber")}
                className="h-10 bg-black mt-2 px-2 text-orange-300 outline-none border-b border-orange-300 placeholder:text-orange-300 placeholder:opacity-80"
                {...register("account_no")}
              />
            </div>
            <div className="flex flex-col mt-8">
              <label className="label-form">
                <span>{t("otp")}</span>
              </label>
              <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
                <input
                  {...register("otp")}
                  placeholder={t("otp")}
                  type={"text"}
                  className="bg-transparent outline-none flex-grow placeholder-yellow-400"
                />
                <div
                  className={`w-32 text-center flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer ${otpTime ? 'opacity-70 pointer-events-none' : ''}`}
                  onClick={sendOTP}
                >
                  {otpTime ? otpTime : t("requestOTP")}
                </div>
              </div>
            </div>
            <div className="flex mt-6 items-center justify-center">
              <button
                type="submit"
                className="bg-orange-400 px-6 py-2 rounded-xl"
              >
                {isPending ? <Spin size="small" /> : t("Submit")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddMemberBank;
