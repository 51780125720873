import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useGetPlayerWallets } from "./GetPlayerWallets";

export interface WithdrawGameBalanceProps {
  provider: string;
  amount: string;
}

export interface SuccessProps {
  onSuccess: () => void;
}
export const useWithdrawGameBalance = ({ onSuccess }: SuccessProps) => {
  const { refetch: refetchPlayer } = useGetPlayerWallets();
  return useMutation({
    mutationKey: ["WithdrawGameBalance"],
    mutationFn: async (payload: WithdrawGameBalanceProps) => {
      const res = await fetchAPI(
        { username: localStorage.getItem("username"), ...payload },
        "WithdrawGameBalance",
        localStorage.getItem("username"),
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: (data) => {
      refetchPlayer();
      message.success(data.msg);
      onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
