import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "..";

export interface BannerItemProps {
  banner_id: string;
  banner_name: string;
  web_banner: string;
  web_banner_alt: string;
  mobile_banner: string;
  banner_url: string;
  banner_sequence: string;
  icon: string;
}

export const useBanner = () => {
  return useQuery({
    queryKey: ["Banner"],
    queryFn: async () => {
      const res = await fetchAPI<BannerItemProps[]>({}, "Banner", null);
      if (!res.status) {
        return res.data;
      }
    },
  });
};
