import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface StuckPointRoot {
  provider: string;
  provider_name: string;
  balance: string;
  game_type_code: string;
}

export const useStuckPoint = () => {

  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["StuckPoint", username],
    enabled: isAuth,
    queryFn: async () => {
      const res = await fetchAPI<StuckPointRoot>(
        { page: 1, size: 1000, username: username },
        "StuckPoint",
        username
      );
      return res.data;
    },
  });
};
