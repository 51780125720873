/* eslint-disable @typescript-eslint/no-explicit-any */

import { Friend, useFriendList } from "apis/friends/list-friend";
import { PATH } from "constants/path";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ListMenuDefault } from "./value";

interface GroupedData {
  firstChar: string;
  childs: Friend[];
}

function ContactPage() {
  const { t } = useTranslation("contact");
  const navigate = useNavigate();
  const { data: dataFriend } = useFriendList();
  const [data, setData] = useState<Friend[]>([]);
  const [keyword, setKeyword] = useState("");
  const timeRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (!dataFriend?.list.friend) return;
    setData(dataFriend?.list.friend);
  }, [dataFriend]);

  const handleKeyword = (value: string) => {
    setKeyword(value);
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
    timeRef.current = setTimeout(() => {
      searchDataArray(value);
    }, 1000);
  };

  const searchDataArray = (value: string) => {
    if (!dataFriend) return;
    const newData = dataFriend.list.friend.filter((i) => {
      if (i.friend_username.includes(value)) {
        return true;
      }
      return false;
    });
    setData(newData);
  };

  const groupData = useMemo(() => {
    const array: GroupedData[] = [];
    if (data.length) {
      data.forEach((item) => {
        const firstChar = item.friend_username.charAt(0).toLowerCase();
        if (!array.find((i) => i.firstChar === firstChar)) {
          const childs = data.filter((i) => {
            if (i.friend_username.charAt(0).toLowerCase() === firstChar)
              return true;
            return false;
          });
          array.push({
            firstChar: firstChar,
            childs: childs || [],
          });
        }
      });
    }
    return array;
  }, [data]);

  return (
    <div className="flex flex-col w-full py-2 relative">
      <div className="h-screen overflow-auto">
        <div className="sticky top-0 left-0 w-full bg-black pb-2">
          <h3 className="text-xl px-4 font-semibold text-orange-300">
            {t("friends")}
          </h3>
          <div className="flex items-center bg-slate-900 mt-4 px-2 rounded">
            <svg
              className="w-4 mx-2 fill-orange-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>
            <input
              placeholder={t("search")}
              className="flex-1 h-10 bg-inherit pl-2 outline-none"
              value={keyword}
              onChange={(e) => handleKeyword(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col mt-2 px-4">
          {ListMenuDefault.map((item, index) => {
            return (
              <div
                onClick={() => {
                  if (item.url.includes("http")) {
                    return window.open(item.url);
                  }
                  navigate(item.url);
                }}
                key={index}
                className="flex items-center py-2 border-b border-gray-800"
              >
                <img src={item.icon} className="w-10 rounded-full" />
                <span className="text-sm ml-4">{t(`menu.${item.code}`)}</span>
              </div>
            );
          })}
        </div>
        {groupData.map((item, index) => {
          return (
            <div key={index} className="flex flex-col mt-4">
              <div className="bg-gray-500 px-4 py-1">
                <span className="text-lg font-bold uppercase">
                  {item.firstChar}
                </span>
              </div>
              <div className="flex flex-col px-4">
                {item.childs.map((i, e) => {
                  return (
                    <div
                      key={e}
                      onClick={() => {
                        navigate(PATH.contact + "/detail/" + i.friend_username);
                      }}
                      className="flex items-center py-4 border-b border-gray-800"
                    >
                      <img
                        src={i.avatar}
                        className="w-10 h-10 rounded-full bg-slate-600"
                      />
                      <span className="text-sm ml-4">{i.friend_username}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}

        <div className="h-32" />
      </div>
      {/* <div className="absolute right-1 h-screen overflow-auto flex flex-col gap-2 items-center justify-center">
          {["J", "P"].map((item, index) => {
            const active = !index;
            return (
              <div
                key={index}
                className={`w-6 h-6 flex items-center justify-center rounded-full ${
                  active && "bg-blue-600"
                }`}
              >
                <span>{item}</span>
              </div>
            );
          })}
        </div> */}
    </div>
  );
}

export default ContactPage;
