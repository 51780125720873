import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";

export interface FormForgotPasswordProps {
  username: string;
  email?: string;
  mobile: string;
}

export interface FormRequestOTPProps {
  username: string;
  mobile: string;
  type: number;
}

export const useForgotPasswordForm = (callback: () => void) => {
  return useMutation({
    mutationKey: ["MemberForgotPassword"],
    mutationFn: async (payload: FormForgotPasswordProps) => {
      const res = await fetchAPI(
        {
          ...payload,
        },
        "ForgotPassword",
        payload.username,
      );
      message.success(res.msg || "");
      const password = res.msg?.split(":")[1]?.trim() || "";
      setTimeout(() => {
        const newPasswordElement = document.getElementById("new-password");
        if (newPasswordElement) {
          (newPasswordElement as HTMLInputElement).value = password;
        }
      }, 100);
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return payload;
    },
    onSuccess: () => {
      callback();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};

// const removePrefix = (input: string): string => {
//   const prefixRegex = new RegExp(
//     `^(${MOBILE_PREFIX.map((m) => m.code.replace("+", "")).join("|")})`,
//   );
//   return input.replace(prefixRegex, "");
// };

export const useRequestOTP = () => {
  return useMutation({
    mutationKey: ["MemberRequestOTP"],
    mutationFn: async (payload: FormRequestOTPProps) => {
      // const mobileWithoutPrefix = removePrefix(payload.mobile);
      // if (mobileWithoutPrefix.length < 7 || mobileWithoutPrefix.length > 11) {
      //   return;
      // }
      const res = await fetchAPI(
        {
          ...payload,
        },
        "RequestOTP",
        payload.username,
      );
      message.success(res.msg);
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return payload;
    },
    onSuccess: () => {},
    onError: (err) => {
      message.error(err + "");
    },
  });
};
