import { useMutation } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useStuckPoint } from "./StuckPoint";
import { message } from "antd";

interface StuckPointQuitProps {
  username: string;
  provider: string;
  
}
export const useStuckPointQuit = () => {
  const { refetch } = useStuckPoint();
  return useMutation({
    mutationKey: ["StuckPointQuit"],
    mutationFn: async (payload: StuckPointQuitProps) => {
      const res = await fetchAPI(
        { ...payload },
        "StuckPointQuit",
        payload.username
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: (data) => {
      refetch();
      message.success(data.msg)
    },
    onError: (err) => {
      message.destroy();
      message.error(err + "");
    },
  });
};
