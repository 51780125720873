import { useGetAgentReport } from 'apis/referral'
import LoadingCustom from 'components/LoadingCustom';
import React from 'react'
import { useTranslation } from 'react-i18next';

const AgentReport = () => {
  const { t } = useTranslation("referral")
  const { data: dataAgentReport, isLoading } = useGetAgentReport();

  return (
    isLoading ? (
      <LoadingCustom center height={400} />
    ) : (

      <div className='relative'>
        <h2 className='text-[#EDCD90] font-semibold text-center text-lg mb-4'>{t("agentReport.AgentReport")}</h2>
        <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
          <div className='flex items-center justify-between gap-2 text-[#E3B85D] border-b border-[#EDCD90] py-3'>
            <span className='text-[14px]'>{t("agentReport.CurrentDirect")}</span>
            <span className='font-semibold text-[#EDCD90]'>{dataAgentReport?.direct_perfor}</span>
          </div>
          <div className='flex items-center justify-between gap-2 text-[#E3B85D] py-3'>
            <span className='text-[14px]'>{t("agentReport.CurrentTeam")}</span>
            <span className='font-semibold text-[#EDCD90]'>{dataAgentReport?.team_perfor}</span>
          </div>
        </div>
        <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
          <div className='flex items-center justify-between gap-2 text-[#E3B85D] border-b border-[#EDCD90] py-3'>
            <span className='text-[14px]'>{t("agentReport.CurrentTotal")}</span>
            <span className='font-semibold text-[#EDCD90]'>{dataAgentReport?.total_perfor}</span>
          </div>
          <div className='flex items-center justify-between gap-2 text-[#E3B85D] py-3'>
            <span className='text-[14px]'>{t("agentReport.LastCommission")}</span>
            <span className='font-semibold text-[#EDCD90]'>{dataAgentReport?.last_comm}</span>
          </div>
        </div>
        <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3'>
          <div className='flex items-center justify-between gap-2 text-[#E3B85D] py-3'>
            <span className='text-[14px]'>{t("agentReport.TotalHistorical")}</span>
            <span className='font-semibold text-[#EDCD90]'>{dataAgentReport?.total_comm}</span>
          </div>
        </div>
      </div>
    )
  )
}

export default AgentReport