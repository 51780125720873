import { Spin } from "antd";
import { useFriendDetail } from "apis/friends/friend-detail";
import { useFriendUpdate } from "apis/friends/update-friend";
import HeaderTab from "components/HeaderTab";
import { useModalConfirm } from "components/ModalConfirm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ModalTransfer from "./modal-transsfer";

function FriendDetail() {
  const { contextHolder, modalConfig } = useModalConfirm();
  const { t } = useTranslation("contact");
  const { code } = useParams();
  const { data, isLoading, refetch } = useFriendDetail(code + "");
  const { mutateAsync } = useFriendUpdate();
  const [open, setOpen] = useState(false);

  if (isLoading) {
    return (
      <div>
        <HeaderTab title={t("Detail")} />
        <div className="flex items-center justify-center py-4">
          <Spin />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col">
      {contextHolder}
      <HeaderTab title={t("Detail")} />
      <div className="px-4 bg-slate-900 flex items-center mt-4 py-4 border-b border-orange-300">
        <img src={data?.avatar} className="w-12 h-12 rounded-full" />
        <div className="flex flex-col ml-6 flex-1">
          <p className="text-lg font-semibold text-orange-300">
            {data?.full_name}
          </p>
          <p className="text-xs text-orange-300">UID: {data?.username}</p>
        </div>
      </div>
      <div className="px-4 bg-slate-900">
        <div className="flex flex-col py-2">
          <p className="text-sm font-semibold text-orange-300">{t("mobile")}</p>
          <p className="text-sm mt-2">{data?.mobile}</p>
        </div>
        <div className="flex flex-col py-2">
          <p className="text-sm font-semibold text-orange-300">{t("email")}</p>
          <p className="text-sm mt-2">{data?.email}</p>
        </div>
        <div className="flex flex-col py-2">
          <p className="text-sm font-semibold text-orange-300">{t("birth")}</p>
          <p className="text-sm mt-2">2002-2-22</p>
        </div>
      </div>
      {/* add friend  */}
      {data?.is_friend_added !== 1 ? (
        <div
          onClick={async () => {
            await mutateAsync({
              username: localStorage.getItem("username") + "",
              update_type: "add",
              friend_username: data?.username + "",
              remark: `${localStorage.getItem(
                "username",
              )} sent a friend request`,
            });
            setTimeout(() => {
              refetch();
            }, 1000);
          }}
          className="mt-8 bg-slate-900 flex justify-center py-4 items-center"
        >
          <svg
            className="w-6 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM504 312l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
          </svg>
          <span className="ml-2 font-semibold">{t("addFriend")}</span>
        </div>
      ) : null}
      {data && data.is_friend_added === 1 && data.friend_status === "0" ? (
        <div
          onClick={async () => {
            modalConfig({
              title: t("Cancelrequest"),
              content: t("CancelrequestMsg"),
              onSuccess: async () => {
                await mutateAsync({
                  username: localStorage.getItem("username") + "",
                  update_type: "cancel",
                  friend_username: data.username + "",
                  remark: `${localStorage.getItem(
                    "username",
                  )} canceled sending friend request`,
                });
                setTimeout(() => {
                  refetch();
                }, 1000);
              },
            });
          }}
          className="mt-8 bg-slate-900 flex justify-center py-4 items-center"
        >
          <svg
            className="w-8 fill-orange-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>
          <span className="ml-2 text-orange-300 font-semibold">
            {t("Cancelrequest")}
          </span>
        </div>
      ) : null}
      {data && data.is_friend_added === 1 && data.friend_status === "1" ? (
        <>
          <div
            onClick={() => setOpen(true)}
            className="mt-8 bg-slate-900 flex justify-center py-4 items-center"
          >
            <svg
              className="w-6 fill-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64l241.9 0c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5 608 384c0 35.3-28.7 64-64 64l-241.9 0c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5 32 128c0-35.3 28.7-64 64-64zm64 64l-64 0 0 64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64l64 0 0-64zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
            </svg>
            <span className="ml-2 text-blue-500 font-semibold">
              {t("transfer")}
            </span>
          </div>
          <ModalTransfer open={open} onClose={() => setOpen(false)} />
          <div
            onClick={() => {
              modalConfig({
                title: t("deleteFriend"),
                content: t("deleteMsg"),
                onSuccess: async () => {
                  await mutateAsync({
                    username: localStorage.getItem("username") + "",
                    update_type: "delete",
                    friend_username: data?.username + "",
                    remark: `${localStorage.getItem(
                      "username",
                    )} have removed you from my friends list`,
                  });
                  setTimeout(() => {
                    refetch();
                  }, 1000);
                },
              });
            }}
            className="mt-2 bg-slate-900 flex justify-center py-4 items-center"
          >
            <svg
              className="w-6 fill-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
            </svg>
            <span className="ml-2 text-red-500 font-semibold">
              {t("deleteFriend")}
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default FriendDetail;
