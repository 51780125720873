import { PATH } from "constants/path";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppStore } from "stores/app";

function UserLayout() {
  const { isAuthenticated } = useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (isAuthenticated) return;
      navigate(PATH.login, {
        replace: true,
      });
    };
    checkAuthentication();
  }, [isAuthenticated]);

  return <Outlet />;
}

export default UserLayout;
