import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface FriendSearchResponse {
  list: List[];
}

export interface List {
  player_id: string;
  username: string;
  full_name: string;
  mobile: string;
  email: string;
  avatar: string;
}

export const useFriendSearch = (keyword: string) => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["FriendSearch", keyword],
    queryFn: async () => {
      const res = await fetchAPI<FriendSearchResponse>(
        { username: username, keyword },
        "FriendSearch",
        username,
      );
      return res.data;
    },
    enabled: isAuth && !!keyword,
  });
};
