import { vipApi } from "apis/vip";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RankingType, UserTurnOver } from "types/vip";
import LoadingCustom from "components/LoadingCustom";

interface Props {
  className?: string;
  clickOpenModal?: boolean;
  selectedTab: number;
}

const CardAuthenVip = ({ className = "", selectedTab }: Props) => {
  const { t } = useTranslation("ranking");
  const username = localStorage.getItem("username") || "";
  const [loading, setLoading] = useState(false);
  const [listRanking, setListRanking] = useState<RankingType[]>([]);
  const [userTurnover, setUserTurnover] = useState<UserTurnOver>();

  const fetchLevelProgress = async () => {
    try {
      setLoading(true);
      const res = selectedTab === 1 ? await vipApi.getRankingMonthly({ username }) : await vipApi.getRankingWeekly({ username });
      if (res?.data) {
        setListRanking(res.data.data);
      }
    } catch (err) {
      throw new Error((err as Error).message || "error get Data");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserTurnover = async () => {
    try {
      setLoading(true);
      const res = await vipApi.getUserTurnOver({ username });

      if (res?.data) {
        setUserTurnover(res.data.data);
      }
    } catch (err) {
      throw new Error((err as Error).message || "error get Data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLevelProgress();
    fetchUserTurnover();
  }, [selectedTab]);

  return (
    <div className={clsx(className, "flex flex-col items-center w-full h-full max-w-[390px] mx-auto overflow-auto")}>
      {loading ? (
        <LoadingCustom height={400} center />
      ) : (
        <div className="max-w-xl mx-auto mt-4">
          <div className="flex justify-between text-xs text-[#EDCD90] text-center">
            <div className="flex-1 text-left">{t("UserName")}:</div>
            {selectedTab === 0 ? <div className="flex-1">{t("WeeklyTurnover")}:</div>
              : <div className="flex-1">{t("TotalTurnover")}:</div>}
          </div>
          <div className="flex justify-between text-[#EDCD90] text-xs mb-4 text-center">
            <div className="flex-1 text-left">{username}</div>
            {selectedTab === 0 ? <div className="flex-1">{userTurnover?.weekly_turnover || 0}</div> :
              <div className="flex-1">{(selectedTab === 0 ? userTurnover?.weekly_turnover : userTurnover?.monthly_turnover) || 0}</div>}
          </div>
          <h1 className="text-center text-lg font-bold mb-4 text-[#EDCD90]">{t(selectedTab === 0 ? "weeklyRanking" : "total")}</h1>
          <table className="w-full border-collapse border border-gray-300 text-center bg-[#655746] text-xs">
            <thead className="bg-gray-100">
              <tr className="bg-[#FBDA85] text-black">
                <th className="border border-gray-300 px-2 py-2">{t("Ranking")}</th>
                <th className="border border-gray-300 px-2 py-2">{t("PlayerID")}</th>
                {selectedTab === 0 ? <th className="border border-gray-300 px-2 py-2">{t("WeeklyTurnover")}</th> :
                  <th className="border border-gray-300 px-2 py-2">{t("TotalTurnover")}</th>}
              </tr>
            </thead>
            <tbody>
              {listRanking.map((player) => (
                <tr key={player.ranking} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2 text-center">{player.ranking}</td>
                  <td className="border border-gray-300 px-4 py-2 text-center">{player.username}</td>
                  {/* <td className="border border-gray-300 px-4 py-2 text-center">{player.total_turnover || 0}</td> */}
                  {selectedTab === 0 ? <td className="border border-gray-300 px-4 py-2 text-center">{player.weekly_turnover || 0}</td> :
                    <td className="border border-gray-300 px-4 py-2 text-center">{player.monthly_turnover || 0}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
      }
    </div >
  );
};
export default CardAuthenVip;
