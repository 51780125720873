import { PATH } from "constants/path";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import IconChevronRight from 'assets/icons/icon-chevron-right.svg'
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation("register");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === PATH.topup) {
      navigate(PATH.topup);
    }
  }, [pathname]);

  return <div className="bg-linearBlack w-full h-full overflow-hidden">
    <div className='relative h-[52px] flex items-center justify-center gap-2'>
      <button
        className='w-[52px] h-full flex items-center justify-center absolute top-0 left-0'
        onClick={() => navigate("/login")}
      >
        <img src={IconChevronRight} alt='...' />
      </button>
      <h3 className='text-[#EDCD90] font-medium text-[18px]'>{t("forgotPassword")}</h3>
    </div>
    <div className="w-full bg-[#E3B85D] text-black text-sm p-2 text-center">Please verify by OTP for retrieving UID</div>
    <ForgotPasswordForm />
  </div>
}
export default ForgotPassword;
