import { PATH } from "constants/path";
import BottomTabLayout from "layout/BottomTabLayout";
import DefaultLayout from "layout/DefaultLayout";
import UserLayout from "layout/UserLayout";
import AddMemberBank from "pages/AddMemberBank";
import ContactPage from "pages/ContactPage";
import ForgotPassword from "pages/ForgotPassword";
import HomePage from "pages/HomePage";
import Login from "pages/Login";
import ProfilePage from "pages/ProfilePage";
import TopUpPage from "pages/TopUpPage";
import ReferralPage from "pages/ReferralPage";
import Register from "pages/Register";
import Setting from "pages/Setting";
import SubGamePage from "pages/SubGamePage";
import WithdrawalPage from "pages/WithdrawalPage";
import { RouteObject } from "react-router-dom";
import PromotionPage from "pages/PromotionPage";
import BettingRecord from "pages/PlayerTransactionsPage/BettingRecords";
import ChangePassword from "pages/ChangePassword";
import RankingPage from "pages/RankingPage";
import RankingPage1 from "pages/VIPRankingPage";
import InfomationPage from "pages/InfomationPage";
import PlayerTransactionsPage from "pages/PlayerTransactionsPage";
import TransactionHistory from "pages/PlayerTransactionsPage/TransactionPage";
import MissionPage from "pages/MissionPage";
import CollectionRecord from "pages/ReferralPage/CollectionRecord";
import NewFriends from "pages/ContactPage/new-friends";
import FriendDetail from "pages/ContactPage/friend-detail";
import ShowQRCode from "pages/ReferralPage/ShowQRCode";
import PlayingGameControlsChips from "pages/PlayingGameControlsChips";
import PointInOutRecords from "pages/PlayerTransactionsPage/PointInOutRecords";
import RewardRecords from "pages/PlayerTransactionsPage/RewardRecords";
import PlayingGameControlsMainPoint from "pages/PlayingGameControlsMainPoint";
import RebateRecords from "pages/PlayerTransactionsPage/RebateRecords";
import DownloadApp from "pages/DownloadApp";
import LayoutNotAuth from "layout/LayoutNotAuth";
import HelpDesk from "pages/HelpDesk";

const routesConfig: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        element: <BottomTabLayout />,
        children: [
          {
            element: <HomePage />,
            path: PATH.home,
          },
          {
            element: <UserLayout />,
            children: [
              {
                element: <Setting />,
                path: PATH.me.index,
              },
              {
                element: <ContactPage />,
                path: PATH.contact,
              },
            ],
          },
        ],
      },
      {
        element: <UserLayout />,
        children: [
          {
            element: <NewFriends />,
            path: PATH.contact + "/new",
          },
          {
            element: <FriendDetail />,
            path: PATH.contact + "/detail/:code",
          },
          {
            element: <WithdrawalPage />,
            path: PATH.withdrawal,
          },
          {
            element: <AddMemberBank />,
            path: PATH.addBank,
          },
          {
            element: <ReferralPage />,
            path: PATH.referral,
          },
          {
            element: <ShowQRCode />,
            path: PATH.referral + "/qr",
          },
          {
            element: <CollectionRecord />,
            path: PATH.referral + "/records",
          },
          {
            element: <TopUpPage />,
            path: PATH.topup,
          },
          {
            element: <Setting />,
            path: PATH.me.index,
          },
          {
            element: <BettingRecord />,
            path: PATH.bettingRecord,
          },
          {
            element: <ChangePassword />,
            path: PATH.changePassword,
          },
          {
            element: <ProfilePage />,
            path: PATH.me.edit,
          },
          {
            element: <InfomationPage />,
            path: PATH.me.information,
          },
          {
            element: <PlayerTransactionsPage />,
            path: PATH.playerTransaction,
          },
          {
            element: <TransactionHistory />,
            path: PATH.transactions,
          },
          {
            element: <PlayingGameControlsChips />,
            path: PATH.playingChips,
          },
          {
            element: <PlayingGameControlsMainPoint />,
            path: PATH.playingMainPoint,
          },
          {
            element: <PointInOutRecords />,
            path: PATH.poitInOutRecords,
          },
          {
            element: <RewardRecords />,
            path: PATH.rewardRecords,
          },
          {
            element: <RebateRecords />,
            path: PATH.rebateRecords,
          },
        ],
      },
      {
        element: <LayoutNotAuth />,
        children: [
          {
            element: <Login />,
            path: PATH.login,
          },

          {
            element: <Register />,
            path: PATH.register,
          },
          {
            element: <Register />,
            path: PATH.registerReferral,
          },
        ],
      },
      {
        element: <ForgotPassword />,
        path: PATH.forgotPassword,
      },
      {
        element: <SubGamePage />,
        path: "/games/:category/:provider",
      },
      {
        element: <PromotionPage />,
        path: PATH.promotion,
      },
      {
        element: <RankingPage />,
        path: PATH.ranking,
      },
      {
        element: <RankingPage1 />,
        path: `${PATH.ranking}1`,
      },
      {
        element: <MissionPage />,
        path: PATH.mission,
      },
      {
        element: <DownloadApp />,
        path: PATH.dowloadApp,
      },
      {
        element: <HelpDesk />,
        path: PATH.helpDesk,
      },
    ],
  },
];

export default routesConfig;
