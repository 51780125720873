import { yupResolver } from "@hookform/resolvers/yup";
import { useRequestOTP } from "apis/auth/forgot-password";
import { useRegisterForm } from "apis/auth/register";
import { useGetReferralInfo } from "apis/referral";
import BottomSheet from "components/BottomSheet";
import { MOBILE_PREFIX } from "constants/mobile";
import { PATH } from "constants/path";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import referralImage from "./referal.png";
import LoadingCustom from "components/LoadingCustom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

type FormRegisterProps = {
  username: string;
  fullname: string;
  password: string;
  conpass: string;
  // email: string;
  mobile: string;
  otp: string;
};

interface Props {
  referrer: string;
}
const RegisterForm = ({ referrer }: Props) => {
  const { t } = useTranslation("register");
  const { mutate } = useRegisterForm();
  const navigate = useNavigate();
  const { mutate: requestOTPAPI } = useRequestOTP();
  const [selectedPrefix, setSelectedPrefix] = useState(MOBILE_PREFIX[0]);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    conpass: false,
  });
  const [otpTime, setOTPTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (otpTime) {
      interval.current = setInterval(() => {
        setOTPTime((prev) => prev - 1);
      }, 1000);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
    }
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [otpTime]);
  const {
    data: dataReferralInfo,
    refetch: refetchRefInfo,
    isFetching,
  } = useGetReferralInfo(referrer);

  useEffect(() => {
    refetchRefInfo();
  }, []);

  const schema = yup
    .object({
      username: yup.string().trim().lowercase().required(t("usernameEmpty")),
      fullname: yup.string().trim().lowercase().required(t("fullnameEmpty")),
      password: yup.string().trim().required(t("passwordEmpty")),
      conpass: yup
        .string()
        .trim()
        .oneOf([yup.ref("password")], t("conPassMismatch"))
        .required(t("conPassEmpty")),
      // email: yup
      //   .string()
      //   .trim()
      //   .lowercase()
      //   .required(t("emailEmpty"))
      //   .matches(EMAIL_REGEX, t("emailMatches")),
      mobile: yup
        .string()
        .trim()
        .lowercase()
        .required(t("mobileEmpty"))
        .matches(selectedPrefix.regex, t("mobileMatches")),
      otp: yup.string().trim().lowercase().required(t("otpEmpty")),
    })
    .required();

  const defaultValuesForm: FormRegisterProps = {
    username: "",
    fullname: "",
    password: "",
    conpass: "",
    // email: "",
    mobile: "",
    otp: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: defaultValuesForm,
  });

  const mobile = watch("mobile");
  const username = watch("username");

  const onSubmit: SubmitHandler<FormRegisterProps> = (data) => {
    mutate({
      ...data,
      mobile: `${selectedPrefix.code.replace("+", "")}${mobile}`,
      referral: dataReferralInfo?.username || undefined,
    });
  };
  const sendOTP = async () => {
    setOTPTime(60);
    if (!mobile || !username) {
      if (!username)
        setError("username", {
          type: "validate",
          message: t("usernameEmpty"), // Thông báo lỗi
        });
      if (!mobile)
        setError("mobile", {
          type: "validate",
          message: t("mobileEmpty"), // Thông báo lỗi
        });
      return;
    }
    requestOTPAPI({
      username: username,
      mobile: `${selectedPrefix.code.replace("+", "")}${mobile}`,
      type: 1,
    });
  };

  const togglePasswordVisibility = (field: "password" | "conpass") => {
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div className="px-2">
      <div className="mb-2 text-[#e3b85d] ">{t("referralInfo")}</div>
      <div className="flex bg-[#111111] mb-4 items-center">
        <div className="px-2">
          <img src={referralImage} alt="Logo" className="w-[64px]" />{" "}
        </div>
        <div className="flex-1">
          {referrer ? (
            <>
              <div className="pl-2 font-semibold flex items-center gap-2">
                <span className="text-[12px] font-normal text-[#B89032]">
                  UID:{" "}
                </span>
                {isFetching ? (
                  <LoadingCustom fontSize={20} className="pl-5" />
                ) : (
                  dataReferralInfo?.username
                )}
                {!dataReferralInfo?.username && !isFetching && "No data found"}
              </div>
              <div className="pl-2 font-semibold">
                <span className="text-[12px] font-normal text-[#B89032]">
                  Ref Code:{" "}
                </span>
                {referrer}
              </div>
            </>
          ) : (
            <span className="text-[14px] font-semibold">No Referral </span>
          )}
        </div>
      </div>
      <div className="mb-2 text-[#e3b85d] ">{t("registerInfo")}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-sm">
          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/user.png"} className="w-4" />
              </div>
              <span className="mx-2 text-[#e3b85d]">|</span>
              <input
                placeholder={t("username")}
                {...register("username")}
                type={"text"}
                className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="text-xs text-red-600 -mt-1 mb-2">
            {t("usernameNote")}
          </div>
          {errors?.username && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.username?.message}
            </div>
          )}
          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/user.png"} className="w-4" />
              </div>
              <span className="mx-2 text-[#e3b85d]">|</span>
              <input
                placeholder={t("fullname")}
                {...register("fullname")}
                type={"text"}
                className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
              />
            </div>
          </div>
          <div className="text-xs text-red-600 -mt-1 mb-2">{t("nameNote")}</div>
          {errors?.fullname && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.fullname?.message}
            </div>
          )}
          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/password.png"} className="w-4" />
              </div>
              <span className="mx-2 text-[#e3b85d]">|</span>
              <input
                placeholder={t("password")}
                type={showPassword.password ? "text" : "password"}
                {...register("password")}
                className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
                autoComplete="new-password"
              />
              <span
                className="flex justify-around items-center"
                onClick={() => togglePasswordVisibility("password")}
              >
                {showPassword.password ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )}
              </span>
            </div>
          </div>
          {errors?.password && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.password?.message}
            </div>
          )}

          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/password.png"} className="w-4" />
              </div>
              <span className="mx-2 text-[#e3b85d]">|</span>
              <input
                placeholder={t("confirmPassword")}
                type={showPassword.conpass ? "text" : "password"}
                {...register("conpass")}
                className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
              />
              <span
                className="flex justify-around items-center"
                onClick={() => togglePasswordVisibility("conpass")}
              >
                {showPassword.conpass ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )}
              </span>
            </div>
          </div>
          <div className="text-xs text-red-600 -mt-1 mb-2">
            {t("conPassNote")}
          </div>
          {errors?.conpass && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.conpass?.message}
            </div>
          )}

          {/* <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
              <div className="flex-shrink-0">
                <img src={"/images/email.png"} className="w-4" />
              </div>
              <span className="mx-2 text-[#e3b85d]">|</span>
              <input
                placeholder={t("email")}
                type={"text"}
                {...register("email")}
                className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
              />
            </div>
          </div>
          {errors?.email && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.email?.message}
            </div>
          )} */}

          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-[#e3b85d] w-full mb-2">
              <div className="flex-shrink-0">
                <div className="flex" onClick={() => setOpen(true)}>
                  <div>{selectedPrefix.code}</div>
                </div>
              </div>
              <span className="mx-2 text-[#e3b85d]">|</span>
              <input
                placeholder={t("mobile")}
                type={"text"}
                {...register("mobile")}
                className="bg-transparent outline-none flex-grow placeholder:text-[#e3b85d]"
              />
            </div>
          </div>
          {errors?.mobile && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.mobile?.message}
            </div>
          )}
          <div>
            <div className="flex items-center border-b border-yellow-500 p-2 bg-black text-yellow-400 w-full mb-2">
              <input
                {...register("otp")}
                placeholder={t("otp")}
                type={"text"}
                className="bg-transparent outline-none flex-grow placeholder-yellow-400"
              />
              <div
                className={`flex-shrink-0 text-black rounded bg-linearGold px-2 py-0.5 cursor-pointer w-28 text-center ${
                  otpTime ? "opacity-70 pointer-events-none" : ""
                }`}
                onClick={sendOTP}
              >
                {otpTime ? otpTime : t("requestOTP")}
              </div>
            </div>
          </div>
          {errors?.otp && (
            <div className="text-xs text-red-600 -mt-1 mb-2">
              {errors?.otp?.message}
            </div>
          )}
        </div>
        <div className="px-2 mt-8">
          <button
            type="submit"
            className="w-full text-center py-2 bg-linearGold rounded cursor-pointer text-black mb-4"
          >
            {t("submit")}
          </button>
        </div>
      </form>
      <div className="px-2">
        <div
          onClick={() => navigate(PATH.login)}
          className="text-center py-2 border border-solid border-[#EEB154] rounded cursor-pointer text-[#FFE794] mb-6"
        >
          {t("memberlogin")}
        </div>
      </div>
      <BottomSheet isOpen={open} onClose={() => setOpen(false)}>
        <div className="min-h-40">
          <div className="text-center py-3 bg-gradient-to-r from-[#FFE794] to-[#EEB154] text-orange-300 font-semibold">
            {t("Country")}
          </div>
          {MOBILE_PREFIX.map((item) => {
            return (
              <div
                key={item.code}
                className="relative py-3 border-b border-solid border-[#EEB154] text-[#FFE794] text-center bg-black"
                onClick={async () => {
                  setOpen(false);
                  setSelectedPrefix(item);
                  setFocus("mobile");
                }}
              >
                {item.code}
              </div>
            );
          })}
        </div>
      </BottomSheet>
    </div>
  );
};

export default RegisterForm;
