import axios, { AxiosInstance } from "axios";
import { payloadHelper } from "helper/payloadHelper";
import {
  ApiLoginGameResponse,
  ApiPaginationResponse,
  ApiResponse,
  ApiResponseLogin,
} from "types/api";

const axiosRequest: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    //
  },
});
export default axiosRequest;

axios.defaults.withCredentials = true;

// khi nào call api gọi cái này
async function fetchAPI<Props>(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<ApiResponse<Props>>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    if (res.data.status !== 0) {
      return Promise.reject(res.data.msg);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchAPIReturn<Props>(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<ApiResponse<Props>>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchAPIToken<Props>(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<ApiResponse<Props>>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    if (res.data.status !== 0) {
      return res;
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchAPIGame(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<ApiLoginGameResponse>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function fetchAPILogin(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<ApiResponseLogin>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    if (res.data.status !== 0) {
      return Promise.reject(res.data.msg);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// sử dụng cho cách danh sách có phân trang
async function fetchPaginationAPI<Props>(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<ApiPaginationResponse<Props>>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    if (res.data.status !== 0) {
      return Promise.reject(res.data.msg);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// hàm sử dụng cho cái api trả về ko có msg or data
async function fetchAPIGetMessage<Props>(
  payload: unknown,
  method: string,
  typeName: string | null,
) {
  try {
    const res = await axiosRequest.post<Props>(
      "/",
      payloadHelper(method, typeName, payload),
    );
    if (res.status !== 200) {
      return Promise.reject(res.statusText);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export {
  fetchAPI,
  fetchAPIGame,
  fetchAPILogin,
  fetchAPIToken,
  fetchAPIReturn,
  fetchPaginationAPI,
  fetchAPIGetMessage
};
