import React, { useEffect, useState } from "react";

type Props = {
  title: string;
};

function TimeLine({ title }: Props) {
  const [time, setTime] = useState(0);
  useEffect(() => {
    // Hàm chạy mỗi giây để tăng bộ đếm
    const interval = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);

    // Cleanup khi component bị unmount
    return () => clearInterval(interval);
  }, []);

  const formatSecondsToMinutes = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60); // Tính tổng số phút
    const remainingSeconds = seconds % 60; // Tính số giây còn lại
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds,
    ).padStart(2, "0")}`; // Định dạng mm:ss
  };

  return (
    <div className="flex flex-col">
      <span className="text-white font-bold text-sm">{title}</span>
      <span className="text-xs">{formatSecondsToMinutes(time)}</span>
    </div>
  );
}

export default TimeLine;
