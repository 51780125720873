import HeaderTab from "components/HeaderTab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InvitedFriends from "./InvitedFriends";
import PerformanceQuery from "./PerformanceQuery";
import clsx from "clsx";
import TeamManagement from "./TeamManagement";
import AgentReport from "./AgentReport";
import AgentIntro from "./AgentIntro";
import IconInviteFriend from "assets/images/referralPage/invite-friends.webp"
import IconSearch from "assets/images/referralPage/icon-search.webp"
import IconTeamManage from "assets/images/referralPage/team-management.webp"
import IconAgentReport from "assets/images/referralPage/icon-pie-report.webp"
import IconAgentIntro from "assets/images/referralPage/icon-team-user.webp"
import { Link } from "react-router-dom";

type tabType = {
  id: string
  title: string
  element: React.ReactElement
  icon: string
}

const optionTabs: tabType[] = [
  { id: "1", title: "Invite Friends", element: <InvitedFriends />, icon: IconInviteFriend },
  { id: "2", title: "Performance Query", element: <PerformanceQuery />, icon: IconSearch },
  { id: "3", title: "Team Management", element: <TeamManagement />, icon: IconTeamManage },
  { id: "4", title: "Agent Report", element: <AgentReport />, icon: IconAgentReport },
  { id: "5", title: "Agent Intro", element: <AgentIntro />, icon: IconAgentIntro },
]

function ReferralPage() {
  const { t } = useTranslation("referral");
  const [activeTab, setActiveTab] = useState<tabType>(optionTabs[0])

  return (
    <div className="w-full h-full flex flex-col bg-linearBlack overflow-hidden">
      <HeaderTab title={t("Referral")} />
      <div className="w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide pb-10">
        <div className="flex items-center justify-center md:justify-between mt-3 mb-4 mx-3 p-1 
          border border-[#4e442e] rounded-lg bg-[#E3B85D80] text-black overflow-x-auto scrollbar-hide"
        >
          {optionTabs.map(item => (
            <button
              key={item.id}
              className={clsx(
                "flex flex-col justify-center items-center gap-1 py-2 rounded-md",
                activeTab.id === item.id && "bg-linearGold",
              )}
              onClick={() => setActiveTab(item)}
            >
              <div
                style={{ maskImage: `url(${item.icon})`, maskRepeat: 'no-repeat', maskPosition: "center", maskSize: "cover" }}
                className="w-[20px] h-[20px] bg-black">
              </div>
              <span className="text-[12px] leading-[1.2] font-medium">{item.title}</span>
            </button>
          ))}
        </div>
        {activeTab.element}
        {activeTab.id !== "1" && activeTab.id !== "5" && (
          <Link
            to="/referral/qr"
            className="bg-linearGold p-2 rounded mt-7 mx-3 flex items-center justify-center"
          >
            <span className="text-black text-lg font-semibold">{t("Invitefriends")}</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default ReferralPage;
