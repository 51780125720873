import { OrderStatus, OrderType } from "enums/orders";

export const showStatusText = (key: OrderStatus) => {
  switch (key) {
    case OrderStatus.Pending:
      return "Pending";
    case OrderStatus.Approve:
      return "Approved";
    case OrderStatus.Reject:
      return "Reject";
    case OrderStatus.Processing:
      return "Processing";
    default:
      return "";
  }
};

export const showMessageStatusText = (key: OrderStatus) => {
  switch (key) {
    case OrderStatus.Pending:
      return "Pending";
    case OrderStatus.Approve:
      return "Approved";
    case OrderStatus.Reject:
      return "Cancelled";
    default:
      return "";
  }
};

export const handleColorStatus = (key: OrderStatus) => {
  switch (key) {
    case OrderStatus.Approve:
      return "#10AC02";
    case OrderStatus.Pending:
      return "#EDA10F";
    case OrderStatus.Processing:
      return "#0477FD";
    case OrderStatus.Reject:
      return "#F1212A";
    default:
      return "#00c4c4"
  }
};

export const handleColorStatusWithString = (key: string) => {
  switch (key) {
    case "Approved":
      return "#10AC02";
    case "Pending":
      return "#EDA10F";
    case "Void":
      return "#0477FD";
    case "Cancelled":
      return "#F1212A";
    default:
      return "#00c4c4"
  }
};


export const showOrderTypeText = (key: OrderType) => {
  switch (key) {
    case OrderType.DEPOSIT:
      return "Deposit";
    case OrderType.WITHDRAWAL:
      return "Withdrawal";
    case OrderType.REBATE:
      return "Rebate";
  }
}