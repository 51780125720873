import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useBalance } from "apis/auth/balance";
import { useMemberDetail } from "apis/auth/member-detail";

export const useCloseGame = () => {
  const { refetch: refetchBalance } = useBalance();
  const { refetch: refetchProfile } = useMemberDetail();
  return useMutation({
    mutationKey: ["CloseGame"],
    mutationFn: async () => {
      const res = await fetchAPI(
        { username: localStorage.getItem("username") },
        "CloseGame",
        localStorage.getItem("username"),
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: () => {
      localStorage.removeItem("data-on-game");
      refetchBalance();
      refetchProfile();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
