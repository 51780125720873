import { message } from "antd";
import { useMemberDetail } from "apis/auth/member-detail";
import { PATH } from "constants/path";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const whiteListPath = [
  PATH.home,
  PATH.withdrawal,
  PATH.referral,
  PATH.me.index,
];
function DailyAccountControls() {
  const location = useLocation();
  const { data } = useMemberDetail();
  const { t } = useTranslation("transactions");
  const navigate = useNavigate();
  useEffect(() => {
    if (!data) return;
    if (data.player_type === "5") {
      if (!whiteListPath.includes(location.pathname)) {
        navigate(PATH.home);
        message.destroy();
        message.warning(t("messageDaily"));
      }
    }
  }, [data, location]);

  return null;
}

export default DailyAccountControls;
