import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";

interface gameListType {
  provider_code: string
  provider_name: string
  game_type_code: string
}

export const useGetGameList = (payload?: string) => {
  return useQuery({
    queryKey: ["GetGameList", payload],
    queryFn: async () => {
      const res = await fetchAPI<gameListType[]>(
        { game_type: payload },
        "GetGameList",
        null,
      );
      return res.data;
    },
    staleTime: 0
  });
};
