import { SearchOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useTeamManagement } from 'apis/referral';
import LoadingCustom from 'components/LoadingCustom';
import { useTranslation } from 'react-i18next';

const TeamManagement = () => {
  const { t } = useTranslation("referral");
  const { data, isLoading } = useTeamManagement();

  return (
    <div className='relative'>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <h2 className='text-[#EDCD90] font-semibold text-center text-lg mb-4'>{t("Team Management")}</h2>
        <div className='relative w-full flex px-3 rounded-full bg-black border border-[#4e442e] focus-within:border-[#E3B85D]'>
          <input
            type="text"
            placeholder='Please enter member ID'
            className='flex-1 w-full bg-transparent h-[34px] placeholder:text-[14px] placeholder:text-[#E3B85D80]
            outline-none text-[#EDCD90]'
          />
          <button>
            <SearchOutlined className='text-[#EDCD90]' />
          </button>
        </div>
      </div>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3'>
        <table className='w-full text-[12px]'>
          <thead>
            <tr className='border-b border-[#4e442e]'>
              <td className='py-3 text-center text-[16px] font-semibold text-[#EDCD90]'>{t("Member ID")}</td>
              <td className='py-3 text-center text-[16px] font-semibold text-[#EDCD90]'>{t("Direct Performance")}</td>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, index) => (
              <tr key={index}>
                <td className='py-3 text-center text-[14px] text-[#E3B85D]'>{item.player_id}</td>
                <td className='py-3 text-center text-[14px] text-[#E3B85D]'>{item.direct_performance}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <LoadingCustom center height={400} />}
        {data && data.length === 0 && (
          <Empty description={t("Empty data")} className='my-5' />
        )}
      </div>
    </div>
  )
}

export default TeamManagement