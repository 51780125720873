import React, { useRef } from 'react'
import { message } from "antd";
import { useMemberDetail } from "apis/auth/member-detail";
import { useReferralReward } from "apis/auth/referral";
import { PATH } from "constants/path";
// import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { QRCodeCanvas } from 'qrcode.react';

const InvitedFriends = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["referral", "translation"]);
  const { data } = useMemberDetail();
  const link = window.location.origin + "/ref/" + data?.referral_code;
  const [hide, setHide] = useState(true);
  const { data: referral } = useReferralReward();
  const qrCodeRef = useRef<HTMLCanvasElement>(null);
  
  const handleDownload = (ref: string) => {
    const canvas = qrCodeRef.current;
    if (canvas) {
      const image = canvas.toDataURL("image/png"); // Chuyển canvas thành URL ảnh
      const link = document.createElement("a");
      link.href = image;
      link.download = `ref-${ref}.png`; // Tên file tải xuống
      link.click();
    } else {
      console.error("Canvas not found");
    }
  };

  return (
    <>
      <div className="bg-[#231800] mx-3 border border-[#4e442e] p-4 rounded-md flex flex-col relative">
        <h2 className="text-[#EDCD90] font-semibold text-center text-lg mb-4">{t("Invitefriends")}</h2>
        <img
          src="/images/common/coin.png"
          className=" absolute top-0 right-0 w-32"
        />
        <div className="felx flex-col mt-4">
          <p className="text-lg text-[#E3B85D]">{t("ReferralLink")}</p>
          <div className="flex items-center justify-between bg-linearGold px-2 py-1 rounded-md">
            <span className="text-black w-2/3 mr-2 text-sm truncate">
              {link}
            </span>
            <span
              onClick={() => {
                navigator.clipboard.writeText(link);
                message.success(t("message1"));
              }}
              className="text-black w-16 text-center bg-white text-sm rounded-xl"
            >
              {t("Copy")}
            </span>
          </div>
        </div>

        <div className="felx flex-col mt-2">
          <p className="text-lg text-[#E3B85D]">{t("ReferralCode")}</p>
          <div className="flex items-center justify-between bg-linearGold px-2 py-1 rounded-md">
            <span className="text-black w-2/3 mr-2 text-sm truncate">
              {data?.referral_code}
            </span>
            <span
              onClick={() => {
                if (!data?.referral_code) return;
                navigator.clipboard.writeText(data.referral_code);
                message.success(t("message2"));
              }}
              className="text-black w-16 text-center bg-white text-sm rounded-xl"
            >
              {t("Copy")}
            </span>
          </div>
        </div>
        <div className='flex flex-col gap-3 justify-center mt-5 items-center'>
          <QRCodeCanvas
            value={link || ""}
            className='border border-[#E3B85D]'
            ref={qrCodeRef}
          />
          <button
            className='bg-linearGold py-2 px-3 font-semibold rounded flex items-center justify-center text-black text-[14px]'
            onClick={() => handleDownload(data?.referral_code || "")}
          >
            Download QR Code
          </button>
        </div>
      </div>

      <div className="flex flex-nowrap p-4 mt-4 bg-[#231800] mx-3 border border-[#4e442e] rounded-md">
        <div className="flex-1 border-r border-[#E3B85D] flex items-center flex-col">
          <p className="font-bold text-lg text-[#E3B85D]">
            {referral?.direct_member}
          </p>
          <p className="text-sm text-center">{t("col1")}</p>
        </div>
        <div className="flex-1 border-r border-[#E3B85D] flex items-center flex-col">
          <p className="font-bold text-lg text-[#E3B85D]">
            {referral?.new_member_today}
          </p>
          <p className="text-sm text-center">{t("col2")}</p>
        </div>
        <div className="flex-1 border-r border-[#E3B85D] flex items-center flex-col">
          <p className="font-bold text-lg text-[#E3B85D]">
            {referral?.new_team_member_today}
          </p>
          <p className="text-sm text-center">{t("col3")}</p>
        </div>
        <div className="flex-1 flex items-center flex-col">
          <p className="font-bold text-lg text-[#E3B85D]">
            {referral?.total_member}
          </p>
          <p className="text-sm text-center">{t("col4")}</p>
        </div>
      </div>

      <div className="flexflex-col flex-nowrap p-4 mt-4 bg-[#231800] mx-3 border border-[#4e442e] rounded-md">
        <div className="flex w-full items-center justify-between">
          <h3 className="font-semibold text-lg text-[#E3B85D]">
            {t("CommissionBonus")}
          </h3>
          <p
            onClick={() => navigate(PATH.referral + "/records")}
            className="text-sm text-[#E3B85D] flex items-center"
          >
            {t("Receivingprofile")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              className="w-3 ml-2 fill-orange-300"
            >
              <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
            </svg>
          </p>
        </div>
        <div className="flex mt-2 items-center justify-between bg-linearGold p-2 rounded">
          <p className="text-black text-sm">
            {t("CurrentBonus")}(MVR): {referral?.current_bonus || "0.00"}
          </p>
          {/* <p className="text-black text-sm">{t("Notreleasedyet")}</p> */}
        </div>
      </div>
      <div className="flex flex-col flex-nowrap p-4 mt-4 bg-[#231800] mx-3 border border-[#4e442e] rounded-md">
        <h3 className="flex items-center text-[#E3B85D] font-semibold text-lg">
          <svg
            className="w-4 fill-orange-300 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
          </svg>
          {t("CommonProblems")}
        </h3>
        <div onClick={() => setHide(!hide)} className="mt-2">
          <p className="text-[#E3B85D] text-sm">{t("Howtohandle")}</p>
          <p
            className={`text-[#E3B85D] text-sm mt-1 ${hide ? "hidden" : ""}`}
          >
            {t("handleNote")}
          </p>
        </div>
      </div>
      <div
        onClick={() => navigate(PATH.referral + "/qr")}
        className="bg-linearGold p-2 rounded mt-4 mx-3 flex items-center justify-center"
      >
        <span className="text-black text-lg font-semibold">{t("Invitefriends")}</span>
      </div>

      <div className="flex flex-col px-4 py-4 mt-5 bg-[#231800] mx-3 border border-[#4e442e] rounded-md">
        <h4 className="text-sm text-[#EDCD90] font-semibold">
          {t("note.1")}
        </h4>
        <p className="text-sm mt-2">{t("note.2")}</p>
        <p className="text-sm mt-2">{t("note.3")}</p>
        <p className="text-sm mt-2">{t("note.4")}</p>
        <p className="text-sm mt-2">{t("note.5")}</p>
        <p className="text-sm mt-2">{t("note.6")}</p>
        <p className="text-sm mt-2">{t("note.7")}</p>
        <p className="text-sm mt-2">{t("note.8")}</p>
        <p className="text-sm mt-2">{t("note.9")}</p>
        <p className="text-sm mt-2">{t("note.10")}</p>
      </div>
    </>
  )
}

export default InvitedFriends