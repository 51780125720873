import { PATH } from "constants/path";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import IconDeposit from "assets/images/icon-withdrawal.png"

function HomeFunction() {
  const { t } = useTranslation(["setting"]);
  const listMenu = [
    {
      icon: "/images/Event.png",
      name: t("event"),
      link: PATH.promotion,
    },
    {
      icon: "/images/Betting Record.png",
      name: t("ranking"),
      link: PATH.ranking,
    },
    {
      icon: "/icons/settings/referral.png",
      name: t("referral"),
      link: PATH.referral,
    },
    {
      icon: "/images/Transaction Record.png",
      name: "Transactions",
      link: PATH.playerTransaction,
    },
  ];
  return (
    <div className="w-full py-3">
      <div className="grid grid-cols-6 gap-2 items-center">
        <Link
          to={PATH.topup}
          className="col-span-1 bg-[#645947] min-h-full rounded-xl flex items-center justify-center"
        >
          <img src={IconDeposit} className="w-[35px]" />
        </Link>
        <div
          className="col-span-4 bg-[#645947] flex items-start justify-between p-2 rounded-xl"
        >
          {listMenu.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.link}
                className="flex flex-col items-center justify-center w-full"
              >
                <img src={item.icon} className="w-8" />
                <span className="text-[11px] text-center global">{item.name}</span>
              </Link>
            );
          })}
        </div>
        <Link
          to={PATH.withdrawal}
          className="col-span-1 flex items-center justify-center bg-[#645947] rounded-xl min-h-full"
        >
          <img src="/images/Withdrawal.png" className="w-[35px]" />
        </Link>
      </div>
    </div>
  );
}

export default HomeFunction;
