import HeaderTab from "components/HeaderTab";
import React, { useEffect, useState } from "react";
import {
  useDepositForm,
} from "apis/deposit";
import LoadingCustom from "components/LoadingCustom";
import clsx from "clsx";
import IconSelectCheck from "assets/icons/icon-select-check.svg";
import DepositOffline from "./DepositOffline";
import { useTranslation } from "react-i18next";

const TopUpPage = () => {
  const { t } = useTranslation("deposit")
  const [selectedMethod, setSelectedMethod] = useState<number>(0);

  const { data: dataDeposit, isLoading } = useDepositForm();

  useEffect(() => {
    if (!dataDeposit) return;

    if (dataDeposit.offline_banking_channel.length > 0) {
      setSelectedMethod(0);
    } else if (dataDeposit.online_banking_channel.length > 0) {
      setSelectedMethod(Number(dataDeposit.online_banking_channel[0].id));
    } else {
      return;
    }
  }, [dataDeposit]);

  return <div className="bg-linearBlack w-full h-full overflow-hidden">
    <HeaderTab title={t("Topup")} />
    <div className="pt-4 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide">
      <div className='relative'>
        {isLoading ? (
          <LoadingCustom center height={400} />
        ) : (
          <React.Fragment>
            <div className='mb-4 flex flex-col w-full'>
              <label className='relative text-[#edcd90] px-2.5 font-medium mb-2 
            before:content-[""] before:absolute before:w-[5px] before:h-[16px] before:rounded-xl before:bg-linearGold
            before:top-[50%] before:-translate-y-[50%]
            '>
                <span className="pl-3">{t("Payment Method")}</span>
              </label>
              <div className="px-2.5 flex flex-wrap gap-2 justify-start items-center">
                {dataDeposit &&
                  dataDeposit.offline_banking_channel &&
                  dataDeposit.offline_banking_channel.length > 0 && (
                    <div
                      style={{ width: "calc(33% - 8px)" }}
                      className={clsx(
                        "text-[14px] relative h-[65px] p-2 border border-[#4e442e] rounded-md flex items-center justify-center bg-[#000000] text-[#E3B85D80] shadow-sm text-center overflow-hidden",
                        selectedMethod === 0 && "!text-[#E0BB48] !border-[#E3B85D]"
                      )}
                      onClick={() => setSelectedMethod(0)}
                    >
                      {t("TopUp Offline")}
                      {selectedMethod === 0 && <span
                        style={{ backgroundImage: `url(${IconSelectCheck})` }}
                        className="w-[20px] h-[16px] bg-center bg-cover bg-no-repeat absolute right-0 bottom-0"
                      >
                      </span>}
                    </div>
                  )}
              </div>
            </div>
            {dataDeposit &&
              dataDeposit?.offline_banking_channel.length > 0 &&
              selectedMethod === 0 && <DepositOffline />}
          </React.Fragment>
        )}
      </div>
    </div>
  </div>
}
export default TopUpPage
