import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchAPI, fetchAPIGetMessage } from "..";
import { useAppStore } from "stores/app";
import { DepositFormSettingType, DepositOfflineSubmissionForm } from "types/deposit";
import { message } from "antd";
import { ApiMessageDepositResponseType, ApiResponseGlobal } from "types/api";
import { ReceiveType } from "./type";

interface FormProps {
  onSuccess: () => void;
}

export const useDepositForm = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["DepositForm"],
    queryFn: async () => {
      const res = await fetchAPI<DepositFormSettingType>(
        { username },
        "DepositForm",
        username,
      );
      return res.data;
    },
    enabled: isAuth,
  });
};

export const useDepositOfflineSubmission = (props: FormProps) => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["Deposit"],
    mutationFn: async (payload: DepositOfflineSubmissionForm) => {
      const res = await fetchAPI<string>(
        {
          ...payload,
          username: username,
        },
        "Deposit",
        username,
      );
      if (res.status === 0) {
        return res;
      }
      return Promise.reject(res.msg);
    },
    onSuccess: () => {
      // message.success(data.msg + " : " + data.data);
      props.onSuccess && props.onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    }
  });
};

export const useGetMessageDeposit = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["checkPopupOnOffDPS", username],
    queryFn: async () => {
      const res = await fetchAPIGetMessage<ApiMessageDepositResponseType>(
        { username },
        "checkPopupOnOffDPS",
        username,
      );
      return res;
    },
    staleTime: 0,
    refetchInterval: 1000 * 30,
    enabled: isAuth,
  });
};

export const useGetReceiveDataDPS = () => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["receiveDataDPS"],
    mutationFn: async (payload: ReceiveType) => {
      const res = await fetchAPIGetMessage<ApiResponseGlobal>(
        {
          ...payload,
          username
        },
        "receiveDataDPS",
        username);
      return res;
    }
  })
};