import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useGetPlayerWallets } from "./GetPlayerWallets";

export interface DepositGameBalanceProps {
  provider: string;
  amount: string;
  game_type:string
}
export interface SuccessProps {
  onSuccess: () => void;
}
export const useDepositGameBalance = ({ onSuccess }: SuccessProps) => {
  const { refetch: refetchPlayer } = useGetPlayerWallets();
  return useMutation({
    mutationKey: ["DepositGameBalance"],
    mutationFn: async (payload: DepositGameBalanceProps) => {
      const res = await fetchAPI(
        { username: localStorage.getItem("username"), ...payload },
        "DepositGameBalance",
        localStorage.getItem("username"),
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: () => {
      onSuccess();
      setTimeout(() => {
        refetchPlayer();
      }, 1000);
    },
    onError: (err) => {
      message.destroy();
      message.error(err + "");
    },
  });
};
