import { Modal } from 'antd';
import { PromotionItemProps } from 'apis/promotions';
import React from 'react'

interface Props {
  dataItem: PromotionItemProps;
  open: boolean;
  onClose: () => void;
}

const MissionDetail = ({ dataItem, onClose, open }: Props) => {

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={false}
      modalRender={node => (
        <div className='custom-modal !bg-transparent'>{node}</div>
      )}
    >
      <div className='rounded-lg overflow-hidden bg-[#241800] text-white border border-[#E3B85D]'>
        <h3 className='bg-linearGold text-center text-[20px] font-semibold text-[#000000] py-2 px-4 pr-[40px]'>
          {dataItem.bonus}
        </h3>
        <div className='mb-4 overflow-y-auto max-h-[70vh]'>
          <div
            className='p-2 text-[14px] !text-white'
            dangerouslySetInnerHTML={{ __html: dataItem?.content.replaceAll("#212529", "white") + "" }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MissionDetail