import { DatePicker } from "antd";
import clsx from "clsx";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";

type dateType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

interface Props {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  className?: string;
  handleChangeDate: React.Dispatch<React.SetStateAction<dateType>>;
  handleApplyDate?: () => void;
}

const RangePickerCustom = ({
  endDate,
  startDate,
  className,
  handleChangeDate,
  handleApplyDate,
}: Props) => {
  const { t } = useTranslation("rangepicker")

  return (
    <div
      className={clsx(
        className,
        "flex justify-start items-end gap-2 range-custom",
      )}
    >
      <div className="w-full max-w-[300px]">
        <p className="text-[#E3B85D] text-[14px] font-semibold ">
          {t("Start Date")} *
        </p>
        <DatePicker
          value={startDate}
          className={clsx(
            "w-full rounded-sm h-[40px] bg-black border border-[#4e442e] text-[#E3B85D]",
            "placeholder:text-[#E3B85D] outline-none hover:border-[#4e442e] hover:bg-black",
          )}
          placeholder={t("placeStart")}
          onChange={(value) => {
            if (value && value.isValid()) {
              handleChangeDate((prev) => ({
                ...prev,
                startDate: dayjs(value),
              }));
            } else {
              handleChangeDate((prev) => ({
                ...prev,
                startDate: null,
              }));
            }
          }}
          disabledDate={(current) => {
            return current > dayjs().endOf("day");
          }}
          format="YYYY/MM/DD"
          allowClear={false}
        />
      </div>
      <div className="w-full max-w-[300px] ">
        <p className="text-[#E3B85D] text-[14px] font-semibold">{t("End Date")} *</p>
        <DatePicker
          value={endDate}
          className={clsx(
            "w-full rounded-sm h-[40px] bg-black border border-[#4e442e] text-[#E3B85D]",
            "placeholder:text-[#E3B85D] outline-none hover:border-[#4e442e] hover:bg-black",
          )}
          placeholder={t("placeEnd")}
          onChange={(value) => {
            if (value && value.isValid()) {
              handleChangeDate((prev) => ({
                ...prev,
                endDate: dayjs(value),
              }));
            } else {
              handleChangeDate((prev) => ({
                ...prev,
                endDate: null,
              }));
            }
          }}
          format="YYYY/MM/DD"
          disabledDate={(current) => {
            return current > dayjs().endOf("day");
          }}
          allowClear={false}
        />
      </div>
      <button
        className={clsx(
          "bg-linearGold h-[40px] rounded-md text-black px-3 text-[12px] font-semibold border border-[#4e442e]",
        )}
        onClick={() => {
          handleChangeDate({ startDate, endDate });
          handleApplyDate && handleApplyDate();
        }}
      >
        {t("Apply")}
      </button>
    </div>
  );
};

export default RangePickerCustom;
