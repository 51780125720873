import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface ProfileProps {
  player_id: string;
  username: string;
  full_name: string;
  birthday_wish: string;
  nickname: string;
  mobile: string;
  email: string;
  birth: string;
  referrer: string;
  referrer_ids: string;
  referral_code: string;
  active: string;
  wallet_lock: string;
  points: string;
  profile_group_id: string;
  bank_group_id: string;
  upline: string;
  upline_ids: string;
  login_token: string;
  avatar: string;
  deposit_status: string;
  chip_points: string;
  player_type: string;
}

export const useMemberDetail = () => {
  const isAuth = useAppStore((state) => state.isAuthenticated);
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["MemberDetail"],
    queryFn: async () => {
      const res = await fetchAPI<ProfileProps>(
        { username },
        "MemberDetail",
        username,
      );
      if (!res.status) {
        return res.data;
      }
    },
    enabled: isAuth,
  });
};
