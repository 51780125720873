import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchAPIToken } from "apis";
import { PATH } from "constants/path";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "stores/app";

export const useVerifyToken = () => {
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const setAuthentication = useAppStore((state) => state.setAuthentication);
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: ["VerifyToken", username],
    queryFn: async () => {
      const res = await fetchAPIToken(
        { username, token },
        "VerifyToken",
        username
      );
      if (res.status !== 0) {
        // logout ở đây
        localStorage.removeItem("username");
        localStorage.removeItem("token");
        navigate(PATH.login, { replace: true });
        setAuthentication(false);
        queryClient.clear();
      }
      return res;
    },
    refetchInterval: 1000 * 30,
    enabled: !!username,
  });
};
