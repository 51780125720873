import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";
export interface Root {
  game_reward_old: string;
  game_reward_new: string;
  turnover: string;
  last_turnover: string;
}

export const useLoginGameInterface = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["LoginGameInterface", username],
    enabled: isAuth,
    queryFn: async () => {
      const res = await fetchAPI<Root>(
        { username: username },
        "LoginGameInterface",
        username
      );
      return res.data;
    },
  });
};
