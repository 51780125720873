import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface BalanceProps {
  main_balance: string;
  total: string;
  fc_balance: number;
  yb_balance: number;
  jili_balance: number;
  pp_balance: number;
}

export const useBalance = () => {
  const isAuth = useAppStore((state) => state.isAuthenticated);
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["GetBalanceDV"],
    queryFn: async () => {
      const res = await fetchAPI<BalanceProps>(
        { username },
        "GetBalanceDV",
        username,
      );
      if (!res.status) {
        return res.data;
      }
    },
    enabled: isAuth,
    refetchInterval: 1000 * 30,
  });
};
