// import { useBettingRecords } from 'apis/transactions';
import { Empty } from 'antd'
import { useBettingRecords, useGamePlayedRecord } from 'apis/transactions'
import clsx from 'clsx'
import HeaderTab from 'components/HeaderTab'
import LoadingCustom from 'components/LoadingCustom'
import PaginationsCustom from 'components/PaginationsCustom'
import RangePickerCustom from 'components/RangePickerCustom'
import SelectCustom from 'components/SelectCustom'
import dayjs, { Dayjs } from 'dayjs'
import _map from "lodash/map"
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

type dateType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const BettingRecord = () => {
  const { t } = useTranslation("transactions");

  const [dateTime, setDateTime] = useState<dateType>({
    startDate: dayjs().subtract(7, "day"),
    endDate: dayjs()
  })
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 15;

  const [selectedGameFilter, setSelectedGameFilter] = useState<string>("");
  const { data: dataGamePlayed } = useGamePlayedRecord();

  const listGamePlayed = useMemo(() => {
    const res = _map(dataGamePlayed, (item) => ({
      label: item.game_name,
      value: item.game_name,
    }));

    if (dataGamePlayed && dataGamePlayed.length <= 0) {
      return res;
    }

    return [...[{ label: t("All Game Played"), value: "" }], ...res];
  }, [dataGamePlayed]);


  const { data: dataBettingRecords, refetch, isFetching, isLoading } = useBettingRecords({
    size: pageSize,
    game_name: selectedGameFilter,
    page: currentPage,
    start: dateTime.startDate?.format("YYYY/MM/DD").toString() ?? "",
    end: dateTime.endDate?.format("YYYY/MM/DD").toString() ?? ""
  });

  useEffect(() => {
    refetch();
  }, [dateTime.startDate, dateTime.endDate, refetch, selectedGameFilter]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateTime.startDate, dateTime.endDate, selectedGameFilter])

  return (
    <div className='bg-linearBlack w-full h-full overflow-hidden'>
      <HeaderTab title={t("Betting")} />
      <div className='pt-2 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3'>
        <RangePickerCustom
          startDate={dateTime.startDate}
          endDate={dateTime.endDate}
          className='mt-2'
          handleChangeDate={setDateTime}
          handleApplyDate={() => refetch()}
        />
        <div className='w-full mt-2 mb-5 flex flex-col items-start justify-between'>
          <p className='text-[#E3B85D] text-[14px] font-semibold mb-1'>{t("Game played")}:</p>
          <SelectCustom
            options={listGamePlayed}
            value={selectedGameFilter}
            onChange={(value) => setSelectedGameFilter(value)}
            className='mb-0 h-[40px] text-[12px] text-[#E3B85D] w-full bg-black border border-[#4e442e] rounded-sm px-2'
            dropdownClassName='select-dropdown-custom !bg-[#42300c] border border-[#E3B85D] !text-[#ffffff] shadow-[0_0_15px_rgba(0,0,0,0.5)]'
          />
        </div>
        {dataBettingRecords?.data &&
          dataBettingRecords.data.length > 0 &&
          !isFetching && dataBettingRecords.data.map((item, i) => (
            <div
              key={i}
              className='p-3 mb-5 bg-[#171200] rounded-md border border-[#E3B85D]'
            >
              <div className='flex items-center gap-3 justify-between mb-1'>
                <span className='text-[14px]'>{t("Game")}: </span>
                <span className='text-[#E3B85D] font-semibold'>{item.Game}</span>
              </div>
              <div className='flex items-center gap-3 justify-between'>
                <span className='text-[14px]'>{t("Provider")}: </span>
                <span className='text-[#E3B85D] font-semibold'>{item.Provider}</span>
              </div>
              <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
              <div className='flex items-center gap-3 justify-between mb-1'>
                <span className='text-[14px]'>{t("Stake")}: </span>
                <p className='flex gap-2 items-center'>
                  <span className='text-[12px] text-[#c8a45c]'>MVR</span>
                  <span className='text-[20px] font-bold text-[#ffe6b4]'>{item.Stake}</span>
                </p>
              </div>
              <div className='flex items-center gap-3 justify-between'>
                <span className='text-[14px]'>{t("Win/Lose")}: </span>
                <span
                  className={clsx(
                    'font-semibold',
                    Number(item.Winlose) < 0.00 ? "text-[#e44141]" : Number(item.Winlose) > 0.00 ? "text-[#2bd32b]" : "text-[#ac8636]"
                  )}
                >
                  {item.Winlose}
                </span>
              </div>
              <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
              <div className='flex items-center gap-3'>
                <span className='text-[12px] text-[#c8a45c]'>{item.Date}</span>
              </div>
            </div>
          ))
        }
        {dataBettingRecords?.data.length === 0 && !isFetching && (
          <div className='w-full h-[400px] flex items-center justify-center'>
            <Empty description={t("Empty Data")} />
          </div>
        )}
        {(isLoading || isFetching) && (
          <LoadingCustom height={400} center />
        )}
        {!isFetching && (
          <PaginationsCustom
            lengthData={dataBettingRecords?.data.length || 0}
            currentPage={currentPage}
            pageSize={pageSize || 5}
            total={dataBettingRecords?.totalCount || 0}
            onChangePage={(page: number) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  )
}

export default BettingRecord