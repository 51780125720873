import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "..";

export interface AnnouncementItemProps {
  id: string;
  title: string;
  content: string;
  start: string;
  end: string;
}

export const useAnnouncement = () => {
  return useQuery({
    queryKey: ["Announcement"],
    queryFn: async () => {
      const res = await fetchAPI<AnnouncementItemProps[]>(
        {},
        "Announcement",
        null,
      );
      if (!res.status) {
        return res.data;
      }
    },
  });
};
