import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { fetchAPI } from "apis";
import { useFriendList } from "./list-friend";

export interface FriendUpdateFormProps {
  username: string;
  friend_username: string;
  update_type: "add" | "cancel" | "accept" | "reject" | "delete";
  remark?: string;
}

export const useFriendUpdate = () => {
  const { refetch } = useFriendList();
  return useMutation({
    mutationKey: ["FriendUpdate"],
    mutationFn: async (payload: FriendUpdateFormProps) => {
      const res = await fetchAPI(payload, "FriendUpdate", payload.username);
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return res;
    },
    onSuccess: (data) => {
      message.success(data.msg);
      refetch();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
