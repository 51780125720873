import { useHotGame } from "apis/games/hot-game";
// Import Swiper React components
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import ItemGameProvider from "components/ItemGameProvider";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/autoplay";

function HotGame() {
  const { data } = useHotGame();
  const { t } = useTranslation("home");
  const dataGame = data;
  return (
    <div className="w-full">
      <div className="flex items-center">
        <h3 className="font-bold text-orange-400 text-xl">{t("HotGames")}</h3>
        <img src="/icons/home/fire.png" className="w-6" />
      </div>
      <div className="w-full my-2">
        <Swiper
          spaceBetween={20}
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={4}
          speed={1000}
          breakpoints={{
            640: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 6,
            },
          }}
        >
          {dataGame?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ItemGameProvider hideFavourite item={item}></ItemGameProvider>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default HotGame;
