import { useQuery } from "@tanstack/react-query";
import { fetchAPI, fetchPaginationAPI } from "..";

export interface RecordsType {
  page?: number;
  size?: number;
  start?: string;
  end?: string;
}

export interface PayloadTransactionProps extends RecordsType {
  type: number;
  status?: string;
}

export interface TransactionItemProps {
  status: string;
  type: string;
  bank_name?: string;
  bank_account_name?: string;
  bank_account_no?: string;
  amount: string;
  transaction_code?: string;
  create_date: string;
  update_date?: string;
  name?: string;
  reward_amount?: string;
  current_amount?: string;
  promotion_name?: string;
  transfer: string;
  friend: string;
  balance_before: string;
  balance_after: string;
  remark: string;
  point_type: string;
  report_date: string;
  Date?: string
  Bonus_type?: string
  Amount?: string
  Member?: string
  Rate?: string
}

export const useTransaction = (payload: PayloadTransactionProps) => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: [
      "MemberOrder",
      payload.type,
      payload.page,
      payload.start,
      payload.end,
    ],
    queryFn: async () => {
      const res = await fetchPaginationAPI<TransactionItemProps[]>(
        {
          ...payload,
          page: payload.page,
          size: payload.size,
          username: username,
          start: payload.start,
          end: payload.end,
          status: payload.status,
        },
        "MemberOrder",
        username,
      );
      return res;
    },
  });
};

export interface PayloadBettingRecordType extends RecordsType {
  game_name?: string;
}

export interface BettingRecordsResponseType {
  Date: string;
  Stake: string;
  Winlose: string;
  Provider: string;
  Game: string;
}

export const useBettingRecords = (payload: PayloadBettingRecordType) => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["Wager", payload.page, payload.start, payload.end],
    queryFn: async () => {
      const res = await fetchPaginationAPI<BettingRecordsResponseType[]>(
        {
          ...payload,
          page: payload.page,
          size: payload.size,
          username: username,
          start: payload.start,
          end: payload.end,
          game_name: payload.game_name,
        },
        "Wager",
        username,
      );
      return res;
    },
  });
};

export interface ListGameByIDResponse {
  game_name: string;
}

export const useGamePlayedRecord = () => {
  const username = localStorage.getItem("username");

  return useQuery({
    queryKey: ["showListGameByID"],
    queryFn: async () => {
      const res = await fetchAPI<ListGameByIDResponse[]>(
        { username },
        "showListGameByID",
        username,
      );
      return res.data;
    },
  });
};

export interface PointInOutRecordsResponse {
  report_date: string;
  wallet_out: string;
  balance_before: string;
  transfer_out_amount: string;
  after_balance: string;
  wallet_in: string;
}

export const usePointInOutRecords = (payload: RecordsType) => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["GetPointInOut", payload.page, payload.start, payload.end],
    queryFn: async () => {
      const res = await fetchPaginationAPI<PointInOutRecordsResponse[]>(
        {
          ...payload,
          page: payload.page,
          size: payload.size,
          username: username,
          start: payload.start,
          end: payload.end,
        },
        "GetPointInOut",
        username,
      );
      return res;
    },
  });
};

export interface RewardRecordsResponse {
  promotion_name: string;
  reward_amount: string;
  created_date: string;
  reward_date: string;
  status: string;
}

export const useRewardRecords = (payload: RecordsType) => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["showPromtionByUser", payload.page, payload.start, payload.end],
    queryFn: async () => {
      const res = await fetchPaginationAPI<RewardRecordsResponse[]>(
        {
          ...payload,
          page: payload.page,
          size: payload.size,
          username: username,
          start: payload.start,
          end: payload.end,
        },
        "showPromtionByUser",
        username,
      );
      return res;
    },
  });
};
