import { Modal } from 'antd';
import { useGetMessageDeposit, useGetReceiveDataDPS } from 'apis/deposit';
import { useGetMessageWithdrawal, useGetReceiveDataWRL } from 'apis/withdrawal';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OrderStatus } from 'enums/orders';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { handleColorStatus, showMessageStatusText } from 'helper/showStatusText';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { ApiMessageDepositResponseType, ApiMessageWithdrawralResponseType } from 'types/api';

interface Props {
  open: boolean
  onClose: () => void
  dataMessageDeposit?: ApiMessageDepositResponseType
  dataMessageWithdrawal?: ApiMessageWithdrawralResponseType
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ModalAnnouncement = ({ dataMessageDeposit, dataMessageWithdrawal, onClose, open }: Props) => {
  const { t } = useTranslation("messageModal");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync: getReceiveDataDPS } = useGetReceiveDataDPS();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync: getReceiveDataWRL } = useGetReceiveDataWRL();

  const { refetch: refetchWithdrawal } = useGetMessageWithdrawal();
  const { refetch: refetchDeposit } = useGetMessageDeposit();

  const arrayMessage: (ApiMessageDepositResponseType | ApiMessageWithdrawralResponseType)[] = []

  if (dataMessageDeposit) {
    arrayMessage.push(dataMessageDeposit);
  }

  if (dataMessageWithdrawal) {
    arrayMessage.push(dataMessageWithdrawal);
  }

  const getReceiveDeposit = async () => {
    if (dataMessageDeposit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status, transaction_code, created_date, msg, ...filteredData } =
        dataMessageDeposit;
      await getReceiveDataDPS({
        ...(status === "0" && { status_pending: Number(status) }),
        ...(status === "1" && { status_approve: Number(status) }),
        ...(status === "2" && { status_cancel: Number(status) }),
        ...filteredData,
      });
    }
  };

  const getReceiveWithdraw = async () => {
    if (dataMessageWithdrawal) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status, transaction_code, created_date, msg, ...filteredData } =
        dataMessageWithdrawal;
      await getReceiveDataWRL({
        ...(status === "0" && { status_pending: Number(status) }),
        ...(status === "1" && { status_approve: Number(status) }),
        ...(status === "2" && { status_cancel: Number(status) }),
        ...filteredData,
      });
    }
  };

  useEffect(() => {
    if (open) {
      getReceiveDeposit();
      getReceiveWithdraw();
    }
  }, [open]);

  const handleOnClose = () => {
    onClose();
    refetchDeposit();
    refetchWithdrawal();
  }

  const splitChatAt = (key?: string) => {
    const chartAt = key?.charAt(0);

    switch (chartAt) {
      case "D":
        return "Deposit";
      case "W":
        return "Withdrawal";
      default:
        return "";
    }
  }

  return (
    <Modal
      open={open}
      onCancel={handleOnClose}
      footer={false}
      modalRender={node => (
        <div className='custom-modal !bg-transparent'>{node}</div>
      )}
      className='top-[30%]'
    >
      <div className='rounded-lg overflow-hidden bg-[#241800] text-white border border-[#E3B85D]'>
        <h3 className='bg-linearGold text-center text-[20px] font-bold text-[#000000] py-2 px-4'>
          {t("Notification")} !
        </h3>
        <div className='overflow-y-auto max-h-[70vh] p-3 font-semibold text-[18px]'>
          <div className='flex flex-col gap-2 w-full'>
            {arrayMessage && arrayMessage.length > 0 && arrayMessage.map((item, index) => (
              <div key={index} className='px-2 py-3 border border-[#704c00] bg-[#191100] rounded-md'>
                <p className='mb-2 text-[#c8a45c] text-[14px]'>{item?.transaction_code}</p>
                <div className='flex items-center justify-between gap-3'>
                  <p className='text-[#fce28e] text-[18px]'>{splitChatAt(item?.transaction_code)}</p>
                  <p style={{ color: handleColorStatus(item?.status as OrderStatus) }}>
                    {t(showMessageStatusText(item?.status as OrderStatus))}
                  </p>
                </div>
                <p className='mt-3 text-[12px] font-medium text-[#c8a45c]'>{item?.created_date}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalAnnouncement