// Import Swiper React components
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import { useBanner } from "apis/banner";
import { isMobile } from "enums/device";
function Banner() {
  const { data } = useBanner();

  return (
    <div className="w-full">
      <Swiper
        spaceBetween={10}
        loop={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        speed={1000}
      >
        {data &&
          data.length &&
          data.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  key={index}
                  className="w-full min-h-40 rounded-xl items-center justify-center flex bg-gray-700"
                >
                  <img src={isMobile ? item.mobile_banner : item.web_banner} />
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}

export default Banner;
