import React from 'react'

const AgentIntro = () => {
  return (
    <div className='relative'>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <h2 className='text-[#EDCD90] font-semibold text-lg mb-2'>Scan your unique QR code</h2>
        <p className='text-[#E3B85D] text-[12px]'>Your connection is your money! You just need to invite new players to scan your QR code and register as a user, and you will become the referrer of that user.</p>
      </div>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <p className='text-[#E3B85D] text-[12px]'>Referrer not only enjoy rebate during the game, but also earn the commission rewards of their downline players. The more players you invite, the more commissions you can earn.</p>
      </div>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <h2 className='text-[#EDCD90] font-semibold text-lg mb-2'>Commission rate reference</h2>
        <p className='text-[#E3B85D] text-[12px]'>According to different games, commission rate is also different, the commission rate can be seen in the chart below:
          (The game will launch event of special rebates and commissions from time to time)</p>
      </div>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <h2 className='text-[#EDCD90] font-semibold text-lg mb-2'>Important Notes</h2>
        <ul className='list-decimal pl-3 text-[12px] leading-[1.5] text-[#E3B85D]'>
          <li>All rebates are calculated based on 'Valid Bet Amount'</li>
          <li>All fishing games, cockfighting, slot machines, and light running machines do not count towards rebates</li>
          <li>All fishing games, cockfighting, slot machines, and light running machines do not count towards rebates</li>
          <li>If any 'water farming' is found, the company reserves the right to 'freeze and cancel the issuance' of all rebates</li>
        </ul>
      </div>
      {/* <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <h2 className='text-[#EDCD90] font-semibold text-lg mb-2'>Commission settlement</h2>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Example：Live & Sport Betting Rebate</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Level 1 Downline players' turnover in I1BETNOW = RM100,000.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Level 2 Downline players' turnover in AE SEXY = RM200,000.00</p>
      </div>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <p className='text-[#E3B85D] text-[12px] mb-2'>RM100,000.00 x 0.1% =RM100.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>RM200,000.00 x 0.1% =RM200.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Then your commission will be RM300.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Example：Slot Lose Rebate</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Level 1 Downline players' lost in JILI=RM10,000.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Level 2 Downline players' lost in VPOWER=RM20,000.00</p>
      </div>
      <div className='bg-[#231800] border border-[#4e442e] rounded-md mx-3 p-3 mb-5'>
        <p className='text-[#E3B85D] text-[12px] mb-2'>RM10,000.00 x 2% =RM200.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>RM10,000.00 x 2% =RM200.00</p>
        <p className='text-[#E3B85D] text-[12px] mb-2'>Then your commission will be RM400.00</p>
      </div> */}
      <h2 className='text-[#EDCD90] font-semibold text-[12px] mb-2 mx-3 text-center'>The rebate commission will be automatically settled into your account before 4pm every day.</h2>
    </div>
  )
}

export default AgentIntro