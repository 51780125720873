import { Empty } from 'antd';
import { usePointInOutRecords } from 'apis/transactions';
import HeaderTab from 'components/HeaderTab';
import LoadingCustom from 'components/LoadingCustom';
import PaginationsCustom from 'components/PaginationsCustom';
import RangePickerCustom from 'components/RangePickerCustom';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type dateType = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const PointInOutRecords = () => {
  const { t } = useTranslation("transactions")
  const [dateTime, setDateTime] = useState<dateType>({
    startDate: dayjs().subtract(7, "day"),
    endDate: dayjs()
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;

  const { data: dataPointInOutRecords, refetch, isFetching, isLoading } = usePointInOutRecords({
    size: pageSize,
    page: currentPage,
    start: dateTime.startDate?.format("YYYY/MM/DD").toString() ?? "",
    end: dateTime.endDate?.format("YYYY/MM/DD").toString() ?? ""
  });

  useEffect(() => {
    refetch();
  }, [dateTime.startDate, dateTime.endDate, refetch, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dateTime.startDate, dateTime.endDate])

  return (
    <div className='bg-linearBlack w-full h-full overflow-hidden'>
      <HeaderTab title={"Point In Out Records"} />
      <div className='pt-2 pb-[70px] w-full overflow-y-auto h-[calc(100%_-_58px)] scrollbar-hide px-3'>
        <RangePickerCustom
          startDate={dateTime.startDate}
          endDate={dateTime.endDate}
          className='mt-2'
          handleChangeDate={setDateTime}
          handleApplyDate={() => refetch()}
        />
        <div className='mt-5'>
          {
            dataPointInOutRecords?.data &&
            dataPointInOutRecords.data.length > 0 &&
            !isFetching && dataPointInOutRecords.data.map((item, index) => (
              <div
                key={index}
                className="p-3 mb-5 bg-[#171200] rounded-md border border-[#E3B85D]"
              >
                <div className='flex items-center gap-3 justify-between leading-[1.5]'>
                  <span className='text-[14px]'>{t("WalletIn")}: </span>
                  <span className='text-[#E3B85D] font-semibold'>{item.wallet_in}</span>
                </div>
                <div className='flex items-center gap-3 justify-between'>
                  <span className='text-[14px]'>{t("WalletOut")}: </span>
                  <span className='text-[#E3B85D] font-semibold'>{item.wallet_out}</span>
                </div>
                <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
                <div className='flex items-center gap-3 justify-between'>
                  <span className='text-[14px]'>{t("Before Balance")}: </span>
                  <span className='text-[#E3B85D] font-semibold'>{item.balance_before}</span>
                </div>
                <div className='flex items-center gap-3 justify-between'>
                  <span className='text-[14px]'>{t("After Balance")}: </span>
                  <span className='text-[#E3B85D] font-semibold'>{item.after_balance}</span>
                </div>
                <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
                <div className='flex items-center gap-3 justify-between'>
                  <span className='text-[14px]'>{t("TransferOutAmount")}: </span>
                  <span className='text-[#E3B85D] font-semibold'>{item.transfer_out_amount}</span>
                </div>
                <div className='w-full h-[1px] bg-[#E3B85D80] my-2'></div>
                <div className='flex items-center gap-3 pt-2 justify-end'>
                  <span className='text-[12px] text-[#c8a45c]'>{item.report_date}</span>
                </div>
              </div>
            ))
          }
        </div>
        {dataPointInOutRecords?.data.length === 0 && !isFetching && (
          <div className='w-full h-[400px] flex items-center justify-center'>
            <Empty description={"Empty Data"} />
          </div>
        )}
        {(isLoading || isFetching) && (
          <LoadingCustom height={400} center />
        )}
        {!isFetching && <PaginationsCustom
          lengthData={dataPointInOutRecords?.data.length || 0}
          currentPage={currentPage}
          pageSize={pageSize || 5}
          total={dataPointInOutRecords?.totalCount || 0}
          onChangePage={(page: number) => setCurrentPage(page)}
        />}
      </div>
    </div>
  )
}

export default PointInOutRecords