import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { useAppStore } from "stores/app";

export interface FriendRoot {
  list: {
    friend: Friend[];
    sent: Friend[];
    received: Friend[];
  };
}

export interface Friend {
  friend_id: string;
  friend_username: string;
  full_name: string;
  mobile: string;
  email: string;
  avatar: string;
}

export const useFriendList = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((state) => state.isAuthenticated);
  return useQuery({
    queryKey: ["FriendList"],
    queryFn: async () => {
      const res = await fetchAPI<FriendRoot>(
        { username: username },
        "FriendList",
        username,
      );
      return res.data;
    },
    enabled: isAuth,
  });
};
