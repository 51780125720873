import { liveChatUrl, PATH } from "constants/path";

export const ListMenuDefault = [
  {
    code: "new",
    icon: "/icons/contact/add.png",
    url: PATH.contact + "/new",
  },
  {
    code: "helpdesk",
    icon: "/icons/contact/helpdesk.png",
    url: liveChatUrl,
  },
  {
    code: "workOrder",
    icon: "/icons/contact/blocklist.png",
    url: liveChatUrl,
  },
];
