import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchAPI } from "..";
import { message } from "antd";
import { useAppStore } from "stores/app";

export interface BankItemProps {
  bank_id: string;
  bank_name: string;
  bank_code: string;
}
export const useBankList = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((STATE) => STATE.isAuthenticated);
  return useQuery({
    queryKey: ["GetBankList"],
    queryFn: async () => {
      const res = await fetchAPI<BankItemProps[]>(
        { username },
        "GetBankList",
        username,
      );
      return res;
    },
    enabled: isAuth,
  });
};

export interface MemberBankItemProps {
  player_bank_id: string;
  bank_id: string;
  bank_name: string;
  bank_account_name: string;
  bank_account_no: string;
  bank_account_address?: "";
  verify: string;
}
export const useMemberBank = () => {
  const username = localStorage.getItem("username");
  const isAuth = useAppStore((STATE) => STATE.isAuthenticated);
  return useQuery({
    queryKey: ["GetMemberBank"],
    queryFn: async () => {
      const res = await fetchAPI<MemberBankItemProps[]>(
        { username },
        "GetMemberBank",
        username,
      );
      return res;
    },
    enabled: isAuth,
  });
};

export interface FormMemberBankProps {
  id: string;
  account_no: string;
  account_name: string;
  otp: string;
  mobile?: string;
}

export interface AddMemberBankProps {
  onSuccess?: () => void;
}

export const useAddMemberBank = ({ onSuccess }: AddMemberBankProps) => {
  const username = localStorage.getItem("username");
  return useMutation({
    mutationKey: ["AddMemberBank"],
    mutationFn: async (payload: FormMemberBankProps) => {
      const res = await fetchAPI(
        {
          ...payload,
          username: username,
        },
        "AddMemberBank",
        username,
      );
      if (!res.status) {
        return res;
      }
      return Promise.reject(res.msg);
    },
    onSuccess: (data) => {
      message.success(data.msg);
      onSuccess && onSuccess();
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
