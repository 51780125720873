import { useTranslation } from "react-i18next";
import { useBalance } from "apis/auth/balance";
import { useMemberDetail } from "apis/auth/member-detail";
import LoadingCustom from "components/LoadingCustom";

function Balance() {
  const { t } = useTranslation("home");
  const { data: balance, isLoading: isLoadingBalance } = useBalance();
  const { data: profile, isLoading: isLoadingprofile } = useMemberDetail();
  return (
    <div className="w-full flex justify-center pt-3">
      <div className="w-full h-[35px] border border-[#E3B85D] px-2 py-1 rounded-2xl flex">
        <div className="flex-1 flex justify-center items-center">
          <span className="text-xs text-[#E3B85D] font-semibold">{t("Cash")}</span>
          <span className="text-xs ml-2">MVR</span>
          <span className="text-xs ml-2">
            {isLoadingBalance ? <LoadingCustom fontSize={18} /> : balance?.total}
          </span>
        </div>
        <div className="w-[1px] h-full bg-orange-300 mx-2" />
        <div className="flex-1 flex justify-center items-center">
          <span className="text-xs text-[#E3B85D] font-semibold">{t("Chips")}</span>
          <span className="text-xs ml-2">MVR</span>
          <span className="text-xs ml-2">
            {isLoadingprofile ? <LoadingCustom fontSize={18} /> : profile?.chip_points}
          </span>
        </div>
      </div>
      <div />
    </div>
  );
}

export default Balance;
