export function createAmountSelect(num: number) {
  const arr = [];
  arr[0] = num; // index 0 là số ban đầu
  arr[1] = num * 5; // index 1 là số đó * 5
  arr[2] = arr[1] * 2; // index 2 là value của index 1 * 2
  arr[3] = arr[2] * 2; // index 3 là value của index 2 * 2
  arr[4] = arr[3] * 2; // index 4 là value của index 3 * 2 + 100
  arr[5] = arr[4] * 2; // index cuối là value của index 4 * 2 + 100
  return arr;
}

export function formatNumberWithCommas(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
