import { SubGameItemProps } from "apis/games/type";

export const ProvidersGame: SubGameItemProps[] = [
  {
    name: "Evolution",
    provider_code: "EVO",
    type: "LC",
    picture: "/images/providers/evo.png",
  },
  {
    name: "Pokerwin P2P",
    provider_code: "COMMINGSOON",
    type: "LC",
    picture: "/images/providers/live-pokerwin.png",
  },
  // {
  //   name: "SBO Live Casino",
  //   provider_code: "COMMINGSOON",
  //   type: "LC",
  //   picture: "/images/providers/live-SBO.png",
  // },
  // {
  //   name: "Ezugi",
  //   provider_code: "EZ",
  //   type: "LC",
  //   picture: "/images/providers/ez.png",
  // },
  // {
  //   name: "Sexy",
  //   provider_code: "SX",
  //   type: "LC",
  //   picture: "/images/providers/sx.png",
  // },
  // {
  //   name: "SA Gaming",
  //   provider_code: "SA",
  //   type: "LC",
  //   picture: "/images/providers/sa.png",
  // },
  // {
  //   name: "Dream Gaming",
  //   provider_code: "DG",
  //   type: "LC",
  //   picture: "/images/providers/dg.png",
  // },
  {
    name: "DB LIVE",
    provider_code: "PMLC",
    type: "LC",
    picture: "/images/providers/db.png",
    isMaintenance: true
  },
  {
    name: "YeeBet",
    provider_code: "YB",
    type: "LC",
    picture: "/images/providers/yb.png",
    isClose: true,
  },
  {
    name: "Pragmatic Play",
    provider_code: "PP",
    type: "LC",
    picture: "/images/providers/pplive.png",
  },
  // {
  //   name: "DE SPORT",
  //   provider_code: "DE",
  //   type: "SB",
  //   picture: "/images/providers/de.png",
  // },
  // {
  //   name: "BTI SPORT",
  //   provider_code: "BTI",
  //   type: "SB",
  //   picture: "/images/providers/bti.png",
  // },
  // {
  //   name: "IBC SPORT",
  //   provider_code: "IBC",
  //   type: "SB",
  //   picture: "/images/providers/ibc.png",
  // },
  {
    name: "SBO SPORT",
    provider_code: "SBO",
    type: "SB",
    picture: "/images/providers/sbo.png",
  },
  {
    name: "DB SPORT",
    provider_code: "PMSB",
    type: "SB",
    picture: "/images/providers/panda-sport.png",
    isMaintenance: true
  },
  // {
  //   name: "SBO ESPORT",
  //   provider_code: "COMMINGSOON",
  //   type: "ES",
  //   picture: "/images/providers/esport-SBO.png",
  // },
  // {
  //   name: "DB ESPORT",
  //   provider_code: "COMMINGSOON",
  //   type: "ES",
  //   picture: "/images/providers/esport-DB.png",
  // },
  {
    name: "Pragmatic Play",
    provider_code: "PP",
    type: "SL",
    picture: "/images/providers/ppslot.png",
  },
  {
    name: "WF Gaming",
    provider_code: "COMMINGSOON",
    type: "SL",
    picture: "/images/providers/slot-WEG.png",
  },
  {
    name: "JILI",
    provider_code: "JILI",
    type: "SL",
    picture: "/images/providers/jili-sl.png",
  },
  // {
  //   name: "KA Gaming",
  //   provider_code: "KA",
  //   type: "SL",
  //   picture: "/images/providers/ka.png",
  // },
  // {
  //   name: "Habanero",
  //   provider_code: "HB",
  //   type: "SL",
  //   picture: "/images/providers/hb.png",
  // },
  // {
  //   name: "Spade Gaming",
  //   provider_code: "SG",
  //   type: "SL",
  //   picture: "/images/providers/sg.png",
  // },
  {
    name: "PG SOFT",
    provider_code: "PGS2",
    type: "SL",
    picture: "/images/providers/pgs2.png",
    isMaintenance: true
  },
  // {
  //   name: "Ace Win",
  //   provider_code: "ACEW",
  //   type: "SL",
  //   picture: "/images/providers/acew.png",
  // },
  {
    name: "FaChai Gaming",
    provider_code: "FC",
    type: "SL",
    picture: "/images/providers/slot-fc.png",
  },
  // {
  //   name: "SBO SLOTS",
  //   provider_code: "COMMINGSOON",
  //   type: "SL",
  //   picture: "/images/providers/slot-sbo.png",
  // },
  // {
  //   name: "Play N Go",
  //   provider_code: "COMMINGSOON",
  //   type: "SL",
  //   picture: "/images/providers/slot-playn-go.png",
  // },
  {
    name: "Playstar",
    provider_code: "PS",
    type: "SL",
    picture: "/images/providers/slot-playstar.png",
    isMaintenance: true
  },
  // {
  //   name: "Aviatrix ",
  //   provider_code: "AVIA",
  //   type: "SL",
  //   picture: "/images/providers/slot-aviatrix.png",
  // },
  // {
  //   name: "Endorphina",
  //   provider_code: "COMMINGSOON",
  //   type: "SL",
  //   picture: "/images/providers/slot-endorphina.png",
  // },
  // {
  //   name: "Kingmaker",
  //   provider_code: "KM",
  //   type: "SL",
  //   picture: "/images/providers/kingmaker-slot.png",
  // },
  {
    name: "Ibex",
    provider_code: "IBEX",
    type: "SL",
    picture: "/images/providers/ibex-slot.png",
  },
  {
    name: "JILI",
    provider_code: "JILI",
    type: "FH",
    picture: "/images/providers/jili_fh.png",
  },
  // {
  //   name: "FaChai Gaming",
  //   provider_code: "FC",
  //   type: "FH",
  //   picture: "/images/providers/fis_hing-fc.png",
  // },

  {
    name: "JILI",
    provider_code: "JILI",
    type: "BG",
    picture: "/images/providers/jili_bg.png",
  },
  {
    name: "Kingmaker",
    provider_code: "KM",
    type: "BG",
    picture: "/images/providers/kingmaker-board game.png",
  },
  {
    name: "SPADEGAMING",
    provider_code: "SG",
    type: "SL",
    picture: "/images/providers/SPADEGAMING.png",
  },
  {
    name: "JDB",
    provider_code: "JDB",
    type: "SL",
    picture: "/images/providers/jdb.png",
  },
  {
    name: "WFGAMING",
    provider_code: "WF",
    type: "SL",
    picture: "/images/providers/slots-wf.png",
  },
  {
    name: "JDB",
    provider_code: "JDB",
    type: "BG",
    picture: "/images/providers/jdb1.png",
  },
  {
    name: "JDB",
    provider_code: "JDB",
    type: "FH",
    picture: "/images/providers/jdb2.png",
  },
  {
    name: "AVIATRIX",
    provider_code: "AVIA",
    type: "ARCADE",
    picture: "/images/providers/avia-arcade.png",
  }
];
