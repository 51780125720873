import { useVerifyToken } from "apis/auth/verify-token";
import { useGetMessageDeposit } from "apis/deposit";
import { useGetMessageWithdrawal } from "apis/withdrawal";
import DailyAccountControls from "components/DailyAccountControls";
import ModalAnnouncement from "components/ModalAnnouncement";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  ApiMessageDepositResponseType,
  ApiMessageWithdrawralResponseType,
} from "types/api";

function DefaultLayout() {
  useVerifyToken();
  const [dataMessageDeposit, setDataMessageDeposit] =
    useState<ApiMessageDepositResponseType>();
  const [dataMessageWithdrawal, setDataMessageWithdrawal] =
    useState<ApiMessageWithdrawralResponseType>();
  const [visibleMessage, setVisibleMessage] = useState(false);

  const { data: getDataWithdrawal } = useGetMessageWithdrawal();
  const { data: getDataDespoit } = useGetMessageDeposit();

  const handleGetMessageDeposit = async (
    response: ApiMessageDepositResponseType
  ) => {
    if (
      response.check_is_showApprove === 1 ||
      response.check_is_showCancel === 1 ||
      response.check_is_showOnPending === 1 ||
      response.check_is_showPending === 1 ||
      response.check_is_showOnPending === 0 ||
      response.check_is_showPending === 0 ||
      response.status_popup_all === 1
    ) {
      return;
    } else {
      setDataMessageDeposit(response);
      setVisibleMessage(true);
    }
  };

  const handleGetMessageWithdrawal = async (
    response: ApiMessageWithdrawralResponseType
  ) => {
    if (
      response.check_is_showApprove === 1 ||
      response.check_is_showCancel === 1 ||
      response.check_is_showPending === 1 ||
      response.status_popup_all === 1
    ) {
      return;
    } else {
      setDataMessageWithdrawal(response);
      setVisibleMessage(true);
    }
  };

  useEffect(() => {
    if (!getDataDespoit || !getDataWithdrawal) return;

    let interval: NodeJS.Timeout | undefined = undefined;

    clearInterval(interval);
    handleGetMessageDeposit(getDataDespoit);
    handleGetMessageWithdrawal(getDataWithdrawal);

    interval = setInterval(() => {
      handleGetMessageDeposit(getDataDespoit);
      handleGetMessageWithdrawal(getDataWithdrawal);
    }, 30000);

    return () => clearInterval(interval);
  }, [getDataDespoit, getDataWithdrawal]);

  return (
    <div className="w-full h-auto bg-gray-900 text-white">
      <div className="container-max-width h-screen w-full bg-black shadow overflow-y-auto overflow-x-hidden">
        <Outlet />
      </div>
      <ModalAnnouncement
        open={visibleMessage}
        dataMessageDeposit={dataMessageDeposit}
        dataMessageWithdrawal={dataMessageWithdrawal}
        onClose={() => setVisibleMessage(false)}
      />
      <DailyAccountControls />
    </div>
  );
}

export default DefaultLayout;
