import { useMutation } from "@tanstack/react-query";
import { useLoginForm } from "./login";
import { fetchAPI } from "apis";
import { message } from "antd";

export interface FormRegisterProps {
  username: string;
  password: string;
  conpass: string;
  // email: string;
  mobile: string;
  referral?: string;
}

export const useRegisterForm = () => {
  const { mutate } = useLoginForm();
  return useMutation({
    mutationKey: ["MemberRegister"],
    mutationFn: async (payload: FormRegisterProps) => {
      const res = await fetchAPI(
        {
          ...payload,
        },
        "MemberRegister",
        payload.username,
      );
      if (res.status) {
        return Promise.reject(res.msg);
      }
      return payload;
    },
    onSuccess: (data) => {
      mutate({ username: data.username, password: data.password });
    },
    onError: (err) => {
      message.error(err + "");
    },
  });
};
