import HeaderTab from "components/HeaderTab";
import { useTranslation } from "react-i18next";

function InfomationPage() {
  const { t } = useTranslation("infomation");
  return (
    <div className="flex flex-col">
      <HeaderTab title={t("infomation")} />
      <div className="px-4 text-sm text-orange-300">{t("note")}</div>
    </div>
  );
}

export default InfomationPage;
