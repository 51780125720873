import { useQuery } from "@tanstack/react-query";
import { fetchAPI } from "apis";
import { SubGameItemProps } from "./type";

export const useHotGame = () => {
  const username = localStorage.getItem("username");
  return useQuery({
    queryKey: ["GetSubGameList", "hot-game"],
    queryFn: async () => {
      const res = await fetchAPI<SubGameItemProps[]>(
        { page: 1, size: 15, username: username, featured: "1" },
        "GetSubGameList",
        null,
      );
      return res.data;
    },
  });
};
