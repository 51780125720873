export const liveChatUrl =
  "https://tawk.to/chat/677cf07caf5bfec1dbe7c0e2/1ih00mpuv";
export const me = "/me";
export const PATH = {
  home: "/",
  topup: "/topup",
  addBank: "/add-bank",
  settings: "/settings",
  login: "/login",
  register: "/register",
  registerReferral: "/ref/:referralCode",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  contact: "/contact",
  me: {
    index: me,
    transfer: me + "/transfer",
    winnerList: me + "/winner-list",
    winloseReport: me + "/winlose-report",
    redEnvelop: me + "/red-envelop",
    guide: me + "/guide",
    vpn: me + "/vpn",
    customerService: me + "/customer-service",
    information: me + "/information",
    setting: me + "/setting",
    edit: me + "/edit-profile",
  },
  withdrawal: "/withdrawal",
  referral: "/referral",
  promotion: "/promotions",
  bettingRecord: "/betting-records",
  transactions: "/transactions-records",
  ranking: "/ranking",
  helpDesk: "/help-desk",
  mission: "/mission",
  playerTransaction: "/player-transactions",
  playingChips: "/game/playing/chips",
  playingMainPoint: "/game/playing/main-point",
  poitInOutRecords: "/point-in-out-records",
  rewardRecords: "/reward-records",
  rebateRecords: "/rebate-records",
  dowloadApp: "/download-app",
};
