import { Spin } from "antd";
import { useBalance } from "apis/auth/balance";
import { useMemberDetail } from "apis/auth/member-detail";
import { useFriendTransfer } from "apis/friends/friend-transfer";
import BottomSheet from "components/BottomSheet";
import { createAmountSelect } from "helper/createAmountSelect";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type Props = {
  open: boolean;
  onClose: () => void;
};

function ModalTransfer({ open, onClose }: Props) {
  const { code } = useParams();
  const { t } = useTranslation("contact");
  const { data: balance } = useBalance();
  const { data: profile } = useMemberDetail();
  const [amount, setAmount] = useState("");
  const { mutate, isPending } = useFriendTransfer({
    onSuccess: () => {
      setAmount("");
      onClose();
    },
  });

  return (
    <BottomSheet isOpen={open} onClose={onClose}>
      <div className="flex flex-col bg-slate-900 min-h-96 px-4">
        <div className="flex py-2 items-center justify-center">
          <h3 className="text-xl font-bold text-orange-300">{t("transfer")}</h3>
        </div>
        <div className="flex flex-col bg-slate-800 rounded">
          <div className="flex items-center justify-between px-2 py-2">
            <span>{t("Points")}</span>
            <span className="font-semibold text-orange-300">
              MVR {profile?.points || "0.00"}
            </span>
          </div>
        </div>
        <div className="flex flex-col mt-6">
          <label className="text-orange-300 font-semibold">{t("Amount")}</label>
          <input
            placeholder={`${t("Min")} 1.00 MVR & ${t("max")} ${
              balance?.total
            } MVR`}
            className="mt-2 h-12 bg-slate-800 px-2 rounded-md placeholder:text-orange-300 outline-none"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <div className="grid grid-cols-6 mt-4 gap-2">
            {createAmountSelect(10).map((item, index) => {
              const isActive = item.toString() === amount;
              return (
                <div
                  className={`bg-slate-800 text-center py-2 rounded-md ${
                    isActive && "!bg-orange-400 text-white"
                  }`}
                  key={index}
                  onClick={() => setAmount(item.toString())}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center mt-6 justify-center">
          <button
            onClick={() =>
              mutate({
                username: localStorage.getItem("username") + "",
                friend_username: code + "",
                amount: amount,
              })
            }
            disabled={!amount}
            className="bg-linearGold w-full py-3 rounded-lg text-black font-extrabold uppercase disabled:opacity-65"
          >
            {isPending ? <Spin size="small" /> : t("Submit")}
          </button>
        </div>
      </div>
    </BottomSheet>
  );
}

export default ModalTransfer;
