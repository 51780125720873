import { message } from "antd";
import { fetchAPIGame } from "apis";
import { SubGameItemProps } from "apis/games/type";
import { PATH } from "constants/path";
import { Dispatch, SetStateAction, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "stores/app";

const createWindow = () => {
  const openBlank = window.open("", "LoginGame");
  if (!openBlank) return;

  const loaderElement = openBlank.document.createElement("div");
  const style = openBlank.document.createElement("style");
  loaderElement.className = "loader";

  style.textContent = `
      .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:rgba(0, 0, 0, 0.5);z-index:9999} 
      .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#6bb0c8;border-radius:50%;animation:loading 2s ease infinite} 
      @keyframes loading {
        from { transform: rotate(0turn)}
        to { transform:rotate(1turn)}
      }
    `;

  openBlank.document.body.appendChild(loaderElement);
  openBlank.document.head.appendChild(style);

  return openBlank;
};

const handleOpenGameInIframe = async (
  payload: SubGameItemProps,
  setGameURL: Dispatch<SetStateAction<string>>
) => {
  try {
    const username = localStorage.getItem("username");
    const formData = {
      provider: payload.provider_code,
      game_type: payload.type,
      game_code: payload.game_code,
      username: username,
    };
    const res = await fetchAPIGame(formData, "OpenGame", username);
    if (res.status === 0) {
      message.destroy();
      setGameURL(res.url);
    } else {
      message.error(res.msg);
    }
  } catch (error) {
    message.destroy();
    message.error(error + "");
  }
};

const handleLoginGameApi = async (
  window: Window,
  payload: SubGameItemProps
) => {
  try {
    const username = localStorage.getItem("username");
    const formData = {
      provider: payload.provider_code,
      game_type: payload.type,
      game_code: payload.game_code,
      username: username,
    };
    const res = await fetchAPIGame(formData, "OpenGame", username);
    if (res.status === 0) {
      message.destroy();
      (window?.location as { href: string }).href = res.url;
    } else {
      window.close();
      message.destroy();
      message.error(res.msg);
    }
  } catch (error) {
    window.close();
    message.destroy();
    message.error(error + "");
  }
};

export const useLoginGame = () => {
  const navigate = useNavigate();
  const isAuth = useAppStore((state) => state.isAuthenticated);
  const windowTab = useRef<Window | undefined>(undefined);
  const onLoginGame = async (
    payload: SubGameItemProps,
    setGameURL?: Dispatch<SetStateAction<string>>
  ) => {
    try {
      // xử lý mở form login
      if (!isAuth) {
        return navigate(PATH.login);
      }
      message.loading(`Loading ....!`);
      try {
        if (setGameURL) {
          handleOpenGameInIframe(payload, setGameURL);
          return;
        }
        windowTab.current = createWindow(); // tạo window
        if (!windowTab.current) return; // kiểm tra window
        handleLoginGameApi(windowTab.current, payload); //chạy call api game
      } catch (error) {
        console.log(error);
        const openBlank = window.open("", "LoginGame"); // tạo window
        if (!openBlank) return; // kiểm tra windown
        openBlank.blur(); // nếu đã có window này trc đó thì đóng nó đi
        openBlank.close();
        windowTab.current = createWindow(); // tạo window mới
        if (!windowTab.current) return; // kiểm tra window mới
        handleLoginGameApi(windowTab.current, payload); // chạy api game
      }
    } catch (error) {
      message.error(error + ""); // báo lỗi khi sảy ra sự cố
    }
  };

  return { onLoginGame, windowTab };
};
