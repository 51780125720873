import React, { JSX } from "react";
import IconChevronRight from "assets/icons/icon-chevron-right.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
  rightComponent?: JSX.Element;
  backUrl?: "home" | "previous";
  onLeft?: () => void;
}

const HeaderTab = ({
  title,
  onLeft,
  rightComponent,
  backUrl = "previous",
}: Props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative h-[52px] flex items-center justify-center gap-2 sticky top-0 left-0 w-full z-50 bg-black">
        <button
          className="w-[52px] h-full flex items-center justify-center absolute top-0 left-0"
          onClick={() => {
            if (onLeft) return onLeft();
            if (backUrl === "home") return navigate("/");
            navigate(-1);
          }}
        >
          <img src={IconChevronRight} alt="..." />
        </button>
        <h3 className="text-[#EDCD90] font-medium text-[18px]">{title}</h3>
        <div>{rightComponent}</div>
      </div>
      <div className="h-[6px] w-full bg-[#E3B85D]"></div>
      <Helmet>
        <title>{title} - MClub</title>
      </Helmet>
    </>
  );
};

export default HeaderTab;
